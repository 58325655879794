/* eslint-disable class-methods-use-this */
import moment from "moment";
import AWS, { S3 } from "aws-sdk";
import { Buffer } from "buffer";
import CryptoJS from "react-native-crypto-js";
import { upperFirst } from "lodash";
import config from "../config/config";
import {
  IAddress1,
  IPredictionsResponse,
  ISheetPriceData,
  ItemWithDimension,
  SheetInfoRes,
} from "../interfaces/commonInterface";

import { getPlacesDetail, getPredictions } from "../services/google";
import { EditOrderData, ItempImages, UserOderList } from "../interfaces/orderInterface";
import {  DISCOUNT_PER, DISCOUNT_PERCENTAGE_TIER3, IMAGE_HEIGHT,  MEMBERSHIP_PLANS_TYPE,  STATUS_ORDER } from "../constant/commonConstants";
import { IArtWorkDataSubmit } from "../interfaces/artworkInterface";

class Helper {
  /**
   *
   * Method to capitalize a string's first character
   * @param string string
   * @returns The capitalized string
   */
  capitalize = (string: string) =>
    string?.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());

  changeNumberFormate = (string: string) => {
    if (string && string?.length) {
      return `+1 (${string?.substring(0, 3)}) ${string?.substring(
        3,
        6
      )}-${string?.substring(6, string.length)}`;
    }
    return null;
  };

  /**
   * calculate difference between two dates
   * @param pastDate date
   * @returns
   */
  diffBtwDates = (pastDate: Date) => {
    const todaysDate = moment();
    const dayDiff = todaysDate.diff(pastDate, "days");
    const monthDiff = todaysDate.diff(pastDate, "months");
    const yearDiff = todaysDate.diff(pastDate, "years");
    let dateDiff = {
      years: 0,
      months: 0,
      days: 0,
    };
    if (yearDiff > 0) {
      dateDiff = { ...dateDiff, years: yearDiff };
    }
    if (monthDiff > 0) {
      dateDiff = { ...dateDiff, months: monthDiff };
    }
    if (dayDiff > 0) {
      dateDiff = { ...dateDiff, days: dayDiff };
    }
    return dateDiff;
  };

  /**
   * Calculate actual start date-time of a job shift
   * @param date string | Date
   * @param time string | Date
   * @returns Formatted date-time
   */

  getShiftDateWithTime = (date: string | Date, time: string | Date): Date => {
    const startDate = moment(date).format("YYYY-MM-DD");
    const startTime = moment(time).format("HH:mm:ss");

    return moment(`${startDate} ${startTime}`).format() as unknown as Date;
  };

  getSheetName = (
    orderId: number,
    imageIndex: number,
    orderLength: number,
    quantity: number,
    sheetName: string,
    totalSheets?: number,
    clientName?: string,
    companyName?: string,
    sheetDimension?: string
  ): string => {
    sheetDimension=sheetDimension?.replaceAll("in.","")?.replaceAll(" ","");
    return `${orderId}-${imageIndex}-${orderLength}-${quantity}-${totalSheets}-
    ${clientName?.replaceAll(/\s+/g, "_")?.replaceAll("-","_")}-
    ${this.capitalizeCompanyName(companyName?.replaceAll(/\s+/g, "_")?.replaceAll("-","_"))}-
    ${sheetDimension?sheetDimension:""}-
    ${sheetName?.replaceAll(/\s+/g, "_")?.replaceAll("-","_")}`
      ?.replace(/\s+/g, "")
      ?.replace("--", "");
  };

  /**
   * Fetch address predictions based on searched text
   * @param searchText
   * @returns predictions
   */

  fetchPredictions = async (
    searchText: string
  ): Promise<IPredictionsResponse> =>
    new Promise((resolve, reject) => {
      try {
        (async () => {
          const response = await getPredictions(searchText);
          resolve(response?.data as IPredictionsResponse);
        })();
      } catch (e) {
        reject(e);
      }
    });

  /**
   * Fetches complete address info based on specified place id
   * @param place_id place id
   * @returns address object
   */

  onPlaceSelected = async (place_id: string) => {
    const response = await getPlacesDetail(place_id);
    let address: IAddress1 = {};
    if (response.data && response.data?.success) {
      address = { ...response.data.data };
    }
    return address;
  };

  discountHandler = (currTier: string,maxTier: string,basePrice: number,quantity: number) => {
    let result = 0;
    if (currTier !== maxTier) {
      const disPrice = +((DISCOUNT_PER * basePrice) / 100).toFixed(2);
      const newBasePriceForAdvanceMember = Number(
        (basePrice - disPrice).toFixed(2)
      );
      result =
        Number((basePrice - newBasePriceForAdvanceMember).toFixed(2)) *
        quantity;
    }
    if (currTier === maxTier) {
      const disPrice = +((DISCOUNT_PERCENTAGE_TIER3 * basePrice) / 100).toFixed(2);
      const newBasePriceForAdvanceMember = Number((basePrice - disPrice).toFixed(2));
      result =
        Number((basePrice - newBasePriceForAdvanceMember).toFixed(2)) *
        quantity;
    }
    return result;
  };

  matchTier = (
    sheetPriceData: ISheetPriceData[],
    currArea: number
  ): ISheetPriceData => {
    for (const i in sheetPriceData) {
      if (currArea > 0 && currArea <= sheetPriceData[i].tier_area) {
        return sheetPriceData[i];
      }
    }
    return sheetPriceData[sheetPriceData.length - 1];
  };


  getItemDiscountPrice = (
    quantity: number,
    dimensions: string,
    membershipPlan: string,
    sheetPriceData: ISheetPriceData[],
    totalSheetArea=0,
  ) => {
    let price = 0;
    if (dimensions.length === 0) {
      return price;
    };
    const matchTierRange = this.matchTier(sheetPriceData, totalSheetArea);
    // console.log("matchTierRange",matchTierRange?.tier_title);
    if(membershipPlan &&matchTierRange && matchTierRange.per_square_fit_price>0){
      if(MEMBERSHIP_PLANS_TYPE[1].value===membershipPlan) {
        //  ADVANCE MEMBER WISE DISCOUNT
          const basePrice= Number(
            +(sheetPriceData[0].per_square_fit_price * this.oneUnitArea(dimensions)).toFixed(2)
          );
          price = this.discountHandler(matchTierRange.tier_title,
            sheetPriceData[sheetPriceData.length - 1].tier_title,basePrice,quantity
          );
      } else {
        // TIER WISE DISCOUNT
          const basePrice = Number(
            (sheetPriceData[0].per_square_fit_price * this.oneUnitArea(dimensions)).toFixed(2));
          const tierWisePrice = Number(
              (matchTierRange.per_square_fit_price * this.oneUnitArea(dimensions)).toFixed(2));
          price = Number((basePrice-tierWisePrice).toFixed(2))*quantity; 
      }
     };
    return price;
  };



  // get discount

  getDisCount = (imageData: ItempImages[]) => {
    let discount = 0;
    if (imageData.length > 0) {
      imageData.forEach((item: ItempImages) => {
        discount += item.price ? item.price : 0;
      });
    }
    return discount;
  };

  uploadFileOnS3 = async (
    file: string,
    filePath: string,
    fileTypes: string
  ) => {
    AWS.config.update(config.aws);
    const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
    const bucket = new AWS.S3({
      params: { Bucket: config.aws_bucket },
      useAccelerateEndpoint: true,
    });
    const buf = Buffer.from(file.split(",")[1], "base64");

    const params: S3.PutObjectRequest = {
      Body: buf,
      ContentEncoding: "base64",
      ContentType: fileTypes,
      ACL: "public-read",
      Key: filePath,
      Bucket: config.aws_bucket as string,
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params, options, (s3Err, data) => {
        if (data) {
          resolve(data.Location);
        }
        if (s3Err) {
          reject(s3Err);
        }
      });
    });
  };

  // BasePrice

  getTableBasePrice = (
    dim: string,
    planType: string,
    sheetData: SheetInfoRes[],
    quantity: number,
    userTotalSheets: number,
    sheetPriceData: ISheetPriceData[]
  ) => {
    const price = [0, 0]; // at o index discount price and at 1 index actual price
    if(dim && dim!=="" ){
    if(sheetPriceData && sheetPriceData.length>0){
      price[0]= Number(
        (sheetPriceData[0].per_square_fit_price * this.oneUnitArea(dim)).toFixed(2));
      }
    };
    return price;
  };

  // table dim

  getTableDimenssion = (
    dim: string,
    planType: string,
    sheetData: SheetInfoRes[]
  ) => {
    let dimenssion = "";
    const tempDim = dim?.split(" ");
    sheetData.forEach((item: SheetInfoRes) => {
      if (
        +tempDim[0] === item.length &&
        +tempDim[3] === item.width &&
        item.membership_type.toLowerCase().includes(planType)
      ) {
        dimenssion = `${item.length} in. x ${item.width} in.`;
      }
    });

    return dimenssion;
  };

  getEncryptId = (userId: number) => {
    let id = "";

    const encData = CryptoJS.AES.encrypt(
      JSON.stringify(userId),
      config.userId_encryption_key as string
    ).toString();
    id = encData.replace(/\//g, "@@@");
    return id;
  };

  /**
   * Function to calculate the status of order
   */
  calculateOrderStatus = (products: UserOderList[]) => {
    let cancelImages = 0;
    let approveImages = 0;
    let onHoldImages = 0;
    let pendingImages = 0;
    let inProgressImages = 0;
    let pendingCompletedImages = 0;
    let completedImages = 0;
    
    for (const product of products) {
      if (product.image_status === STATUS_ORDER.ON_HOLD) {
        onHoldImages += 1;
      } else if (product.image_status === STATUS_ORDER.APPROVE) {
        approveImages += 1;
      } else if (
        product.image_status === STATUS_ORDER.PENDING ||
        product.image_status == null
      ) {
        pendingImages += 1;
      } else if (product.image_status === STATUS_ORDER.CANCEL) {
        cancelImages += 1;
      } else if (product.image_status === STATUS_ORDER.PROCESSING) {
        inProgressImages += 1;
      } else if (product.image_status === STATUS_ORDER.COMPLETED_PENDING) {
        pendingCompletedImages += 1;
      } else if (product.image_status === STATUS_ORDER.COMPLETED) {
        completedImages += 1;
      }
    }

    let status = "";
    if (pendingImages) {
      status = STATUS_ORDER.PENDING;
    } else if (inProgressImages) {
      status = STATUS_ORDER.PROCESSING;
    } else if (
      pendingCompletedImages > 0 &&
      pendingCompletedImages < products.length
    ) {
      status = STATUS_ORDER.PROCESSING;
    } else if (pendingCompletedImages === products.length) {
      status = STATUS_ORDER.COMPLETED_PENDING;
    } else if (onHoldImages > 0 && pendingCompletedImages === 0) {
      status = STATUS_ORDER.ON_HOLD;
    } else if (cancelImages === products.length) {
      status = STATUS_ORDER.CANCEL;
    } else if (completedImages + cancelImages === products.length) {
      status = STATUS_ORDER.COMPLETED;
    } else if (
      approveImages === products.length ||
      completedImages + approveImages + cancelImages === products.length
    ) {
      status = STATUS_ORDER.APPROVE;
    }

    return status;
  };

  separateComma = (val: number) => {
    // remove sign if negative
    let sign = 1;
    if (val < 0) {
      sign = -1;
      val = -val;
    }
    // trim the number decimal point if it exists
    const num = val.toString().includes(".")
      ? val.toString().split(".")[0]
      : val.toString();
    const len = num.toString().length;
    let result = "";
    let count = 1;

    for (let i = len - 1; i >= 0; i -= 1) {
      result = num.toString()[i] + result;
      if (count % 3 === 0 && count !== 0 && i !== 0) {
        result = `,${result}`;
      }
      count += 1;
    }

    // add number after decimal point
    if (val.toString().includes(".")) {
      result = `${result}.${val.toString().split(".")[1]}`;
    }
    // return result with - sign if negative
    return sign < 0 ? `-${result}` : result;
  };

  getImagePpi = (width: number) => {
    const ppi = width / IMAGE_HEIGHT;
    return ppi;
  };

  oneUnitArea=(dim:string)=>{
    let area=0;
    if(dim  && dim!==""){
      const dimension= dim.split(" ");
      if(dimension && dimension.length>=4){
        area =(Number(dimension[0]) * Number(dimension[3])) /144;
      }
    };
    return area;
  };

  getsheetPriceByDimension=(baseTierData:ISheetPriceData,
     currDim:string,
    ) =>{
    let sheetBasePrice=0;
    if(baseTierData && currDim){
      sheetBasePrice = Number((
        this.oneUnitArea(currDim) *
        baseTierData.per_square_fit_price
      )?.toFixed(2));
    }
    return sheetBasePrice;
  };

  groupByData = <T extends (ItempImages | IArtWorkDataSubmit | EditOrderData) & ItemWithDimension>(
    arr: T[],
    from: keyof ItemWithDimension
  ): T[] => {
    const groupedData: { [key: string]: T } = {};
    arr.forEach(item => {
      if (Object.prototype.hasOwnProperty.call(item, from)) {
        const key = item[from];
        if(key){
          if (groupedData[key]) {
            groupedData[key].quantity = +groupedData[key].quantity + Number(item.quantity);
          } else {groupedData[key] = { ...item }};
        }
      }
    });
    return Object.values(groupedData).filter(item => Number(item.quantity) > 0);
  };
  
  calculateTotalArea = <T extends (ItempImages | IArtWorkDataSubmit | EditOrderData) & ItemWithDimension>(
    arr: T[],
    from: keyof ItemWithDimension
  ): number => {
    let totalArea=0;
    if(arr && arr.length>0){
      arr.forEach((item)=>{
        if(Object.prototype.hasOwnProperty.call(item, from)){
          const key = item[from];
          if(key){ totalArea += this.oneUnitArea(key) * item.quantity}
        }
      });
    };
    return Math.ceil(totalArea);
  };

  capitalizeCompanyName=(param?:string)=>{
   if(param){
    param=param.split("_").map(char=>upperFirst(char)).join("_")
   };
   return param;
  };
};

export default new Helper();
