export const ROLES = {
  USER: "User",
  ARTIST: "Artist",
  ADMIN: "Admin",
};

export const ALPHANUMERIC_REGEX = /^[a-z\d\-_\s]+$/i;
export const ALPHABATE_WITH_SPACE_REGEX = /^[a-zA-Z ]*$/;
export const PHONE_REGEX = /\b\d{10}\b/g;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
export const NAME_REGEX = /^[ a-zA-Z0-9\-’]+$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const QUANTITY_REGEX = /^[1-9][0-9]*?$/;
export const NUMBER_REGEX_WITH_SPACE = /^[0-9 ]*$/;
export const NEW_LINE_REGEX = /\r?\n/g;
export const ALPHABATE_REGEX = /\b[A-Za-z]/g;
export const CITY_REGEX = /^(?=.*[a-zA-Z ])( )([a-zA-Z0-9]+)$/;
export const DATE_FORMAT = "MM/YY";
export const TIME_FORMAT = "hh:mm A";
export const companyNameRegex = /^[a-zA-Z0-9&.,'\-\s]+( LLC| Ltd| Inc| Corp| Co\.| Company| LLP| AG| GmbH)?$/;

export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,._'"/\\-]*$/;

export const LIST_RECORDS_LIMIT = 10;
export const IMAGE_HEIGHT = 22;

export const SMALL_SHEET_BASE_PRICE = 8.95;
export const LARGE_SHEET_BASE_PRICE = 11.95;

export const TOKEN_EXPIRED = "token-expired";
export const SMALL_SHEET_SIZE = " 12 in.";
export const MOUSE_EVENT_UP = "ArrowUp";
export const MOUSE_EVENT_DOWN = "ArrowDown";
export const SALES_CHART = "UDDER SALES CHART";

export const SELECT_REASON = [
  { id: "reason_one", name: "Reason one" },
  { id: "reason_two", name: "Reason two" },
  { id: "reason_three", name: "Reason three" },
];
export const ORDER_STATUS = [
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
  { label: "Both", value: "both" },
];
export const QUANTITY = [
  { label: "One", value: 1 },
  { label: "Two", value: 2 },
  { label: "Three", value: 3 },
];
export const IMAGE_DIMENSSION = [
  { name: `22 in. x 12 in.`, value: `22"12"` },
  { name: `22 in. x 16 in.`, value: `22"16"` },
];
export const DISCOUNT_PER=29.61;
export const DISCOUNT_PERCENTAGE_TIER3=38.55;
export const BASE_TIER_PRICE=4.88182;

export const ADMIN_KEY="adminKey";
export const ARTIST_KEY="artistKey";
export const PNG_FLAG=".png";
export const PNG="png";
export const IMAGE="image";
export const PDF="pdf";
export const WILL_COLLECT="Will collect ($0.00)";

export const ARTIST_HOLD_REASON = [
  {
    name: "The artwork does not meet the minimum raster resolution requirement of 300 PPI.",
    value:
      "The artwork does not meet the minimum raster resolution requirement of 300 PPI.",
  },
  {
    name: "The artwork exceeds the maximum dimensions",
    value: "The artwork exceeds the maximum dimensions",
  },
  {
    name: "The artwork does not have clean edges.",
    value: "The artwork does not have clean edges.",
  },
  {
    name: " The artwork appears to have a background which may need to be removed.",
    value:
      " The artwork appears to have a background which may need to be removed.",
  },
  {
    name: "The artwork contains thin lines or small text which may not transfer well.",
    value:
      "The artwork contains thin lines or small text which may not transfer well.",
  },

  {
    name: "The artwork appears to contain copyrighted content",
    value: "The artwork appears to contain copyrighted content",
  },
];

export const ARTIST_DECLINE_REASON = [
  {
    name: "The artwork appears to contain copyrighted content.",
    value: "The artwork appears to contain copyrighted content.",
  },
  {
    name: "The artwork appears to contain content deemed unacceptable.",
    value: "The artwork appears to contain content deemed unacceptable.",
  },
  {
    name: "The artwork appears to contain content which is not allowed.",
    value: "The artwork appears to contain content which is not allowed.",
  },
];

export const PARCEL = {
  length: "5",
  width: "5",
  height: "5",
  distance_unit: "in",
  weight: "2",
  mass_unit: "lb",
};

export const FROM_ADDRESS = {
  name: "Linder",
  company: "Udder Color",
  street1: "3511 Charleston Rd",
  city: "Norman",
  state: "OK",
  zip: "73069",
  country: "US",
  phone: "+1 405 989 8768",
  email: "admin@linderscreenprinting.com",
};

export const STATUS_ORDER = {
  PENDING: "Pending",
  PROCESSING: "In Progress",
  APPROVE: "Approved",
  ON_HOLD: "On Hold",
  DECLINE: "Declined",
  CANCEL: "Canceled",
  DELIVERED: "Delivered",
  COMPLETED: "Completed",
  COMPLETED_PENDING: "Printed",
  UPDATED: "Updated",
  PAYMENT_PENDING: "Payment pending",
  CANCELLED: "Canceled",
  APPROVE_BY_ARTIST: "Approve by artist",
  ALL: "All",
};

export const ARTIST_ORDER_STATUS = { APPROVE_BY_ARTIST: "Approve by artist" };

export const ADMIN_STATUS_DROPDOWN = [
  { name: "In Progress", id: "In Progress" },
  { name: "Approved", id: "Approved" },
  { name: "On Hold", id: "On Hold" },
  { name: "Canceled", id: "Canceled" },
  { name: "Completed", id: "Completed" },
  { name: "Printed", id: "Printed" },
  { name: "Payment pending", id: "Payment pending" },
];

export const MEMBERSHIP_PLANS = [
  { name: "Basic Membership", value: "basic" },
  { name: "Advanced Membership", value: "advance" },
];

export const QUANTITY_RANGE = {
  lessThanThirty: "lessThenThirty",
  moreThanSeventyFive: "moreThanSeventyFive",
  betweenThirtySevetyFive: "betweenThirtySevetyFive",
};

export const MEMBERSHIP_PLANS_TYPE = [
  { name: "Basic Membership", value: "basic" },
  { name: "Advanced Membership", value: "advanced" },
];
export const TAX_PLANS_TYPE = [
  { name: "Tax-exempt", value: 1 },
  { name: "Taxable", value: 0 },
];

export const CURRENCY_SYMBOL = "$";

export const GATE_WAY_TYPE = {
  authorize: "Authorize",
  stripe: "Stripe",
};


export const tabArray = [
  { text: "all", status: STATUS_ORDER.ALL },
  { text: "pending", status: STATUS_ORDER.PENDING },
  { text: "approved", status: STATUS_ORDER.APPROVE },
  { text: "in_progress", status: STATUS_ORDER.PROCESSING },
  { text: "artist_on_hold", status: STATUS_ORDER.ON_HOLD },
  { text: "completed_pending", status: STATUS_ORDER.COMPLETED_PENDING },
  { text: "completed", status: STATUS_ORDER.COMPLETED },
  { text: "payment_pending", status: STATUS_ORDER.PAYMENT_PENDING },
  { text: "cancelled", status: STATUS_ORDER.CANCELLED },
];

export const UPS_TRACKIN_LINK =
  "http://wwwapps.ups.com/WebTracking/track?HTMLVersion=5.0&loc=en_US&Requester=UPSHome&WBPM_lid=homepage%2Fct1.html_pnl_trk&track.x=Track&trackNums=";

export const ORDER_FILE_ACTIONS = {
  artist: {
    Pending: [
      {
        name: "Pending",
        value: "Pending",
      },
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
    ],
    Approved: [
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
      {
        name: "In Progress",
        value: "InProgress",
      },
      {
        name: "Printed",
        value: "Printed",
      },
    ],
    "On Hold": [
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
    ],
    Canceled: [
      {
        name: "Canceled",
        value: "Canceled",
      },
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
    ],

    "In Progress": [
      {
        name: "In Progress",
        value: "In Progress",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
      {
        name: "Printed",
        value: "Printed",
      },
    ],
    Printed: [
      {
        name: "Printed",
        value: "Printed",
      },
      {
        name: "In Progress",
        value: "In Progress",
      },
    ],
    Completed: [
      {
        name: "Completed",
        value: "Completed",
      },
    ],
  },
  admin: {
    Pending: [
      {
        name: "Pending",
        value: "Pending",
      },
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
    ],
    Approved: [
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
      {
        name: "In Progress",
        value: "InProgress",
      },
      {
        name: "Completed",
        value: "Completed",
      },
    ],
    "On Hold": [
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
    ],
    Canceled: [
      {
        name: "Canceled",
        value: "Canceled",
      },
      {
        name: "Approved",
        value: "Approved",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
    ],
    "In Progress": [
      {
        name: "In Progress",
        value: "In Progress",
      },
      {
        name: "On Hold",
        value: "On Hold",
      },
      {
        name: "Canceled",
        value: "Canceled",
      },
      {
        name: "Printed",
        value: "Printed",
      },
    ],
    Printed: [
      {
        name: "Printed",
        value: "Printed",
      },
      {
        name: "In Progress",
        value: "InProgress",
      },
      {
        name: "Completed",
        value: "Completed",
      },
    ],
    Completed: [
      {
        name: "Completed",
        value: "Completed",
      },
      {
        name: "In Progress",
        value: "InProgress",
      },
    ],
  },
};

export const STATE_LIST = [
  {
    name: "Alabama",
    abbreviation: "AL",
    id: "Alabama",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
    id: "Alaska",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
    id: "Arizona",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
    id: "Arkansas",
  },
  {
    name: "California",
    abbreviation: "CA",
    id: "California",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
    id: "Colorado",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
    id: "Connecticut",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
    id: "Delaware",
  },
  {
    name: "Florida",
    abbreviation: "FL",
    id: "Florida",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
    id: "Georgia",
  },
  {
    name: "District of Columbia",
    abbreviation: "DC",
    id: "District of Columbia",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
    id: "Hawaii",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
    id: "Idaho",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
    id: "Illinois",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
    id: "Indiana",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
    id: "Iowa",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
    id: "Kansas",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
    id: "Kentucky",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
    id: "Louisiana",
  },
  {
    name: "Maine",
    abbreviation: "ME",
    id: "Maine",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
    id: "Maryland",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
    id: "Massachusetts",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
    id: "Michigan",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
    id: "Minnesota",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
    id: "Mississippi",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
    id: "Missouri",
  },
  {
    name: "Montana",
    abbreviation: "MT",
    id: "Montana",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
    id: "Nebraska",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
    id: "Nevada",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
    id: "New Hampshire",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
    id: "New Jersey",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
    id: "New Mexico",
  },
  {
    name: "New York",
    abbreviation: "NY",
    id: "New York",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
    id: "North Carolina",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
    id: "North Dakota",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
    id: "Ohio",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
    id: "Oklahoma",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
    id: "Oregon",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
    id: "Pennsylvania",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
    id: "Rhode Island",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
    id: "South Carolina",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
    id: "South Dakota",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
    id: "Tennessee",
  },
  {
    name: "Texas",
    abbreviation: "TX",
    id: "Texas",
  },
  {
    name: "Utah",
    abbreviation: "UT",
    id: "Utah",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
    id: "Vermont",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
    id: "Virginia",
  },
  {
    name: "Washington",
    abbreviation: "WA",
    id: "Washington",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
    id: "West Virginia",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
    id: "Wisconsin",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
    id: "Wyoming",
  },
];

export const CITY_LIST = [
  { id: "Abbeville", state_id: "Louisiana" },
  { id: "Aberdeen", state_id: "Maryland" },
  { id: "Aberdeen", state_id: "Mississippi" },
  { id: "Aberdeen", state_id: "South Dakota" },
  { id: "Aberdeen", state_id: "Washington" },
  { id: "Abilene", state_id: "Texas" },
  { id: "Abilene", state_id: "Kansas" },
  { id: "Abingdon", state_id: "Virginia" },
  { id: "Abington", state_id: "Massachusetts" },
  { id: "Abington", state_id: "Massachusetts" },
  { id: "Absecon", state_id: "New Jersey" },
  { id: "Accokeek", state_id: "Maryland" },
  { id: "Acton", state_id: "Massachusetts" },
  { id: "Acushnet", state_id: "Massachusetts" },
  { id: "Acworth", state_id: "Georgia" },
  { id: "Ada", state_id: "Oklahoma" },
  { id: "Adams", state_id: "Massachusetts" },
  { id: "Addison", state_id: "Illinois" },
  { id: "Addison", state_id: "Texas" },
  { id: "Adelanto", state_id: "California" },
  { id: "Adelphi", state_id: "Maryland" },
  { id: "Adrian", state_id: "Michigan" },
  { id: "Affton", state_id: "Missouri" },
  { id: "Agawam", state_id: "Massachusetts" },
  { id: "Agoura Hills", state_id: "California" },
  { id: "Ahuimanu", state_id: "Hawaii" },
  { id: "Aiea", state_id: "Hawaii" },
  { id: "Aiken", state_id: "South Carolina" },
  { id: "Air Force Academy", state_id: "Colorado" },
  { id: "Airmont", state_id: "New York" },
  { id: "Akron", state_id: "Ohio" },
  { id: "Alabaster", state_id: "Alabama" },
  { id: "Alachua", state_id: "Florida" },
  { id: "Alameda", state_id: "California" },
  { id: "Alamo", state_id: "California" },
  { id: "Alamo", state_id: "Texas" },
  { id: "Alamo Heights", state_id: "Texas" },
  { id: "Alamogordo", state_id: "New Mexico" },
  { id: "Alamosa", state_id: "Colorado" },
  { id: "Albany", state_id: "Georgia" },
  { id: "Albany", state_id: "California" },
  { id: "Albany", state_id: "New York" },
  { id: "Albany", state_id: "Oregon" },
  { id: "Albemarle", state_id: "North Carolina" },
  { id: "Albert Lea", state_id: "Minnesota" },
  { id: "Rice", state_id: "Minnesota" },
  { id: "Albertville", state_id: "Alabama" },
  { id: "Albion", state_id: "Michigan" },
  { id: "Albion", state_id: "New York" },
  { id: "Albion", state_id: "New York" },
  { id: "Albuquerque", state_id: "New Mexico" },
  { id: "Alcoa", state_id: "Tennessee" },
  { id: "Alden", state_id: "New York" },
  { id: "Alderwood Manor", state_id: "Washington" },
  { id: "Aldine", state_id: "Texas" },
  { id: "Alexander City", state_id: "Alabama" },
  { id: "Alexandria", state_id: "Indiana" },
  { id: "Alexandria", state_id: "Minnesota" },
  { id: "Alexandria", state_id: "Kentucky" },
  { id: "Alexandria", state_id: "Louisiana" },
  { id: "Alexandria", state_id: "Virginia" },
  { id: "Henrico", state_id: "Virginia" },
  { id: "Algonquin", state_id: "Illinois" },
  { id: "Alhambra", state_id: "California" },
  { id: "Alice", state_id: "Texas" },
  { id: "Aliquippa", state_id: "Pennsylvania" },
  { id: "Aliso Viejo", state_id: "California" },
  { id: "Allegany", state_id: "New York" },
  { id: "Allen", state_id: "Texas" },
  { id: "Allen Park", state_id: "Michigan" },
  { id: "Allendale", state_id: "Michigan" },
  { id: "Allendale", state_id: "New Jersey" },
  { id: "Allentown", state_id: "Pennsylvania" },
  { id: "Alliance", state_id: "Ohio" },
  { id: "Alliance", state_id: "Nebraska" },
  { id: "Allouez", state_id: "Wisconsin" },
  { id: "Alma", state_id: "Michigan" },
  { id: "Aloha", state_id: "Oregon" },
  { id: "Alondra Park", state_id: "California" },
  { id: "Alpena", state_id: "Michigan" },
  { id: "Alpharetta", state_id: "Georgia" },
  { id: "Alpine", state_id: "California" },
  { id: "Alpine", state_id: "Utah" },
  { id: "Alsip", state_id: "Illinois" },
  { id: "Alta Sierra", state_id: "California" },
  { id: "Altadena", state_id: "California" },
  { id: "Altamont", state_id: "Oregon" },
  { id: "Altamont", state_id: "New York" },
  { id: "Altamonte Springs", state_id: "Florida" },
  { id: "Alton", state_id: "Illinois" },
  { id: "Altoona", state_id: "Iowa" },
  { id: "Altoona", state_id: "Pennsylvania" },
  { id: "Altoona", state_id: "Wisconsin" },
  { id: "Altus", state_id: "Oklahoma" },
  { id: "Fort Towson", state_id: "Oklahoma" },
  { id: "Alum Rock", state_id: "California" },
  { id: "Alvin", state_id: "Texas" },
  { id: "Amarillo", state_id: "Texas" },
  { id: "Ambler", state_id: "Pennsylvania" },
  { id: "Ambridge", state_id: "Pennsylvania" },
  { id: "American Canyon", state_id: "California" },
  { id: "American Fork", state_id: "Utah" },
  { id: "Americus", state_id: "Georgia" },
  { id: "Ames", state_id: "Iowa" },
  { id: "Chariton", state_id: "Iowa" },
  { id: "Amesbury", state_id: "Massachusetts" },
  { id: "Amesbury", state_id: "Massachusetts" },
  { id: "Amherst", state_id: "New Hampshire" },
  { id: "Amherst", state_id: "Massachusetts" },
  { id: "Amherst", state_id: "Ohio" },
  { id: "Amherst", state_id: "New York" },
  { id: "Amherst Center", state_id: "Massachusetts" },
  { id: "Amityville", state_id: "New York" },
  { id: "Ammon", state_id: "Idaho" },
  { id: "Amory", state_id: "Mississippi" },
  { id: "Amsterdam", state_id: "New York" },
  { id: "Anaconda-Deer Lodge County", state_id: "Montana" },
  { id: "Anacortes", state_id: "Washington" },
  { id: "Anadarko", state_id: "Oklahoma" },
  { id: "Anaheim", state_id: "California" },
  { id: "Anchorage", state_id: "Alaska" },
  { id: "Andalusia", state_id: "Alabama" },
  { id: "Anderson", state_id: "California" },
  { id: "Anderson", state_id: "Indiana" },
  { id: "Anderson", state_id: "South Carolina" },
  { id: "Anderson Mill", state_id: "Texas" },
  { id: "Andover", state_id: "Florida" },
  { id: "Andover", state_id: "Minnesota" },
  { id: "Andover", state_id: "Massachusetts" },
  { id: "Andover", state_id: "Kansas" },
  { id: "Andover", state_id: "Massachusetts" },
  { id: "Andrews", state_id: "Texas" },
  { id: "Andrews AFB", state_id: "Maryland" },
  { id: "Angleton", state_id: "Texas" },
  { id: "Angola", state_id: "Indiana" },
  { id: "Ankeny", state_id: "Iowa" },
  { id: "Ann Arbor", state_id: "Michigan" },
  { id: "Annandale", state_id: "Virginia" },
  { id: "Annapolis", state_id: "Maryland" },
  { id: "Anniston", state_id: "Alabama" },
  { id: "Anoka", state_id: "Minnesota" },
  { id: "Ansonia", state_id: "Connecticut" },
  { id: "Ansonia", state_id: "Connecticut" },
  { id: "Anthony", state_id: "New Mexico" },
  { id: "Antigo", state_id: "Wisconsin" },
  { id: "Antioch", state_id: "Illinois" },
  { id: "Antioch", state_id: "California" },
  { id: "Apache Junction", state_id: "Arizona" },
  { id: "Apex", state_id: "North Carolina" },
  { id: "Apollo Beach", state_id: "Florida" },
  { id: "Apopka", state_id: "Florida" },
  { id: "Apple Valley", state_id: "California" },
  { id: "Apple Valley", state_id: "Minnesota" },
  { id: "Appleton", state_id: "Wisconsin" },
  { id: "Applewood", state_id: "Colorado" },
  { id: "Aptos", state_id: "California" },
  { id: "Aquia Harbour", state_id: "Virginia" },
  { id: "Arab", state_id: "Alabama" },
  { id: "Arabi", state_id: "Louisiana" },
  { id: "Aransas Pass", state_id: "Texas" },
  { id: "Arbutus", state_id: "Maryland" },
  { id: "Arcadia", state_id: "California" },
  { id: "Arcadia", state_id: "Florida" },
  { id: "Arcadia", state_id: "New York" },
  { id: "Arcata", state_id: "California" },
  { id: "Archbald", state_id: "Pennsylvania" },
  { id: "Archdale", state_id: "North Carolina" },
  { id: "Arden Hills", state_id: "Minnesota" },
  { id: "Arden-Arcade", state_id: "California" },
  { id: "Ardmore", state_id: "Oklahoma" },
  { id: "Ardmore", state_id: "Pennsylvania" },
  { id: "Arkadelphia", state_id: "Arkansas" },
  { id: "Arkansas City", state_id: "Kansas" },
  { id: "Arlington", state_id: "Massachusetts" },
  { id: "Arlington", state_id: "Massachusetts" },
  { id: "Arlington", state_id: "Texas" },
  { id: "Arlington", state_id: "New York" },
  { id: "Arlington", state_id: "Virginia" },
  { id: "Arlington", state_id: "Washington" },
  { id: "Arlington Heights", state_id: "Illinois" },
  { id: "Arnold", state_id: "Missouri" },
  { id: "Lees Summit", state_id: "Missouri" },
  { id: "Arnold", state_id: "Maryland" },
  { id: "Arroyo Grande", state_id: "California" },
  { id: "Artesia", state_id: "California" },
  { id: "Artesia", state_id: "New Mexico" },
  { id: "Artondale", state_id: "Washington" },
  { id: "Arvada", state_id: "Colorado" },
  { id: "Arvin", state_id: "California" },
  { id: "Asbury Park", state_id: "New Jersey" },
  { id: "Asheboro", state_id: "North Carolina" },
  { id: "Asheville", state_id: "North Carolina" },
  { id: "Ashland", state_id: "Oregon" },
  { id: "Ashland", state_id: "Ohio" },
  { id: "Ashland", state_id: "Virginia" },
  { id: "Ashland", state_id: "Wisconsin" },
  { id: "Slinger", state_id: "Wisconsin" },
  { id: "Ashland", state_id: "New Jersey" },
  { id: "Ashland", state_id: "Massachusetts" },
  { id: "Ashland", state_id: "Kentucky" },
  { id: "Ashland", state_id: "California" },
  { id: "Ashtabula", state_id: "Ohio" },
  { id: "Ashwaubenon", state_id: "Wisconsin" },
  { id: "Aspen Hill", state_id: "Maryland" },
  { id: "Astoria", state_id: "Oregon" },
  { id: "Atascadero", state_id: "California" },
  { id: "Atascocita", state_id: "Texas" },
  { id: "Atchison", state_id: "Kansas" },
  { id: "Athens", state_id: "Alabama" },
  { id: "Athens", state_id: "Texas" },
  { id: "Athens", state_id: "Tennessee" },
  { id: "Athens", state_id: "Ohio" },
  { id: "Athens-Clarke County", state_id: "Georgia" },
  { id: "Atherton", state_id: "California" },
  { id: "Athol", state_id: "Massachusetts" },
  { id: "Athol", state_id: "Massachusetts" },
  { id: "Atkinson", state_id: "New Hampshire" },
  { id: "Atlanta", state_id: "Georgia" },
  { id: "Atlantic", state_id: "Iowa" },
  { id: "Atlantic Beach", state_id: "Florida" },
  { id: "Atlantic City", state_id: "New Jersey" },
  { id: "Atmore", state_id: "Alabama" },
  { id: "Attalla", state_id: "Alabama" },
  { id: "Attica", state_id: "New York" },
  { id: "Attleboro", state_id: "Massachusetts" },
  { id: "Atwater", state_id: "California" },
  { id: "Auburn", state_id: "California" },
  { id: "Auburn", state_id: "Alabama" },
  { id: "Auburn", state_id: "Georgia" },
  { id: "Auburn", state_id: "Indiana" },
  { id: "Auburn", state_id: "Massachusetts" },
  { id: "Auburn", state_id: "Maine" },
  { id: "Auburn", state_id: "New York" },
  { id: "Auburn", state_id: "Washington" },
  { id: "Auburn Hills", state_id: "Michigan" },
  { id: "Auburndale", state_id: "Florida" },
  { id: "Audubon", state_id: "New Jersey" },
  { id: "Audubon", state_id: "Pennsylvania" },
  { id: "August", state_id: "California" },
  { id: "Augusta", state_id: "Maine" },
  { id: "Augusta", state_id: "Kansas" },
  { id: "Augusta-Richmond County", state_id: "Georgia" },
  { id: "Aurora", state_id: "Illinois" },
  { id: "Aurora", state_id: "Colorado" },
  { id: "Aurora", state_id: "Missouri" },
  { id: "Aurora", state_id: "New York" },
  { id: "Aurora", state_id: "Ohio" },
  { id: "Austin", state_id: "Texas" },
  { id: "Austin", state_id: "Minnesota" },
  { id: "Austintown", state_id: "Ohio" },
  { id: "Avenal", state_id: "California" },
  { id: "Avenel", state_id: "New Jersey" },
  { id: "Aventura", state_id: "Florida" },
  { id: "Avocado Heights", state_id: "California" },
  { id: "Avon", state_id: "Connecticut" },
  { id: "Avon", state_id: "Indiana" },
  { id: "Avon", state_id: "Ohio" },
  { id: "Avon", state_id: "New York" },
  { id: "Avon Lake", state_id: "Ohio" },
  { id: "Avon Park", state_id: "Florida" },
  { id: "Avondale", state_id: "Arizona" },
  { id: "Ayer", state_id: "Massachusetts" },
  { id: "Azalea Park", state_id: "Florida" },
  { id: "Azle", state_id: "Texas" },
  { id: "Aztec", state_id: "New Mexico" },
  { id: "Azusa", state_id: "California" },

  { id: "Babylon", state_id: "New York" },
  { id: "Babylon", state_id: "New York" },
  { id: "Back Mountain", state_id: "Pennsylvania" },
  { id: "Bacliff", state_id: "Texas" },
  { id: "Bailey’s Crossroads", state_id: "Virginia" },
  { id: "Bainbridge", state_id: "Georgia" },
  { id: "Bainbridge Island", state_id: "Washington" },
  { id: "Baker", state_id: "Louisiana" },
  { id: "Baker City", state_id: "Oregon" },
  { id: "Bakersfield", state_id: "California" },
  { id: "Balch Springs", state_id: "Texas" },
  { id: "Baldwin", state_id: "Pennsylvania" },
  { id: "Baldwin", state_id: "New York" },
  { id: "Baldwin Harbor", state_id: "New York" },
  { id: "Baldwin Park", state_id: "California" },
  { id: "Baldwinsville", state_id: "New York" },
  { id: "Ballenger Creek", state_id: "Maryland" },
  { id: "Ballston", state_id: "New York" },
  { id: "Ballwin", state_id: "Missouri" },
  { id: "Baltimore", state_id: "Maryland" },
  { id: "Bangor", state_id: "Maine" },
  { id: "Bangor Trident Base", state_id: "Washington" },
  { id: "Banning", state_id: "California" },
  { id: "Baraboo", state_id: "Wisconsin" },
  { id: "Barberton", state_id: "Ohio" },
  { id: "Barclay-Kingston", state_id: "New Jersey" },
  { id: "Bardstown", state_id: "Kentucky" },
  { id: "Barnhart", state_id: "Missouri" },
  { id: "Barnstable Town", state_id: "Massachusetts" },
  { id: "Barre", state_id: "Vermont" },
  { id: "Barre", state_id: "Vermont" },
  { id: "Barrington", state_id: "Rhode Island" },
  { id: "Barrington", state_id: "Rhode Island" },
  { id: "Barrington", state_id: "New Jersey" },
  { id: "Barrington", state_id: "New Hampshire" },
  { id: "Barrington", state_id: "Illinois" },
  { id: "Barstow", state_id: "California" },
  { id: "Bartlesville", state_id: "Oklahoma" },
  { id: "Bartlett", state_id: "Illinois" },
  { id: "Bartlett", state_id: "Tennessee" },
  { id: "Barton", state_id: "New York" },
  { id: "Bartonville", state_id: "Illinois" },
  { id: "Bartow", state_id: "Florida" },
  { id: "Bastrop", state_id: "Louisiana" },
  { id: "Batavia", state_id: "Illinois" },
  { id: "Batavia", state_id: "New York" },
  { id: "Batesville", state_id: "Mississippi" },
  { id: "Batesville", state_id: "Indiana" },
  { id: "Batesville", state_id: "Arkansas" },
  { id: "Bath", state_id: "Maine" },
  { id: "Bath", state_id: "New York" },
  { id: "Baton Rouge", state_id: "Louisiana" },
  { id: "Battle Creek", state_id: "Michigan" },
  { id: "Battle Ground", state_id: "Washington" },
  { id: "Bay City", state_id: "Texas" },
  { id: "Bay City", state_id: "Michigan" },
  { id: "Bay Minette", state_id: "Alabama" },
  { id: "Bay Point", state_id: "California" },
  { id: "Bay Shore", state_id: "New York" },
  { id: "Bay St. Louis", state_id: "Mississippi" },
  { id: "Bay Village", state_id: "Ohio" },
  { id: "Bayonet Point", state_id: "Florida" },
  { id: "Bayonne", state_id: "New Jersey" },
  { id: "Bayou Cane", state_id: "Louisiana" },
  { id: "Bayport", state_id: "New York" },
  { id: "Bayshore Gardens", state_id: "Florida" },
  { id: "Baytown", state_id: "Texas" },
  { id: "Bayville", state_id: "New York" },
  { id: "Baywood", state_id: "New York" },
  { id: "Baywood-Los Osos", state_id: "California" },
  { id: "Beach Park", state_id: "Illinois" },
  { id: "Beachwood", state_id: "Ohio" },
  { id: "Beachwood", state_id: "New Jersey" },
  { id: "Beacon", state_id: "New York" },
  { id: "Beacon Square", state_id: "Florida" },
  { id: "Bear", state_id: "Delaware" },
  { id: "Beatrice", state_id: "Nebraska" },
  { id: "Beaufort", state_id: "South Carolina" },
  { id: "Beaumont", state_id: "Texas" },
  { id: "Beaumont", state_id: "California" },
  { id: "Beaver Dam", state_id: "Wisconsin" },
  { id: "Beaver Falls", state_id: "Pennsylvania" },
  { id: "Beavercreek", state_id: "Ohio" },
  { id: "Beaverton", state_id: "Oregon" },
  { id: "Beckett Ridge", state_id: "Ohio" },
  { id: "Beckley", state_id: "West Virginia" },
  { id: "Bedford", state_id: "Virginia" },
  { id: "Bedford", state_id: "Texas" },
  { id: "Bedford", state_id: "Ohio" },
  { id: "Bedford", state_id: "New York" },
  { id: "Bedford", state_id: "New Hampshire" },
  { id: "Bedford", state_id: "Massachusetts" },
  { id: "Bedford", state_id: "Indiana" },
  { id: "Bedford Heights", state_id: "Ohio" },
  { id: "Bee Ridge", state_id: "Florida" },
  { id: "Beech Grove", state_id: "Indiana" },
  { id: "Beecher", state_id: "Michigan" },
  { id: "Beekman", state_id: "New York" },
  { id: "Beeville", state_id: "Texas" },
  { id: "Bel Air", state_id: "Maryland" },
  { id: "Bel Air North", state_id: "Maryland" },
  { id: "Bel Air South", state_id: "Maryland" },
  { id: "Belchertown", state_id: "Massachusetts" },
  { id: "Belen", state_id: "New Mexico" },
  { id: "Belfast", state_id: "Maine" },
  { id: "Bell", state_id: "California" },
  { id: "Bell Gardens", state_id: "California" },
  { id: "Bella Vista", state_id: "Arkansas" },
  { id: "Bellair-Meadowbrook Terrace", state_id: "Florida" },
  { id: "Bellaire", state_id: "Texas" },
  { id: "Bellbrook", state_id: "Ohio" },
  { id: "Belle Chasse", state_id: "Louisiana" },
  { id: "Belle Glade", state_id: "Florida" },
  { id: "Belle Haven", state_id: "Virginia" },
  { id: "Bellefontaine", state_id: "Ohio" },
  { id: "Bellefontaine Neighbors", state_id: "Missouri" },
  { id: "Bellefonte", state_id: "Pennsylvania" },
  { id: "Belleville", state_id: "New Jersey" },
  { id: "Belleville", state_id: "Illinois" },
  { id: "Bellevue", state_id: "Kentucky" },
  { id: "Bellevue", state_id: "Nebraska" },
  { id: "Rego Park", state_id: "Nebraska" },
  { id: "Bellevue", state_id: "Ohio" },
  { id: "Bellevue", state_id: "Pennsylvania" },
  { id: "Bellevue", state_id: "Wisconsin" },
  { id: "Bellevue", state_id: "Washington" },
  { id: "Bellevue Town", state_id: "Wisconsin" },
  { id: "Bellflower", state_id: "California" },
  { id: "Bellingham", state_id: "Massachusetts" },
  { id: "Bellingham", state_id: "Washington" },
  { id: "Bellmawr", state_id: "New Jersey" },
  { id: "Bellmead", state_id: "Texas" },
  { id: "Bellmore", state_id: "New York" },
  { id: "Bellview", state_id: "Florida" },
  { id: "Bellwood", state_id: "Illinois" },
  { id: "Belmar", state_id: "New Jersey" },
  { id: "Belmont", state_id: "Massachusetts" },
  { id: "Belmont", state_id: "Massachusetts" },
  { id: "Belmont", state_id: "New Hampshire" },
  { id: "Belmont", state_id: "North Carolina" },
  { id: "Belmont", state_id: "California" },
  { id: "Beloit", state_id: "Wisconsin" },
  { id: "Beloit", state_id: "Wisconsin" },
  { id: "Belpre", state_id: "Ohio" },
  { id: "Belton", state_id: "Missouri" },
  { id: "Belton", state_id: "Texas" },
  { id: "Beltsville", state_id: "Maryland" },
  { id: "Belvedere Park", state_id: "Georgia" },
  { id: "Belvidere", state_id: "Illinois" },
  { id: "Bemidji", state_id: "Minnesota" },
  { id: "Benbrook", state_id: "Texas" },
  { id: "Bend", state_id: "Oregon" },
  { id: "Benicia", state_id: "California" },
  { id: "Bennettsville", state_id: "South Carolina" },
  { id: "Bennington", state_id: "Vermont" },
  { id: "Bennington", state_id: "Vermont" },
  { id: "Bennsville", state_id: "Maryland" },
  { id: "Bensenville", state_id: "Illinois" },
  { id: "Benton", state_id: "Illinois" },
  { id: "Benton", state_id: "Arkansas" },
  { id: "Benton Harbor", state_id: "Michigan" },
  { id: "Bentonville", state_id: "Arkansas" },
  { id: "Berea", state_id: "Kentucky" },
  { id: "Berea", state_id: "Ohio" },
  { id: "Berea", state_id: "South Carolina" },
  { id: "Bergenfield", state_id: "New Jersey" },
  { id: "Berkeley", state_id: "Missouri" },
  { id: "Berkeley", state_id: "California" },
  { id: "Berkeley Heights", state_id: "New Jersey" },
  { id: "Berkley", state_id: "Michigan" },
  { id: "Berkley", state_id: "Colorado" },
  { id: "Berlin", state_id: "Connecticut" },
  { id: "Berlin", state_id: "New Jersey" },
  { id: "Berlin", state_id: "New Hampshire" },
  { id: "Bermuda Dunes", state_id: "California" },
  { id: "Bernalillo", state_id: "New Mexico" },
  { id: "Bernardsville", state_id: "New Jersey" },
  { id: "Berwick", state_id: "Maine" },
  { id: "Berwick", state_id: "Pennsylvania" },
  { id: "Berwyn", state_id: "Illinois" },
  { id: "Bessemer", state_id: "Alabama" },
  { id: "Bethalto", state_id: "Illinois" },
  { id: "Bethany", state_id: "Oklahoma" },
  { id: "Bethel", state_id: "Connecticut" },
  { id: "Bethel", state_id: "Connecticut" },
  { id: "Bethel Park", state_id: "Pennsylvania" },
  { id: "Bethesda", state_id: "Maryland" },
  { id: "Bethlehem", state_id: "New York" },
  { id: "Bethlehem", state_id: "Pennsylvania" },
  { id: "Bethpage", state_id: "New York" },
  { id: "Bettendorf", state_id: "Iowa" },
  { id: "Beverly", state_id: "Massachusetts" },
  { id: "Beverly Hills", state_id: "Michigan" },
  { id: "Beverly Hills", state_id: "Florida" },
  { id: "Beverly Hills", state_id: "California" },
  { id: "Bexley", state_id: "Ohio" },
  { id: "Biddeford", state_id: "Maine" },
  { id: "Big Flats", state_id: "New York" },
  { id: "Big Lake", state_id: "Minnesota" },
  { id: "Big Rapids", state_id: "Michigan" },
  { id: "Big Spring", state_id: "Texas" },
  { id: "Billerica", state_id: "Massachusetts" },
  { id: "Billings", state_id: "Montana" },
  { id: "Biloxi", state_id: "Mississippi" },
  { id: "Binghamton", state_id: "New York" },
  { id: "Birmingham", state_id: "Michigan" },
  { id: "Birmingham", state_id: "Alabama" },
  { id: "Bisbee", state_id: "Arizona" },
  { id: "Bismarck", state_id: "North Dakota" },
  { id: "Bixby", state_id: "Oklahoma" },
  { id: "Black Forest", state_id: "Colorado" },
  { id: "Black Jack", state_id: "Missouri" },
  { id: "Black Mountain", state_id: "North Carolina" },
  { id: "Blackfoot", state_id: "Idaho" },
  { id: "Blackhawk-Camino Tassajara", state_id: "California" },
  { id: "Blacklick Estates", state_id: "Ohio" },
  { id: "Blacksburg", state_id: "Virginia" },
  { id: "Blackstone", state_id: "Massachusetts" },
  { id: "Blackwell", state_id: "Oklahoma" },
  { id: "Bladensburg", state_id: "Maryland" },
  { id: "Blaine", state_id: "Minnesota" },
  { id: "Blair", state_id: "Nebraska" },
  { id: "Blakely", state_id: "Pennsylvania" },
  { id: "Bloomfield", state_id: "New Jersey" },
  { id: "Bloomfield", state_id: "New Mexico" },
  { id: "Bloomfield", state_id: "Connecticut" },
  { id: "Bloomfield Township", state_id: "Michigan" },
  { id: "Blooming Grove", state_id: "New York" },
  { id: "Bloomingdale", state_id: "New Jersey" },
  { id: "Bloomingdale", state_id: "Florida" },
  { id: "Bloomingdale", state_id: "Illinois" },
  { id: "Bloomingdale", state_id: "Tennessee" },
  { id: "Bloomington", state_id: "Illinois" },
  { id: "Bloomington", state_id: "Indiana" },
  { id: "Bloomington", state_id: "California" },
  { id: "Bloomington", state_id: "Minnesota" },
  { id: "Bloomsburg", state_id: "Pennsylvania" },
  { id: "Blue Ash", state_id: "Ohio" },
  { id: "Blue Bell", state_id: "Pennsylvania" },
  { id: "Blue Island", state_id: "Illinois" },
  { id: "Blue Springs", state_id: "Missouri" },
  { id: "Bluefield", state_id: "West Virginia" },
  { id: "Bluffton", state_id: "Indiana" },
  { id: "Blythe", state_id: "California" },
  { id: "Blytheville", state_id: "Arkansas" },
  { id: "Boardman", state_id: "Ohio" },
  { id: "Boaz", state_id: "Alabama" },
  { id: "Boca Del Mar", state_id: "Florida" },
  { id: "Boca Raton", state_id: "Florida" },
  { id: "Boerne", state_id: "Texas" },
  { id: "Bogalusa", state_id: "Louisiana" },
  { id: "Bogota", state_id: "New Jersey" },
  { id: "Bohemia", state_id: "New York" },
  { id: "Boise City", state_id: "Idaho" },
  { id: "Bolingbrook", state_id: "Illinois" },
  { id: "Bolivar", state_id: "Missouri" },
  { id: "Bon Air", state_id: "Virginia" },
  { id: "Bonadelle Ranchos-Madera Ranchos", state_id: "California" },
  { id: "Bonham", state_id: "Texas" },
  { id: "Bonita", state_id: "California" },
  { id: "Bonita Springs", state_id: "Florida" },
  { id: "Bonner Springs", state_id: "Kansas" },
  { id: "Bonney Lake", state_id: "Washington" },
  { id: "Boone", state_id: "Iowa" },
  { id: "Boone", state_id: "North Carolina" },
  { id: "Booneville", state_id: "Mississippi" },
  { id: "Boonton", state_id: "New Jersey" },
  { id: "Boonville", state_id: "Missouri" },
  { id: "Boonville", state_id: "Indiana" },
  { id: "Borger", state_id: "Texas" },
  { id: "Bossier City", state_id: "Louisiana" },
  { id: "Boston", state_id: "Massachusetts" },
  { id: "Boston", state_id: "New York" },
  { id: "Bostonia", state_id: "California" },
  { id: "Bothell", state_id: "Washington" },
  { id: "Boulder", state_id: "Colorado" },
  { id: "Boulder City", state_id: "Nevada" },
  { id: "Boulder Hill", state_id: "Illinois" },
  { id: "Bound Brook", state_id: "New Jersey" },
  { id: "Bountiful", state_id: "Utah" },
  { id: "Bourbonnais", state_id: "Illinois" },
  { id: "Bourne", state_id: "Massachusetts" },
  { id: "Bow", state_id: "New Hampshire" },
  { id: "Bowie", state_id: "Maryland" },
  { id: "Bowleys Quarters", state_id: "Maryland" },
  { id: "Bowling Green", state_id: "Kentucky" },
  { id: "Bowling Green", state_id: "Ohio" },
  { id: "Boxford", state_id: "Massachusetts" },
  { id: "Boyes Hot Springs", state_id: "California" },
  { id: "Boynton Beach", state_id: "Florida" },
  { id: "Bozeman", state_id: "Montana" },
  { id: "Bradenton", state_id: "Florida" },
  { id: "Bradford", state_id: "Pennsylvania" },
  { id: "Bradley", state_id: "Illinois" },
  { id: "Brainerd", state_id: "Minnesota" },
  { id: "Braintree", state_id: "Massachusetts" },
  { id: "Braintree", state_id: "Massachusetts" },
  { id: "Brandon", state_id: "Mississippi" },
  { id: "Brandon", state_id: "Florida" },
  { id: "Branford", state_id: "Connecticut" },
  { id: "Branson", state_id: "Missouri" },
  { id: "Brattleboro", state_id: "Vermont" },
  { id: "Brattleboro", state_id: "Vermont" },
  { id: "Brawley", state_id: "California" },
  { id: "Brazil", state_id: "Indiana" },
  { id: "Brea", state_id: "California" },
  { id: "Breaux Bridge", state_id: "Louisiana" },
  { id: "Brecksville", state_id: "Ohio" },
  { id: "Bremerton", state_id: "Washington" },
  { id: "Brenham", state_id: "Texas" },
  { id: "Brent", state_id: "Florida" },
  { id: "Brentwood", state_id: "California" },
  { id: "Brentwood", state_id: "New York" },
  { id: "Brentwood", state_id: "Missouri" },
  { id: "Brentwood", state_id: "Pennsylvania" },
  { id: "Brentwood", state_id: "Tennessee" },
  { id: "Brevard", state_id: "North Carolina" },
  { id: "Brewer", state_id: "Maine" },
  { id: "Brewster", state_id: "Massachusetts" },
  { id: "Briarcliff Manor", state_id: "New York" },
  { id: "Bridge City", state_id: "Louisiana" },
  { id: "Bridge City", state_id: "Texas" },
  { id: "Bridgeport", state_id: "West Virginia" },
  { id: "Bridgeport", state_id: "Connecticut" },
  { id: "Bridgeport", state_id: "Connecticut" },
  { id: "Bridgeport", state_id: "Michigan" },
  { id: "Gwinn", state_id: "Michigan" },
  { id: "Bridgeton", state_id: "Missouri" },
  { id: "Bridgeton", state_id: "New Jersey" },
  { id: "Bridgetown North", state_id: "Ohio" },
  { id: "Bridgeview", state_id: "Illinois" },
  { id: "Bridgewater", state_id: "Massachusetts" },
  { id: "Bridgewater", state_id: "Massachusetts" },
  { id: "Brier", state_id: "Washington" },
  { id: "Brigantine", state_id: "New Jersey" },
  { id: "Brigham City", state_id: "Utah" },
  { id: "Brighton", state_id: "Michigan" },
  { id: "Brighton", state_id: "New York" },
  { id: "Brighton", state_id: "New York" },
  { id: "Brighton", state_id: "Colorado" },
  { id: "Bristol", state_id: "Connecticut" },
  { id: "Bristol", state_id: "Connecticut" },
  { id: "Bristol", state_id: "Rhode Island" },
  { id: "Bristol", state_id: "Tennessee" },
  { id: "Bristol", state_id: "Rhode Island" },
  { id: "Bristol", state_id: "Virginia" },
  { id: "Bristol", state_id: "Pennsylvania" },
  { id: "Broadview", state_id: "Illinois" },
  { id: "Broadview Heights", state_id: "Ohio" },
  { id: "Broadview Park", state_id: "Florida" },
  { id: "Brockport", state_id: "New York" },
  { id: "Brockton", state_id: "Massachusetts" },
  { id: "Broken Arrow", state_id: "Oklahoma" },
  { id: "Bronxville", state_id: "New York" },
  { id: "Brook Park", state_id: "Ohio" },
  { id: "Brookfield", state_id: "Connecticut" },
  { id: "Brookfield", state_id: "Illinois" },
  { id: "Brookfield", state_id: "Wisconsin" },
  { id: "Richland Center", state_id: "Wisconsin" },
  { id: "Brookfield", state_id: "Wisconsin" },
  { id: "Brookhaven", state_id: "Pennsylvania" },
  { id: "Brookhaven", state_id: "New York" },
  { id: "Brookhaven", state_id: "Mississippi" },
  { id: "Brookings", state_id: "South Dakota" },
  { id: "Brookline", state_id: "Massachusetts" },
  { id: "Brookline", state_id: "Massachusetts" },
  { id: "Brooklyn", state_id: "Ohio" },
  { id: "Brooklyn", state_id: "Connecticut" },
  { id: "Brooklyn Center", state_id: "Minnesota" },
  { id: "Brooklyn Park", state_id: "Minnesota" },
  { id: "Brooklyn Park", state_id: "Maryland" },
  { id: "Brookside", state_id: "Delaware" },
  { id: "Brooksville", state_id: "Florida" },
  { id: "Broomall", state_id: "Pennsylvania" },
  { id: "Broomfield", state_id: "Colorado" },
  { id: "Brown Deer", state_id: "Wisconsin" },
  { id: "Brownfield", state_id: "Texas" },
  { id: "Browns Mills", state_id: "New Jersey" },
  { id: "Brownsburg", state_id: "Indiana" },
  { id: "Brownsville", state_id: "Florida" },
  { id: "Brownsville", state_id: "Texas" },
  { id: "Brownsville", state_id: "Tennessee" },
  { id: "Brownsville-Bawcomville", state_id: "Louisiana" },
  { id: "Brownwood", state_id: "Texas" },
  { id: "Brunswick", state_id: "Maine" },
  { id: "Brunswick", state_id: "Maine" },
  { id: "Brunswick", state_id: "Georgia" },
  { id: "Naylor", state_id: "Georgia" },
  { id: "Brunswick", state_id: "Ohio" },
  { id: "Brunswick", state_id: "New York" },
  { id: "Brushy Creek", state_id: "Texas" },
  { id: "Bryan", state_id: "Texas" },
  { id: "Bryan", state_id: "Ohio" },
  { id: "Bryant", state_id: "Arkansas" },
  { id: "Holiday Island", state_id: "Arkansas" },
  { id: "Bryn Mawr-Skyway", state_id: "Washington" },
  { id: "Buckeye", state_id: "Arizona" },
  { id: "Bucyrus", state_id: "Ohio" },
  { id: "Budd Lake", state_id: "New Jersey" },
  { id: "Buechel", state_id: "Kentucky" },
  { id: "Buena Park", state_id: "California" },
  { id: "Buena Vista", state_id: "Michigan" },
  { id: "Buena Vista", state_id: "Virginia" },
  { id: "Buffalo", state_id: "Minnesota" },
  { id: "Buffalo", state_id: "New York" },
  { id: "Buffalo Grove", state_id: "Illinois" },
  { id: "Buford", state_id: "Georgia" },
  { id: "Bull Run", state_id: "Virginia" },
  { id: "Bullhead City", state_id: "Arizona" },
  { id: "Burbank", state_id: "California" },
  { id: "Burbank", state_id: "Illinois" },
  { id: "Burien", state_id: "Washington" },
  { id: "Burkburnett", state_id: "Texas" },
  { id: "Burke", state_id: "Virginia" },
  { id: "Burleson", state_id: "Texas" },
  { id: "Burley", state_id: "Idaho" },
  { id: "Burlingame", state_id: "California" },
  { id: "Burlington", state_id: "Connecticut" },
  { id: "Burlington", state_id: "Kentucky" },
  { id: "Burlington", state_id: "Iowa" },
  { id: "Burlington", state_id: "North Carolina" },
  { id: "Burlington", state_id: "Massachusetts" },
  { id: "Burlington", state_id: "Massachusetts" },
  { id: "Burlington", state_id: "New Jersey" },
  { id: "Burlington", state_id: "Vermont" },
  { id: "Burlington", state_id: "Washington" },
  { id: "Burlington", state_id: "Wisconsin" },
  { id: "Burlington", state_id: "Wisconsin" },
  { id: "Burnsville", state_id: "Minnesota" },
  { id: "Burr Ridge", state_id: "Illinois" },
  { id: "Burrillville", state_id: "Rhode Island" },
  { id: "Burton", state_id: "South Carolina" },
  { id: "Burton", state_id: "Michigan" },
  { id: "Burtonsville", state_id: "Maryland" },
  { id: "Busti", state_id: "New York" },
  { id: "Butler", state_id: "New Jersey" },
  { id: "Butler", state_id: "Pennsylvania" },
  { id: "Butte-Silver Bow", state_id: "Montana" },
  { id: "Buxton", state_id: "Maine" },
  { id: "Byram", state_id: "Mississippi" },

  { id: "Cabot", state_id: "Arkansas" },
  { id: "Cadillac", state_id: "Michigan" },
  { id: "Cahokia", state_id: "Illinois" },
  { id: "Cairo", state_id: "Georgia" },
  { id: "Cairo", state_id: "New York" },
  { id: "Calabasas", state_id: "California" },
  { id: "Caldwell", state_id: "New Jersey" },
  { id: "Caldwell", state_id: "Idaho" },
  { id: "Caledonia", state_id: "Wisconsin" },
  { id: "Calexico", state_id: "California" },
  { id: "Calhoun", state_id: "Georgia" },
  { id: "California", state_id: "Maryland" },
  { id: "California City", state_id: "California" },
  { id: "Calimesa", state_id: "California" },
  { id: "Calipatria", state_id: "California" },
  { id: "Callaway", state_id: "Florida" },
  { id: "Calumet City", state_id: "Illinois" },
  { id: "Calumet Park", state_id: "Illinois" },
  { id: "Calverton", state_id: "Maryland" },
  { id: "Camano", state_id: "Washington" },
  { id: "Camarillo", state_id: "California" },
  { id: "Camas", state_id: "Washington" },
  { id: "Cambria", state_id: "California" },
  { id: "Cambridge", state_id: "Maryland" },
  { id: "Cambridge", state_id: "Massachusetts" },
  { id: "Cambridge", state_id: "Ohio" },
  { id: "Camden", state_id: "South Carolina" },
  { id: "Camden", state_id: "New Jersey" },
  { id: "Camden", state_id: "Arkansas" },
  { id: "Cameron", state_id: "Missouri" },
  { id: "Cameron Park", state_id: "California" },
  { id: "Camillus", state_id: "New York" },
  { id: "Camp Hill", state_id: "Pennsylvania" },
  { id: "Camp Pendleton North", state_id: "California" },
  { id: "Camp Pendleton South", state_id: "California" },
  { id: "Camp Springs", state_id: "Maryland" },
  { id: "Camp Verde", state_id: "Arizona" },
  { id: "Campbell", state_id: "California" },
  { id: "Campbell", state_id: "Ohio" },
  { id: "Campbellsville", state_id: "Kentucky" },
  { id: "Canandaigua", state_id: "New York" },
  { id: "Canandaigua", state_id: "New York" },
  { id: "Canby", state_id: "Oregon" },
  { id: "Candler-McAfee", state_id: "Georgia" },
  { id: "Canfield", state_id: "Ohio" },
  { id: "Canon City", state_id: "Colorado" },
  { id: "Canonsburg", state_id: "Pennsylvania" },
  { id: "Canton", state_id: "Ohio" },
  { id: "Canton", state_id: "Georgia" },
  { id: "Canton", state_id: "Illinois" },
  { id: "Canton", state_id: "Connecticut" },
  { id: "Canton", state_id: "New York" },
  { id: "Canton", state_id: "Mississippi" },
  { id: "Canton", state_id: "Massachusetts" },
  { id: "Canton", state_id: "Michigan" },
  { id: "Canyon", state_id: "Texas" },
  { id: "Canyon Lake", state_id: "Texas" },
  { id: "Canyon Lake", state_id: "California" },
  { id: "Canyon Rim", state_id: "Utah" },
  { id: "Cape Canaveral", state_id: "Florida" },
  { id: "Cape Coral", state_id: "Florida" },
  { id: "Cape Elizabeth", state_id: "Maine" },
  { id: "Cape Girardeau", state_id: "Missouri" },
  { id: "Cape St. Claire", state_id: "Maryland" },
  { id: "Capitola", state_id: "California" },
  { id: "Carbondale", state_id: "Illinois" },
  { id: "Carbondale", state_id: "Pennsylvania" },
  { id: "Carencro", state_id: "Louisiana" },
  { id: "Caribou", state_id: "Maine" },
  { id: "Carlisle", state_id: "Pennsylvania" },
  { id: "Carlsbad", state_id: "New Mexico" },
  { id: "Carlsbad", state_id: "California" },
  { id: "Carmel", state_id: "Indiana" },
  { id: "Carmel", state_id: "New York" },
  { id: "Carmichael", state_id: "California" },
  { id: "Carnegie", state_id: "Pennsylvania" },
  { id: "Carney", state_id: "Maryland" },
  { id: "Carneys Point", state_id: "New Jersey" },
  { id: "Carnot-Moon", state_id: "Pennsylvania" },
  { id: "Carol City", state_id: "Florida" },
  { id: "Carol Stream", state_id: "Illinois" },
  { id: "Carpentersville", state_id: "Illinois" },
  { id: "Carpinteria", state_id: "California" },
  { id: "Carrboro", state_id: "North Carolina" },
  { id: "Carroll", state_id: "Iowa" },
  { id: "Carrollton", state_id: "Michigan" },
  { id: "Carrollton", state_id: "Georgia" },
  { id: "Carrollton", state_id: "Texas" },
  { id: "Carson", state_id: "California" },
  { id: "Carson City", state_id: "Nevada" },
  { id: "Carteret", state_id: "New Jersey" },
  { id: "Cartersville", state_id: "Georgia" },
  { id: "Carthage", state_id: "Missouri" },
  { id: "Carthage", state_id: "Texas" },
  { id: "Caruthersville", state_id: "Missouri" },
  { id: "Carver", state_id: "Massachusetts" },
  { id: "Cary", state_id: "Illinois" },
  { id: "Cary", state_id: "North Carolina" },
  { id: "Casa de Oro-Mount Helix", state_id: "California" },
  { id: "Casa Grande", state_id: "Arizona" },
  { id: "Casas Adobes", state_id: "Arizona" },
  { id: "Cascade-Fairwood", state_id: "Washington" },
  { id: "Casper", state_id: "Wyoming" },
  { id: "Casselberry", state_id: "Florida" },
  { id: "Castle Rock", state_id: "Colorado" },
  { id: "Castle Shannon", state_id: "Pennsylvania" },
  { id: "Castlewood", state_id: "Colorado" },
  { id: "Castro Valley", state_id: "California" },
  { id: "Castroville", state_id: "California" },
  { id: "Catalina", state_id: "Arizona" },
  { id: "Catalina Foothills", state_id: "Arizona" },
  { id: "Catasauqua", state_id: "Pennsylvania" },
  { id: "Cathedral City", state_id: "California" },
  { id: "Catonsville", state_id: "Maryland" },
  { id: "Catskill", state_id: "New York" },
  { id: "Cave Spring", state_id: "Virginia" },
  { id: "Cayce", state_id: "South Carolina" },
  { id: "Cazenovia", state_id: "New York" },
  { id: "Cedar City", state_id: "Utah" },
  { id: "Cedar Falls", state_id: "Iowa" },
  { id: "Cedar Grove", state_id: "New Jersey" },
  { id: "Cedar Hill", state_id: "Texas" },
  { id: "Cedar Hills", state_id: "Oregon" },
  { id: "Cedar Lake", state_id: "Indiana" },
  { id: "Cedar Mill", state_id: "Oregon" },
  { id: "Cedar Park", state_id: "Texas" },
  { id: "Cedar Rapids", state_id: "Iowa" },
  { id: "Cedarburg", state_id: "Wisconsin" },
  { id: "Cedarhurst", state_id: "New York" },
  { id: "Cedartown", state_id: "Georgia" },
  { id: "Celina", state_id: "Ohio" },
  { id: "Center Line", state_id: "Michigan" },
  { id: "Center Moriches", state_id: "New York" },
  { id: "Center Point", state_id: "Alabama" },
  { id: "Centereach", state_id: "New York" },
  { id: "Centerville", state_id: "Ohio" },
  { id: "Centerville", state_id: "Utah" },
  { id: "Central Falls", state_id: "Rhode Island" },
  { id: "Central Islip", state_id: "New York" },
  { id: "Central Manchester", state_id: "Connecticut" },
  { id: "Central Point", state_id: "Oregon" },
  { id: "Centralia", state_id: "Washington" },
  { id: "Centralia", state_id: "Illinois" },
  { id: "Centreville", state_id: "Virginia" },
  { id: "Century Village", state_id: "Florida" },
  { id: "Ceres", state_id: "California" },
  { id: "Cerritos", state_id: "California" },
  { id: "Chalco", state_id: "Nebraska" },
  { id: "Chalmette", state_id: "Louisiana" },
  { id: "Chambersburg", state_id: "Pennsylvania" },
  { id: "Chamblee", state_id: "Georgia" },
  { id: "Champaign", state_id: "Illinois" },
  { id: "Champlin", state_id: "Minnesota" },
  { id: "Chandler", state_id: "Arizona" },
  { id: "Chanhassen", state_id: "Minnesota" },
  { id: "Channahon", state_id: "Illinois" },
  { id: "Channelview", state_id: "Texas" },
  { id: "Chantilly", state_id: "Virginia" },
  { id: "Chanute", state_id: "Kansas" },
  { id: "Chaparral", state_id: "New Mexico" },
  { id: "Chapel Hill", state_id: "North Carolina" },
  { id: "Chappaqua", state_id: "New York" },
  { id: "Charles City", state_id: "Iowa" },
  { id: "Charleston", state_id: "Illinois" },
  { id: "Charleston", state_id: "West Virginia" },
  { id: "Charleston", state_id: "South Carolina" },
  { id: "Charlestown", state_id: "Rhode Island" },
  { id: "Charlotte", state_id: "North Carolina" },
  { id: "Charlotte", state_id: "Michigan" },
  { id: "Charlottesville", state_id: "Virginia" },
  { id: "Charlton", state_id: "Massachusetts" },
  { id: "Charter Oak", state_id: "California" },
  { id: "Chaska", state_id: "Minnesota" },
  { id: "Chatham", state_id: "Massachusetts" },
  { id: "Chatham", state_id: "New Jersey" },
  { id: "Chatham", state_id: "Illinois" },
  { id: "Chattanooga", state_id: "Tennessee" },
  { id: "Cheat Lake", state_id: "West Virginia" },
  { id: "Cheektowaga", state_id: "New York" },
  { id: "Cheektowaga", state_id: "New York" },
  { id: "Chehalis", state_id: "Washington" },
  { id: "Chelmsford", state_id: "Massachusetts" },
  { id: "Chelsea", state_id: "Massachusetts" },
  { id: "Chenango", state_id: "New York" },
  { id: "Cheney", state_id: "Washington" },
  { id: "Cherry Hill Mall", state_id: "New Jersey" },
  { id: "Cherryland", state_id: "California" },
  { id: "Chesapeake", state_id: "Virginia" },
  { id: "Chesapeake Ranch Estates-Drum Point", state_id: "Maryland" },
  { id: "Cheshire", state_id: "Connecticut" },
  { id: "Chester", state_id: "New York" },
  { id: "Chester", state_id: "Virginia" },
  { id: "Chester", state_id: "South Carolina" },
  { id: "Chester", state_id: "Pennsylvania" },
  { id: "Chesterfield", state_id: "Missouri" },
  { id: "Chesterton", state_id: "Indiana" },
  { id: "Chestnut Ridge", state_id: "New York" },
  { id: "Cheval", state_id: "Florida" },
  { id: "Cheverly", state_id: "Maryland" },
  { id: "Cheviot", state_id: "Ohio" },
  { id: "Chevy Chase", state_id: "Maryland" },
  { id: "Cheyenne", state_id: "Wyoming" },
  { id: "Chicago", state_id: "Illinois" },
  { id: "Chicago Heights", state_id: "Illinois" },
  { id: "Chicago Ridge", state_id: "Illinois" },
  { id: "Chickasaw", state_id: "Alabama" },
  { id: "Chickasha", state_id: "Oklahoma" },
  { id: "Chico", state_id: "California" },
  { id: "Chicopee", state_id: "Massachusetts" },
  { id: "Childress", state_id: "Texas" },
  { id: "Adkins", state_id: "Texas" },
  { id: "Chili", state_id: "New York" },
  { id: "Chillicothe", state_id: "Missouri" },
  { id: "Chillicothe", state_id: "Ohio" },
  { id: "Chillum", state_id: "Maryland" },
  { id: "Chino", state_id: "California" },
  { id: "Chino Hills", state_id: "California" },
  { id: "Chino Valley", state_id: "Arizona" },
  { id: "Chippewa Falls", state_id: "Wisconsin" },
  { id: "Choctaw", state_id: "Oklahoma" },
  { id: "Chowchilla", state_id: "California" },
  { id: "Christiansburg", state_id: "Virginia" },
  { id: "Chubbuck", state_id: "Idaho" },
  { id: "Chula Vista", state_id: "California" },
  { id: "Cicero", state_id: "Illinois" },
  { id: "Colchestr", state_id: "Illinois" },
  { id: "Cicero", state_id: "New York" },
  { id: "Cimarron Hills", state_id: "Colorado" },
  { id: "Cincinnati", state_id: "Ohio" },
  { id: "Cinco Ranch", state_id: "Texas" },
  { id: "Circleville", state_id: "Ohio" },
  { id: "Citrus", state_id: "California" },
  { id: "Citrus Heights", state_id: "California" },
  { id: "Citrus Park", state_id: "Florida" },
  { id: "Citrus Ridge", state_id: "Florida" },
  { id: "City of The Dalles", state_id: "Oregon" },
  { id: "Claiborne", state_id: "Louisiana" },
  { id: "Clairton", state_id: "Pennsylvania" },
  { id: "Clanton", state_id: "Alabama" },
  { id: "Claremont", state_id: "California" },
  { id: "Claremont", state_id: "New Hampshire" },
  { id: "Claremore", state_id: "Oklahoma" },
  { id: "Clarence", state_id: "New York" },
  { id: "Clarendon Hills", state_id: "Illinois" },
  { id: "Clarion", state_id: "Pennsylvania" },
  { id: "Clark", state_id: "New Jersey" },
  { id: "Clarksburg", state_id: "West Virginia" },
  { id: "Clarksdale", state_id: "Mississippi" },
  { id: "Clarkson", state_id: "New York" },
  { id: "Clarkston", state_id: "Georgia" },
  { id: "Clarkston", state_id: "Washington" },
  { id: "Clarkston Heights-Vineland", state_id: "Washington" },
  { id: "Clarkstown", state_id: "New York" },
  { id: "Clarksville", state_id: "Indiana" },
  { id: "Clarksville", state_id: "Arkansas" },
  { id: "Clarksville", state_id: "Tennessee" },
  { id: "Claverack", state_id: "New York" },
  { id: "Clawson", state_id: "Michigan" },
  { id: "Clay", state_id: "New York" },
  { id: "Claymont", state_id: "Delaware" },
  { id: "Clayton", state_id: "California" },
  { id: "Clayton", state_id: "Missouri" },
  { id: "Clayton", state_id: "New Jersey" },
  { id: "Clayton", state_id: "Ohio" },
  { id: "Clayton", state_id: "North Carolina" },
  { id: "Clear Lake", state_id: "Iowa" },
  { id: "Clearfield", state_id: "Pennsylvania" },
  { id: "Clearfield", state_id: "Utah" },
  { id: "Clearlake", state_id: "California" },
  { id: "Clearwater", state_id: "Florida" },
  { id: "Cleburne", state_id: "Texas" },
  { id: "Clemmons", state_id: "North Carolina" },
  { id: "Clemson", state_id: "South Carolina" },
  { id: "Clermont", state_id: "Florida" },
  { id: "Cleveland", state_id: "Mississippi" },
  { id: "Cleveland", state_id: "Tennessee" },
  { id: "Cleveland", state_id: "Texas" },
  { id: "Cleveland", state_id: "Ohio" },
  { id: "Cleveland Heights", state_id: "Ohio" },
  { id: "Clewiston", state_id: "Florida" },
  { id: "Cliffside Park", state_id: "New Jersey" },
  { id: "Clifton", state_id: "New Jersey" },
  { id: "Clifton", state_id: "Colorado" },
  { id: "Clifton Heights", state_id: "Pennsylvania" },
  { id: "Clifton Park", state_id: "New York" },
  { id: "Clinton", state_id: "Mississippi" },
  { id: "Clinton", state_id: "Missouri" },
  { id: "Clinton", state_id: "Iowa" },
  { id: "Clinton", state_id: "Maryland" },
  { id: "Clinton", state_id: "Michigan" },
  { id: "Clinton", state_id: "Massachusetts" },
  { id: "Clinton", state_id: "Massachusetts" },
  { id: "Clinton", state_id: "Connecticut" },
  { id: "Clinton", state_id: "Illinois" },
  { id: "Clinton", state_id: "Oklahoma" },
  { id: "Clinton", state_id: "North Carolina" },
  { id: "Clinton", state_id: "Tennessee" },
  { id: "Clinton", state_id: "South Carolina" },
  { id: "Clinton", state_id: "Utah" },
  { id: "Clive", state_id: "Iowa" },
  { id: "Cloquet", state_id: "Minnesota" },
  { id: "Closter", state_id: "New Jersey" },
  { id: "Cloverdale", state_id: "California" },
  { id: "Cloverleaf", state_id: "Texas" },
  { id: "Cloverly", state_id: "Maryland" },
  { id: "Clovis", state_id: "New Mexico" },
  { id: "Clovis", state_id: "California" },
  { id: "Clute", state_id: "Texas" },
  { id: "Clyde", state_id: "Ohio" },
  { id: "Coachella", state_id: "California" },
  { id: "Coalinga", state_id: "California" },
  { id: "Coatesville", state_id: "Pennsylvania" },
  { id: "Cobleskill", state_id: "New York" },
  { id: "Cochituate", state_id: "Massachusetts" },
  { id: "Cockeysville", state_id: "Maryland" },
  { id: "Cocoa", state_id: "Florida" },
  { id: "Cocoa Beach", state_id: "Florida" },
  { id: "Coconut Creek", state_id: "Florida" },
  { id: "Cody", state_id: "Wyoming" },
  { id: "Coeur d’Alene", state_id: "Idaho" },
  { id: "Coeymans", state_id: "New York" },
  { id: "Coffeyville", state_id: "Kansas" },
  { id: "Cohasset", state_id: "Massachusetts" },
  { id: "Cohoes", state_id: "New York" },
  { id: "Colchester", state_id: "Vermont" },
  { id: "Colchester", state_id: "Connecticut" },
  { id: "Coldwater", state_id: "Michigan" },
  { id: "Colesville", state_id: "Maryland" },
  { id: "College", state_id: "Alaska" },
  { id: "College Park", state_id: "Georgia" },
  { id: "College Park", state_id: "Maryland" },
  { id: "College Place", state_id: "Washington" },
  { id: "College Station", state_id: "Texas" },
  { id: "Collegedale", state_id: "Tennessee" },
  { id: "Collegeville", state_id: "Pennsylvania" },
  { id: "Colleyville", state_id: "Texas" },
  { id: "Collier Manor-Cresthaven", state_id: "Florida" },
  { id: "Collierville", state_id: "Tennessee" },
  { id: "Collingdale", state_id: "Pennsylvania" },
  { id: "Collingswood", state_id: "New Jersey" },
  { id: "Collins", state_id: "New York" },
  { id: "Collinsville", state_id: "Illinois" },
  { id: "Collinsville", state_id: "Virginia" },
  { id: "Colonia", state_id: "New Jersey" },
  { id: "Colonial Heights", state_id: "Virginia" },
  { id: "Colonial Heights", state_id: "Tennessee" },
  { id: "Colonial Park", state_id: "Pennsylvania" },
  { id: "Colonie", state_id: "New York" },
  { id: "Colonie", state_id: "New York" },
  { id: "Colorado Springs", state_id: "Colorado" },
  { id: "Colton", state_id: "California" },
  { id: "Columbia", state_id: "Illinois" },
  { id: "Columbia", state_id: "Missouri" },
  { id: "Columbia", state_id: "Mississippi" },
  { id: "Columbia", state_id: "Maryland" },
  { id: "Columbia", state_id: "Pennsylvania" },
  { id: "Columbia", state_id: "Tennessee" },
  { id: "Columbia", state_id: "South Carolina" },
  { id: "Columbia City", state_id: "Indiana" },
  { id: "Columbia Heights", state_id: "Minnesota" },
  { id: "Columbine", state_id: "Colorado" },
  { id: "Columbus", state_id: "Indiana" },
  { id: "Columbus", state_id: "Georgia" },
  { id: "Columbus", state_id: "Mississippi" },
  { id: "Columbus", state_id: "Nebraska" },
  { id: "Columbus", state_id: "Ohio" },
  { id: "Commack", state_id: "New York" },
  { id: "Commerce", state_id: "Texas" },
  { id: "Tuttle", state_id: "Texas" },
  { id: "Commerce", state_id: "California" },
  { id: "Commerce City", state_id: "Colorado" },
  { id: "Compton", state_id: "California" },
  { id: "Comstock Park", state_id: "Michigan" },
  { id: "Concord", state_id: "Massachusetts" },
  { id: "Concord", state_id: "Missouri" },
  { id: "Concord", state_id: "New Hampshire" },
  { id: "Concord", state_id: "New York" },
  { id: "Concord", state_id: "California" },
  { id: "Concord", state_id: "North Carolina" },
  { id: "Congers", state_id: "New York" },
  { id: "Conley", state_id: "Georgia" },
  { id: "Conneaut", state_id: "Ohio" },
  { id: "Connellsville", state_id: "Pennsylvania" },
  { id: "Connersville", state_id: "Indiana" },
  { id: "Conning Towers-Nautilus Park", state_id: "Connecticut" },
  { id: "Conover", state_id: "North Carolina" },
  { id: "Conroe", state_id: "Texas" },
  { id: "Conshohocken", state_id: "Pennsylvania" },
  { id: "Converse", state_id: "Texas" },
  { id: "Conway", state_id: "South Carolina" },
  { id: "Conway", state_id: "Florida" },
  { id: "Conway", state_id: "Arkansas" },
  { id: "Conway", state_id: "New Hampshire" },
  { id: "Conyers", state_id: "Georgia" },
  { id: "Cookeville", state_id: "Tennessee" },
  { id: "Coolidge", state_id: "Arizona" },
  { id: "Coon Rapids", state_id: "Minnesota" },
  { id: "Cooper City", state_id: "Florida" },
  { id: "Coos Bay", state_id: "Oregon" },
  { id: "Copiague", state_id: "New York" },
  { id: "Coppell", state_id: "Texas" },
  { id: "Copperas Cove", state_id: "Texas" },
  { id: "Coral Gables", state_id: "Florida" },
  { id: "Coral Hills", state_id: "Maryland" },
  { id: "Coral Springs", state_id: "Florida" },
  { id: "Coral Terrace", state_id: "Florida" },
  { id: "Coralville", state_id: "Iowa" },
  { id: "Coram", state_id: "New York" },
  { id: "Coraopolis", state_id: "Pennsylvania" },
  { id: "Corbin", state_id: "Kentucky" },
  { id: "Corcoran", state_id: "California" },
  { id: "Cordele", state_id: "Georgia" },
  { id: "Corinth", state_id: "Mississippi" },
  { id: "Corinth", state_id: "Texas" },
  { id: "Cornelius", state_id: "Oregon" },
  { id: "Cornelius", state_id: "North Carolina" },
  { id: "Corning", state_id: "New York" },
  { id: "Corning", state_id: "New York" },
  { id: "Corning", state_id: "California" },
  { id: "Cornwall", state_id: "New York" },
  { id: "Corona", state_id: "California" },
  { id: "Coronado", state_id: "California" },
  { id: "Corpus Christi", state_id: "Texas" },
  { id: "Corrales", state_id: "New Mexico" },
  { id: "Corry", state_id: "Pennsylvania" },
  { id: "Corsicana", state_id: "Texas" },
  { id: "Corte Madera", state_id: "California" },
  { id: "Cortez", state_id: "Colorado" },
  { id: "Cortland", state_id: "New York" },
  { id: "Cortland", state_id: "Ohio" },
  { id: "Cortlandt", state_id: "New York" },
  { id: "Cortlandville", state_id: "New York" },
  { id: "Corvallis", state_id: "Oregon" },
  { id: "Coshocton", state_id: "Ohio" },
  { id: "Costa Mesa", state_id: "California" },
  { id: "Cotati", state_id: "California" },
  { id: "Coto de Caza", state_id: "California" },
  { id: "Cottage Grove", state_id: "Minnesota" },
  { id: "Cottage Grove", state_id: "Oregon" },
  { id: "Cottage Lake", state_id: "Washington" },
  { id: "Cottonwood", state_id: "Arizona" },
  { id: "Cottonwood Heights", state_id: "Utah" },
  { id: "Cottonwood West", state_id: "Utah" },
  { id: "Cottonwood-Verde Village", state_id: "Arizona" },
  { id: "Council Bluffs", state_id: "Iowa" },
  { id: "Country Club", state_id: "California" },
  { id: "Country Club", state_id: "Florida" },
  { id: "Country Club Estates", state_id: "Georgia" },
  { id: "Country Club Hills", state_id: "Illinois" },
  { id: "Country Walk", state_id: "Florida" },
  { id: "Covedale", state_id: "Ohio" },
  { id: "Coventry", state_id: "Rhode Island" },
  { id: "Coventry", state_id: "Connecticut" },
  { id: "Covina", state_id: "California" },
  { id: "Covington", state_id: "Georgia" },
  { id: "Covington", state_id: "Kentucky" },
  { id: "Covington", state_id: "Louisiana" },
  { id: "Covington", state_id: "Washington" },
  { id: "Covington", state_id: "Virginia" },
  { id: "Covington", state_id: "Tennessee" },
  { id: "Coweta", state_id: "Oklahoma" },
  { id: "Coxsackie", state_id: "New York" },
  { id: "Crafton", state_id: "Pennsylvania" },
  { id: "Craig", state_id: "Colorado" },
  { id: "Cranford", state_id: "New Jersey" },
  { id: "Cranston", state_id: "Rhode Island" },
  { id: "Crawford", state_id: "New York" },
  { id: "Crawfordsville", state_id: "Indiana" },
  { id: "Cresskill", state_id: "New Jersey" },
  { id: "Crest Hill", state_id: "Illinois" },
  { id: "Crestline", state_id: "California" },
  { id: "Creston", state_id: "Iowa" },
  { id: "Crestview", state_id: "Florida" },
  { id: "Crestwood", state_id: "Illinois" },
  { id: "Crestwood", state_id: "Missouri" },
  { id: "Crestwood Village", state_id: "New Jersey" },
  { id: "Crete", state_id: "Nebraska" },
  { id: "Crete", state_id: "Illinois" },
  { id: "Creve Coeur", state_id: "Missouri" },
  { id: "Crockett", state_id: "Texas" },
  { id: "Crofton", state_id: "Maryland" },
  { id: "Cromwell", state_id: "Connecticut" },
  { id: "Crookston", state_id: "Minnesota" },
  { id: "Cross Lanes", state_id: "West Virginia" },
  { id: "Crossett", state_id: "Arkansas" },
  { id: "Crossville", state_id: "Tennessee" },
  { id: "Croton-on-Hudson", state_id: "New York" },
  { id: "Crowley", state_id: "Texas" },
  { id: "Crowley", state_id: "Louisiana" },
  { id: "Crown Point", state_id: "Indiana" },
  { id: "Croydon", state_id: "Pennsylvania" },
  { id: "Crystal", state_id: "Minnesota" },
  { id: "Crystal City", state_id: "Texas" },
  { id: "Crystal Lake", state_id: "Illinois" },
  { id: "Cudahy", state_id: "California" },
  { id: "Cudahy", state_id: "Wisconsin" },
  { id: "Columbus", state_id: "Wisconsin" },
  { id: "Cuero", state_id: "Texas" },
  { id: "Cullman", state_id: "Alabama" },
  { id: "Culpeper", state_id: "Virginia" },
  { id: "Culver City", state_id: "California" },
  { id: "Cumberland", state_id: "Maine" },
  { id: "Cumberland", state_id: "Maryland" },
  { id: "Cumberland", state_id: "Rhode Island" },
  { id: "Cumberland Hill", state_id: "Rhode Island" },
  { id: "Cupertino", state_id: "California" },
  { id: "Cushing", state_id: "Oklahoma" },
  { id: "Cutler", state_id: "Florida" },
  { id: "Cutler Ridge", state_id: "Florida" },
  { id: "Cutlerville", state_id: "Michigan" },
  { id: "Cuyahoga Falls", state_id: "Ohio" },
  { id: "Cynthiana", state_id: "Kentucky" },
  { id: "Cypress", state_id: "California" },
  { id: "Cypress Gardens", state_id: "Florida" },
  { id: "Cypress Lake", state_id: "Florida" },

  { id: "D’Iberville", state_id: "Mississippi" },
  { id: "Dade City", state_id: "Florida" },
  { id: "Dale City", state_id: "Virginia" },
  { id: "Dalhart", state_id: "Texas" },
  { id: "Dallas", state_id: "Texas" },
  { id: "Dallas", state_id: "Oregon" },
  { id: "Dalton", state_id: "Massachusetts" },
  { id: "Dalton", state_id: "Georgia" },
  { id: "Daly City", state_id: "California" },
  { id: "Damascus", state_id: "Maryland" },
  { id: "Dana Point", state_id: "California" },
  { id: "Danbury", state_id: "Connecticut" },
  { id: "Danbury", state_id: "Connecticut" },
  { id: "Dania Beach", state_id: "Florida" },
  { id: "Danvers", state_id: "Massachusetts" },
  { id: "Danvers", state_id: "Massachusetts" },
  { id: "Danville", state_id: "California" },
  { id: "Danville", state_id: "Kentucky" },
  { id: "Danville", state_id: "Indiana" },
  { id: "Danville", state_id: "Illinois" },
  { id: "Danville", state_id: "Virginia" },
  { id: "Daphne", state_id: "Alabama" },
  { id: "Darby", state_id: "Pennsylvania" },
  { id: "Darby Township", state_id: "Pennsylvania" },
  { id: "Darien", state_id: "Connecticut" },
  { id: "Darien", state_id: "Connecticut" },
  { id: "Darien", state_id: "Illinois" },
  { id: "Darlington", state_id: "South Carolina" },
  { id: "Darnestown", state_id: "Maryland" },
  { id: "Dartmouth", state_id: "Massachusetts" },
  { id: "Davenport", state_id: "Iowa" },
  { id: "Davidson", state_id: "North Carolina" },
  { id: "Davie", state_id: "Florida" },
  { id: "Davis", state_id: "California" },
  { id: "Dayton", state_id: "Ohio" },
  { id: "Dayton", state_id: "New Jersey" },
  { id: "Dayton", state_id: "Tennessee" },
  { id: "Daytona Beach", state_id: "Florida" },
  { id: "De Bary", state_id: "Florida" },
  { id: "De Land", state_id: "Florida" },
  { id: "De Pere", state_id: "Wisconsin" },
  { id: "De Ridder", state_id: "Louisiana" },
  { id: "De Soto", state_id: "Missouri" },
  { id: "De Witt", state_id: "New York" },
  { id: "Dearborn", state_id: "Michigan" },
  { id: "Dearborn Heights", state_id: "Michigan" },
  { id: "Decatur", state_id: "Indiana" },
  { id: "Decatur", state_id: "Illinois" },
  { id: "Decatur", state_id: "Georgia" },
  { id: "Decatur", state_id: "Alabama" },
  { id: "Decorah", state_id: "Iowa" },
  { id: "Dedham", state_id: "Massachusetts" },
  { id: "Dedham", state_id: "Massachusetts" },
  { id: "Deer Park", state_id: "New York" },
  { id: "Deer Park", state_id: "Texas" },
  { id: "Deerfield", state_id: "Illinois" },
  { id: "Deerfield Beach", state_id: "Florida" },
  { id: "Deerpark", state_id: "New York" },
  { id: "Defiance", state_id: "Ohio" },
  { id: "DeForest", state_id: "Wisconsin" },
  { id: "DeKalb", state_id: "Illinois" },
  { id: "Del Aire", state_id: "California" },
  { id: "Del City", state_id: "Oklahoma" },
  { id: "Del Rio", state_id: "Texas" },
  { id: "Delafield", state_id: "Wisconsin" },
  { id: "Delafield", state_id: "Wisconsin" },
  { id: "Delano", state_id: "California" },
  { id: "Delavan", state_id: "Wisconsin" },
  { id: "Delaware", state_id: "Ohio" },
  { id: "Delhi", state_id: "California" },
  { id: "Delmar", state_id: "New York" },
  { id: "Delphos", state_id: "Ohio" },
  { id: "Delray Beach", state_id: "Florida" },
  { id: "Delta", state_id: "Colorado" },
  { id: "Deltona", state_id: "Florida" },
  { id: "Deming", state_id: "New Mexico" },
  { id: "Demopolis", state_id: "Alabama" },
  { id: "Denham Springs", state_id: "Louisiana" },
  { id: "Denison", state_id: "Iowa" },
  { id: "Denison", state_id: "Texas" },
  { id: "Dennis", state_id: "Massachusetts" },
  { id: "Dent", state_id: "Ohio" },
  { id: "Denton", state_id: "Texas" },
  { id: "Dentsville", state_id: "South Carolina" },
  { id: "Denver", state_id: "Colorado" },
  { id: "Depew", state_id: "New York" },
  { id: "Derby", state_id: "Colorado" },
  { id: "Derby", state_id: "Connecticut" },
  { id: "Derby", state_id: "Connecticut" },
  { id: "Derby", state_id: "Kansas" },
  { id: "Derry", state_id: "New Hampshire" },
  { id: "Derry", state_id: "New Hampshire" },
  { id: "Des Moines", state_id: "Iowa" },
  { id: "Des Moines", state_id: "Washington" },
  { id: "Des Peres", state_id: "Missouri" },
  { id: "Des Plaines", state_id: "Illinois" },
  { id: "Desert Hot Springs", state_id: "California" },
  { id: "Desoto", state_id: "Texas" },
  { id: "Destin", state_id: "Florida" },
  { id: "Destrehan", state_id: "Louisiana" },
  { id: "Detroit", state_id: "Michigan" },
  { id: "Detroit Lakes", state_id: "Minnesota" },
  { id: "Devils Lake", state_id: "North Dakota" },
  { id: "Kathryn", state_id: "North Dakota" },
  { id: "Dewey-Humboldt", state_id: "Arizona" },
  { id: "Dexter", state_id: "Missouri" },
  { id: "Diamond Bar", state_id: "California" },
  { id: "Dickinson", state_id: "North Dakota" },
  { id: "Dickinson", state_id: "Texas" },
  { id: "Dickson", state_id: "Tennessee" },
  { id: "Dickson City", state_id: "Pennsylvania" },
  { id: "Dighton", state_id: "Massachusetts" },
  { id: "Dillon", state_id: "South Carolina" },
  { id: "Dinuba", state_id: "California" },
  { id: "Discovery Bay", state_id: "California" },
  { id: "Dishman", state_id: "Washington" },
  { id: "Dix Hills", state_id: "New York" },
  { id: "Dixon", state_id: "California" },
  { id: "Dixon", state_id: "Illinois" },
  { id: "Dobbs Ferry", state_id: "New York" },
  { id: "Dock Junction", state_id: "Georgia" },
  { id: "Peachtree Corners", state_id: "Georgia" },
  { id: "Doctor Phillips", state_id: "Florida" },
  { id: "Dodge City", state_id: "Kansas" },
  { id: "Dolton", state_id: "Illinois" },
  { id: "Donaldsonville", state_id: "Louisiana" },
  { id: "Donna", state_id: "Texas" },
  { id: "Doral", state_id: "Florida" },
  { id: "Doraville", state_id: "Georgia" },
  { id: "Dormont", state_id: "Pennsylvania" },
  { id: "Dothan", state_id: "Alabama" },
  { id: "Douglas", state_id: "Arizona" },
  { id: "Douglas", state_id: "Georgia" },
  { id: "Douglas", state_id: "Massachusetts" },
  { id: "Douglasville", state_id: "Georgia" },
  { id: "Dover", state_id: "Delaware" },
  { id: "Dover", state_id: "New Hampshire" },
  { id: "Dover", state_id: "New York" },
  { id: "Dover", state_id: "New Jersey" },
  { id: "Dover", state_id: "Ohio" },
  { id: "Dowagiac", state_id: "Michigan" },
  { id: "Downers Grove", state_id: "Illinois" },
  { id: "Downey", state_id: "California" },
  { id: "Downingtown", state_id: "Pennsylvania" },
  { id: "Doylestown", state_id: "Pennsylvania" },
  { id: "Dracut", state_id: "Massachusetts" },
  { id: "Draper", state_id: "Utah" },
  { id: "Drexel Heights", state_id: "Arizona" },
  { id: "Drexel Hill", state_id: "Pennsylvania" },
  { id: "Druid Hills", state_id: "Georgia" },
  { id: "Dry Run", state_id: "Ohio" },
  { id: "Dryden", state_id: "New York" },
  { id: "Du Quoin", state_id: "Illinois" },
  { id: "Duarte", state_id: "California" },
  { id: "Dublin", state_id: "California" },
  { id: "Dublin", state_id: "Georgia" },
  { id: "Dublin", state_id: "Ohio" },
  { id: "DuBois", state_id: "Pennsylvania" },
  { id: "Dubuque", state_id: "Iowa" },
  { id: "Dudley", state_id: "Massachusetts" },
  { id: "Duluth", state_id: "Minnesota" },
  { id: "Duluth", state_id: "Georgia" },
  { id: "Dumas", state_id: "Texas" },
  { id: "Dumbarton", state_id: "Virginia" },
  { id: "Dumont", state_id: "New Jersey" },
  { id: "Dunbar", state_id: "West Virginia" },
  { id: "Duncan", state_id: "Oklahoma" },
  { id: "Duncanville", state_id: "Texas" },
  { id: "Dundalk", state_id: "Maryland" },
  { id: "Dunedin", state_id: "Florida" },
  { id: "Dunellen", state_id: "New Jersey" },
  { id: "Dunkirk", state_id: "New York" },
  { id: "Dunmore", state_id: "Pennsylvania" },
  { id: "Dunn", state_id: "North Carolina" },
  { id: "Dunn Loring", state_id: "Virginia" },
  { id: "Dunwoody", state_id: "Georgia" },
  { id: "Duquesne", state_id: "Pennsylvania" },
  { id: "Durango", state_id: "Colorado" },
  { id: "Durant", state_id: "Oklahoma" },
  { id: "Durham", state_id: "Connecticut" },
  { id: "Durham", state_id: "North Carolina" },
  { id: "Durham", state_id: "New Hampshire" },
  { id: "Durham", state_id: "New Hampshire" },
  { id: "Duxbury", state_id: "Massachusetts" },
  { id: "Dyer", state_id: "Indiana" },
  { id: "Dyersburg", state_id: "Tennessee" },
  { id: "Eagan", state_id: "Minnesota" },
  { id: "Eagle", state_id: "Idaho" },
  { id: "Eagle Mountain", state_id: "Texas" },
  { id: "Eagle Pass", state_id: "Texas" },
  { id: "Earlimart", state_id: "California" },
  { id: "Easley", state_id: "South Carolina" },
  { id: "East Alton", state_id: "Illinois" },
  { id: "East Aurora", state_id: "New York" },
  { id: "East Bethel", state_id: "Minnesota" },
  { id: "East Brainerd", state_id: "Tennessee" },
  { id: "East Bridgewater", state_id: "Massachusetts" },
  { id: "East Brunswick", state_id: "New Jersey" },
  { id: "East Chicago", state_id: "Indiana" },
  { id: "East Cleveland", state_id: "Ohio" },
  { id: "East Compton", state_id: "California" },
  { id: "East Falmouth", state_id: "Massachusetts" },
  { id: "East Fishkill", state_id: "New York" },
  { id: "East Foothills", state_id: "California" },
  { id: "East Glenville", state_id: "New York" },
  { id: "East Grand Forks", state_id: "Minnesota" },
  { id: "East Grand Rapids", state_id: "Michigan" },
  { id: "East Greenbush", state_id: "New York" },
  { id: "East Greenwich", state_id: "Rhode Island" },
  { id: "East Haddam", state_id: "Connecticut" },
  { id: "East Hampton", state_id: "Connecticut" },
  { id: "East Hampton", state_id: "New York" },
  { id: "East Hartford", state_id: "Connecticut" },
  { id: "East Hartford", state_id: "Connecticut" },
  { id: "East Haven", state_id: "Connecticut" },
  { id: "East Haven", state_id: "Connecticut" },
  { id: "East Hemet", state_id: "California" },
  { id: "East Highland Park", state_id: "Virginia" },
  { id: "East Hill-Meridian", state_id: "Washington" },
  { id: "East Hills", state_id: "New York" },
  { id: "East Islip", state_id: "New York" },
  { id: "East La Mirada", state_id: "California" },
  { id: "East Lake", state_id: "Florida" },
  { id: "East Lansing", state_id: "Michigan" },
  { id: "East Liverpool", state_id: "Ohio" },
  { id: "East Longmeadow", state_id: "Massachusetts" },
  { id: "East Los Angeles", state_id: "California" },
  { id: "East Lyme", state_id: "Connecticut" },
  { id: "East Massapequa", state_id: "New York" },
  { id: "East Meadow", state_id: "New York" },
  { id: "East Millcreek", state_id: "Utah" },
  { id: "East Moline", state_id: "Illinois" },
  { id: "East Norriton", state_id: "Pennsylvania" },
  { id: "East Northport", state_id: "New York" },
  { id: "East Orange", state_id: "New Jersey" },
  { id: "East Palo Alto", state_id: "California" },
  { id: "East Pasadena", state_id: "California" },
  { id: "East Patchogue", state_id: "New York" },
  { id: "East Peoria", state_id: "Illinois" },
  { id: "East Perrine", state_id: "Florida" },
  { id: "East Point", state_id: "Georgia" },
  { id: "East Porterville", state_id: "California" },
  { id: "East Providence", state_id: "Rhode Island" },
  { id: "East Renton Highlands", state_id: "Washington" },
  { id: "East Ridge", state_id: "Tennessee" },
  { id: "East Riverdale", state_id: "Maryland" },
  { id: "East Rochester", state_id: "New York" },
  { id: "East Rochester", state_id: "New York" },
  { id: "East Rockaway", state_id: "New York" },
  { id: "East Rutherford", state_id: "New Jersey" },
  { id: "East San Gabriel", state_id: "California" },
  { id: "East St. Louis", state_id: "Illinois" },
  { id: "East Stroudsburg", state_id: "Pennsylvania" },
  { id: "East Wenatchee Bench", state_id: "Washington" },
  { id: "East Windsor", state_id: "Connecticut" },
  { id: "East York", state_id: "Pennsylvania" },
  { id: "Eastchester", state_id: "New York" },
  { id: "Eastchester", state_id: "New York" },
  { id: "Easthampton", state_id: "Massachusetts" },
  { id: "Eastlake", state_id: "Ohio" },
  { id: "Easton", state_id: "Pennsylvania" },
  { id: "Easton", state_id: "Maryland" },
  { id: "Easton", state_id: "Massachusetts" },
  { id: "Easton", state_id: "Connecticut" },
  { id: "Eastpointe", state_id: "Michigan" },
  { id: "Eastwood", state_id: "Michigan" },
  { id: "Eaton", state_id: "Ohio" },
  { id: "Eatonton", state_id: "Georgia" },
  { id: "Eatontown", state_id: "New Jersey" },
  { id: "Eau Claire", state_id: "Wisconsin" },
  { id: "Echelon", state_id: "New Jersey" },
  { id: "Economy", state_id: "Pennsylvania" },
  { id: "Ecorse", state_id: "Michigan" },
  { id: "Eden", state_id: "New York" },
  { id: "Eden", state_id: "North Carolina" },
  { id: "Eden Isle", state_id: "Louisiana" },
  { id: "Eden Prairie", state_id: "Minnesota" },
  { id: "Edgemere", state_id: "Maryland" },
  { id: "Edgewater", state_id: "New Jersey" },
  { id: "Edgewater", state_id: "Florida" },
  { id: "Edgewood", state_id: "Kentucky" },
  { id: "Edgewood", state_id: "Maryland" },
  { id: "Edgewood", state_id: "Washington" },
  { id: "Edina", state_id: "Minnesota" },
  { id: "Edinboro", state_id: "Pennsylvania" },
  { id: "Edinburg", state_id: "Texas" },
  { id: "Edison", state_id: "New Jersey" },
  { id: "Edmond", state_id: "Oklahoma" },
  { id: "Edmonds", state_id: "Washington" },
  { id: "Edwards", state_id: "Colorado" },
  { id: "Edwardsville", state_id: "Illinois" },
  { id: "Effingham", state_id: "Illinois" },
  { id: "Eglin AFB", state_id: "Florida" },
  { id: "Egypt Lake-Leto", state_id: "Florida" },
  { id: "Eidson Road", state_id: "Texas" },
  { id: "El Cajon", state_id: "California" },
  { id: "El Campo", state_id: "Texas" },
  { id: "El Centro", state_id: "California" },
  { id: "El Cerrito", state_id: "California" },
  { id: "El Dorado", state_id: "Arkansas" },
  { id: "El Dorado", state_id: "Kansas" },
  { id: "El Dorado Hills", state_id: "California" },
  { id: "El Mirage", state_id: "Arizona" },
  { id: "El Monte", state_id: "California" },
  { id: "El Paso", state_id: "Texas" },
  { id: "El Paso de Robles", state_id: "California" },
  { id: "El Reno", state_id: "Oklahoma" },
  { id: "El Rio", state_id: "California" },
  { id: "El Segundo", state_id: "California" },
  { id: "El Sobrante", state_id: "California" },
  { id: "Elbridge", state_id: "New York" },
  { id: "Eldersburg", state_id: "Maryland" },
  { id: "Elfers", state_id: "Florida" },
  { id: "Elgin", state_id: "Illinois" },
  { id: "Elizabeth", state_id: "New Jersey" },
  { id: "Elizabeth City", state_id: "North Carolina" },
  { id: "Elizabethton", state_id: "Tennessee" },
  { id: "Elizabethtown", state_id: "Pennsylvania" },
  { id: "Elizabethtown", state_id: "Kentucky" },
  { id: "Elk City", state_id: "Oklahoma" },
  { id: "Elk Grove", state_id: "California" },
  { id: "Elk Grove Village", state_id: "Illinois" },
  { id: "Elk Plain", state_id: "Washington" },
  { id: "Elk River", state_id: "Minnesota" },
  { id: "Elkhart", state_id: "Indiana" },
  { id: "Elkhorn", state_id: "Nebraska" },
  { id: "Elkhorn", state_id: "Wisconsin" },
  { id: "Elkins", state_id: "West Virginia" },
  { id: "Elko", state_id: "Nevada" },
  { id: "Elkridge", state_id: "Maryland" },
  { id: "Elkton", state_id: "Maryland" },
  { id: "Ellensburg", state_id: "Washington" },
  { id: "Ellicott", state_id: "New York" },
  { id: "Ellicott City", state_id: "Maryland" },
  { id: "Ellington", state_id: "Connecticut" },
  { id: "Ellisville", state_id: "Missouri" },
  { id: "Ellsworth", state_id: "Maine" },
  { id: "Ellwood City", state_id: "Pennsylvania" },
  { id: "Elm Grove", state_id: "Wisconsin" },
  { id: "Elma", state_id: "New York" },
  { id: "Elmhurst", state_id: "Illinois" },
  { id: "Elmira", state_id: "New York" },
  { id: "Elmira", state_id: "New York" },
  { id: "Elmont", state_id: "New York" },
  { id: "Elmwood Park", state_id: "New Jersey" },
  { id: "Elmwood Park", state_id: "Illinois" },
  { id: "Elon College", state_id: "North Carolina" },
  { id: "Eloy", state_id: "Arizona" },
  { id: "Elsmere", state_id: "Kentucky" },
  { id: "Elwood", state_id: "Indiana" },
  { id: "Elwood", state_id: "New York" },
  { id: "Elyria", state_id: "Ohio" },
  { id: "Emerson", state_id: "New Jersey" },
  { id: "Emeryville", state_id: "California" },
  { id: "Emmaus", state_id: "Pennsylvania" },
  { id: "Emporia", state_id: "Kansas" },
  { id: "Encinitas", state_id: "California" },
  { id: "Endicott", state_id: "New York" },
  { id: "Endwell", state_id: "New York" },
  { id: "Enfield", state_id: "Connecticut" },
  { id: "Englewood", state_id: "Colorado" },
  { id: "Englewood", state_id: "Florida" },
  { id: "Englewood", state_id: "New Jersey" },
  { id: "Englewood", state_id: "Ohio" },
  { id: "Enid", state_id: "Oklahoma" },
  { id: "Ennis", state_id: "Texas" },
  { id: "Ensley", state_id: "Florida" },
  { id: "Enterprise", state_id: "Alabama" },
  { id: "Enterprise", state_id: "Nevada" },
  { id: "Enumclaw", state_id: "Washington" },
  { id: "Ephrata", state_id: "Washington" },
  { id: "Ephrata", state_id: "Pennsylvania" },
  { id: "Erie", state_id: "Pennsylvania" },
  { id: "Erie", state_id: "Colorado" },
  { id: "Erlanger", state_id: "Kentucky" },
  { id: "Erlton-Ellisburg", state_id: "New Jersey" },
  { id: "Erwin", state_id: "New York" },
  { id: "Escanaba", state_id: "Michigan" },
  { id: "Escondido", state_id: "California" },
  { id: "Esopus", state_id: "New York" },
  { id: "Espanola", state_id: "New Mexico" },
  { id: "Essex", state_id: "Maryland" },
  { id: "Essex", state_id: "Connecticut" },
  { id: "Essex", state_id: "Vermont" },
  { id: "Essex Junction", state_id: "Vermont" },
  { id: "Estelle", state_id: "Louisiana" },
  { id: "Estero", state_id: "Florida" },
  { id: "Estherville", state_id: "Iowa" },
  { id: "Euclid", state_id: "Ohio" },
  { id: "Eufaula", state_id: "Alabama" },
  { id: "Eugene", state_id: "Oregon" },
  { id: "Euless", state_id: "Texas" },
  { id: "Eunice", state_id: "Louisiana" },
  { id: "Eureka", state_id: "California" },
  { id: "Eureka", state_id: "Missouri" },
  { id: "Eustis", state_id: "Florida" },
  { id: "Evans", state_id: "Georgia" },
  { id: "Evans", state_id: "Colorado" },
  { id: "Evans", state_id: "New York" },
  { id: "Evanston", state_id: "Illinois" },
  { id: "Evanston", state_id: "Wyoming" },
  { id: "Evansville", state_id: "Indiana" },
  { id: "Everett", state_id: "Massachusetts" },
  { id: "Everett", state_id: "Washington" },
  { id: "Evergreen", state_id: "Montana" },
  { id: "Evergreen", state_id: "Colorado" },
  { id: "Evergreen Park", state_id: "Illinois" },
  { id: "Ewa Beach", state_id: "Hawaii" },
  { id: "Mont St-Hilair", state_id: "Hawaii" },
  { id: "Ewing", state_id: "New Jersey" },
  { id: "Excelsior Springs", state_id: "Missouri" },
  { id: "Smithville", state_id: "Missouri" },
  { id: "Exeter", state_id: "New Hampshire" },
  { id: "Exeter", state_id: "New Hampshire" },
  { id: "Exeter", state_id: "California" },
  { id: "Exeter", state_id: "Rhode Island" },
  { id: "Fabens", state_id: "Texas" },
  { id: "Fair Lawn", state_id: "New Jersey" },
  { id: "Fair Oaks", state_id: "California" },
  { id: "Fair Oaks", state_id: "Georgia" },
  { id: "Fair Plain", state_id: "Michigan" },
  { id: "Fairbanks", state_id: "Alaska" },
  { id: "Fairborn", state_id: "Ohio" },
  { id: "Fairdale", state_id: "Kentucky" },
  { id: "Fairfax", state_id: "California" },
  { id: "Fairfax", state_id: "Virginia" },
  { id: "Fairfield", state_id: "Ohio" },
  { id: "Fairfield", state_id: "New Jersey" },
  { id: "Fairfield", state_id: "California" },
  { id: "Fairfield", state_id: "Alabama" },
  { id: "Fairfield", state_id: "Connecticut" },
  { id: "Fairfield", state_id: "Iowa" },
  { id: "Fairfield", state_id: "Maine" },
  { id: "Fairhaven", state_id: "Massachusetts" },
  { id: "Fairhope", state_id: "Alabama" },
  { id: "Fairland", state_id: "Maryland" },
  { id: "Fairlawn", state_id: "Ohio" },
  { id: "Fairless Hills", state_id: "Pennsylvania" },
  { id: "Fairmont", state_id: "West Virginia" },
  { id: "Fairmont", state_id: "Minnesota" },
  { id: "Fairmount", state_id: "New York" },
  { id: "Fairview", state_id: "Oregon" },
  { id: "Fairview", state_id: "New Jersey" },
  { id: "Fairview", state_id: "California" },
  { id: "Fairview", state_id: "Georgia" },
  { id: "Fairview Heights", state_id: "Illinois" },
  { id: "Fairview Park", state_id: "Ohio" },
  { id: "Fairview Shores", state_id: "Florida" },
  { id: "Fairwood", state_id: "Washington" },
  { id: "Fall River", state_id: "Massachusetts" },
  { id: "Fallbrook", state_id: "California" },
  { id: "Fallon", state_id: "Nevada" },
  { id: "Falls Church", state_id: "Virginia" },
  { id: "Fallsburg", state_id: "New York" },
  { id: "Fallston", state_id: "Maryland" },
  { id: "Falmouth", state_id: "Maine" },
  { id: "Falmouth", state_id: "Massachusetts" },
  { id: "Fanwood", state_id: "New Jersey" },
  { id: "Fargo", state_id: "North Dakota" },
  { id: "Faribault", state_id: "Minnesota" },
  { id: "Farmers Branch", state_id: "Texas" },
  { id: "Farmersville", state_id: "California" },
  { id: "Farmingdale", state_id: "New York" },
  { id: "Farmington", state_id: "New Mexico" },
  { id: "Farmington", state_id: "New York" },
  { id: "Farmington", state_id: "Utah" },
  { id: "Farmington", state_id: "Minnesota" },
  { id: "Farmington", state_id: "Missouri" },
  { id: "Farmington", state_id: "Maine" },
  { id: "Farmington", state_id: "Michigan" },
  { id: "Farmington", state_id: "Connecticut" },
  { id: "Farmington Hills", state_id: "Michigan" },
  { id: "Farmingville", state_id: "New York" },
  { id: "Farmville", state_id: "Virginia" },
  { id: "Farragut", state_id: "Tennessee" },
  { id: "Farrell", state_id: "Pennsylvania" },
  { id: "Fayetteville", state_id: "North Carolina" },
  { id: "Fayetteville", state_id: "Tennessee" },
  { id: "Fayetteville", state_id: "Arkansas" },
  { id: "Fayetteville", state_id: "Georgia" },
  { id: "Feasterville-Trevose", state_id: "Pennsylvania" },
  { id: "Federal Heights", state_id: "Colorado" },
  { id: "Federal Way", state_id: "Washington" },
  { id: "Fenton", state_id: "New York" },
  { id: "Fenton", state_id: "Michigan" },
  { id: "Fergus Falls", state_id: "Minnesota" },
  { id: "Ferguson", state_id: "Missouri" },
  { id: "Fern Creek", state_id: "Kentucky" },
  { id: "Fern Park", state_id: "Florida" },
  { id: "Fernandina Beach", state_id: "Florida" },
  { id: "Ferndale", state_id: "Maryland" },
  { id: "Ferndale", state_id: "Michigan" },
  { id: "Ferndale", state_id: "Washington" },
  { id: "Fernley", state_id: "Nevada" },
  { id: "Fernway", state_id: "Pennsylvania" },
  { id: "Ferry Pass", state_id: "Florida" },
  { id: "Festus", state_id: "Missouri" },
  { id: "Fillmore", state_id: "California" },
  { id: "Findlay", state_id: "Ohio" },
  { id: "Finneytown", state_id: "Ohio" },
  { id: "Fishers", state_id: "Indiana" },
  { id: "Fishkill", state_id: "New York" },
  { id: "Fitchburg", state_id: "Wisconsin" },
  { id: "Fitchburg", state_id: "Massachusetts" },
  { id: "Fitzgerald", state_id: "Georgia" },
  { id: "Five Corners", state_id: "Washington" },
  { id: "Five Forks", state_id: "South Carolina" },
  { id: "Flagstaff", state_id: "Arizona" },
  { id: "Flat Rock", state_id: "Michigan" },
  { id: "Flatwoods", state_id: "Kentucky" },
  { id: "Flint", state_id: "Michigan" },
  { id: "Floral Park", state_id: "New York" },
  { id: "Florence", state_id: "South Carolina" },
  { id: "Florence", state_id: "Oregon" },
  { id: "Florence", state_id: "Kentucky" },
  { id: "Florence", state_id: "Arizona" },
  { id: "Florence", state_id: "Alabama" },
  { id: "Florence-Graham", state_id: "California" },
  { id: "Florence-Roebling", state_id: "New Jersey" },
  { id: "Florham Park", state_id: "New Jersey" },
  { id: "Florida City", state_id: "Florida" },
  { id: "Florida Ridge", state_id: "Florida" },
  { id: "Florin", state_id: "California" },
  { id: "Florissant", state_id: "Missouri" },
  { id: "Flossmoor", state_id: "Illinois" },
  { id: "Flower Mound", state_id: "Texas" },
  { id: "Flowing Wells", state_id: "Arizona" },
  { id: "Flushing", state_id: "Michigan" },
  { id: "Folcroft", state_id: "Pennsylvania" },
  { id: "Foley", state_id: "Alabama" },
  { id: "Folsom", state_id: "California" },
  { id: "Folsom", state_id: "Pennsylvania" },
  { id: "Fond du Lac", state_id: "Wisconsin" },
  { id: "Fontana", state_id: "California" },
  { id: "Foothill Farms", state_id: "California" },
  { id: "Foothill Ranch", state_id: "California" },
  { id: "Fords", state_id: "New Jersey" },
  { id: "Forest", state_id: "Virginia" },
  { id: "Forest Acres", state_id: "South Carolina" },
  { id: "Forest City", state_id: "North Carolina" },
  { id: "Forest City", state_id: "Florida" },
  { id: "Forest Glen", state_id: "Maryland" },
  { id: "Forest Grove", state_id: "Oregon" },
  { id: "Forest Hill", state_id: "Texas" },
  { id: "Forest Hills", state_id: "Pennsylvania" },
  { id: "Forest Hills", state_id: "Michigan" },
  { id: "Forest Lake", state_id: "Minnesota" },
  { id: "Forest Park", state_id: "Illinois" },
  { id: "Forest Park", state_id: "Georgia" },
  { id: "Forest Park", state_id: "Ohio" },
  { id: "Forestdale", state_id: "Alabama" },
  { id: "Forestville", state_id: "Maryland" },
  { id: "Forestville", state_id: "Ohio" },
  { id: "Forrest City", state_id: "Arkansas" },
  { id: "Fort Ann", state_id: "New York" },
  { id: "Fort Atkinson", state_id: "Wisconsin" },
  { id: "Fort Belvoir", state_id: "Virginia" },
  { id: "Fort Benning South", state_id: "Georgia" },
  { id: "Fort Bliss", state_id: "Texas" },
  { id: "Fort Bragg", state_id: "North Carolina" },
  { id: "Fort Bragg", state_id: "California" },
  { id: "Fort Campbell North", state_id: "Kentucky" },
  { id: "Fort Carson", state_id: "Colorado" },
  { id: "Fort Collins", state_id: "Colorado" },
  { id: "Fort Dix", state_id: "New Jersey" },
  { id: "Fort Dodge", state_id: "Iowa" },
  { id: "Fort Drum", state_id: "New York" },
  { id: "Fort Hood", state_id: "Texas" },
  { id: "Fort Hunt", state_id: "Virginia" },
  { id: "Fort Knox", state_id: "Kentucky" },
  { id: "Fort Lauderdale", state_id: "Florida" },
  { id: "Fort Lee", state_id: "New Jersey" },
  { id: "Fort Lee", state_id: "Virginia" },
  { id: "Fort Leonard Wood", state_id: "Missouri" },
  { id: "Fort Lewis", state_id: "Washington" },
  { id: "Fort Lupton", state_id: "Colorado" },
  { id: "Fort Madison", state_id: "Iowa" },
  { id: "Fort Meade", state_id: "Maryland" },
  { id: "Fort Mill", state_id: "South Carolina" },
  { id: "Fort Mitchell", state_id: "Kentucky" },
  { id: "Fort Morgan", state_id: "Colorado" },
  { id: "Fort Myers", state_id: "Florida" },
  { id: "Fort Myers Beach", state_id: "Florida" },
  { id: "Fort Oglethorpe", state_id: "Georgia" },
  { id: "Fort Payne", state_id: "Alabama" },
  { id: "Fort Pierce", state_id: "Florida" },
  { id: "Fort Pierce North", state_id: "Florida" },
  { id: "Fort Polk South", state_id: "Louisiana" },
  { id: "Fort Riley North", state_id: "Kansas" },
  { id: "Fort Rucker", state_id: "Alabama" },
  { id: "Fort Salonga", state_id: "New York" },
  { id: "Fort Scott", state_id: "Kansas" },
  { id: "Fort Smith", state_id: "Arkansas" },
  { id: "Fort Stewart", state_id: "Georgia" },
  { id: "Fort Stockton", state_id: "Texas" },
  { id: "Fort Thomas", state_id: "Kentucky" },
  { id: "Fort Valley", state_id: "Georgia" },
  { id: "Fort Walton Beach", state_id: "Florida" },
  { id: "Fort Washington", state_id: "Maryland" },
  { id: "Fort Wayne", state_id: "Indiana" },
  { id: "Fort Worth", state_id: "Texas" },
  { id: "Fortuna", state_id: "California" },
  { id: "Fortuna Foothills", state_id: "Arizona" },
  { id: "Foster City", state_id: "California" },
  { id: "Fostoria", state_id: "Ohio" },
  { id: "Fountain", state_id: "Colorado" },
  { id: "Fountain Hills", state_id: "Arizona" },
  { id: "Fountain Inn", state_id: "South Carolina" },
  { id: "Fountain Valley", state_id: "California" },
  { id: "Fountainbleau", state_id: "Florida" },
  { id: "Four Corners", state_id: "Oregon" },
  { id: "Fox Lake", state_id: "Illinois" },
  { id: "Fox Point", state_id: "Wisconsin" },
  { id: "Foxborough", state_id: "Massachusetts" },
  { id: "Framingham", state_id: "Massachusetts" },
  { id: "Framingham", state_id: "Massachusetts" },
  { id: "Franconia", state_id: "Virginia" },
  { id: "Frankfort", state_id: "New York" },
  { id: "Frankfort", state_id: "Indiana" },
  { id: "Frankfort", state_id: "Kentucky" },
  { id: "Frankfort", state_id: "Illinois" },
  { id: "Frankfort Square", state_id: "Illinois" },
  { id: "Franklin", state_id: "Kentucky" },
  { id: "Franklin", state_id: "Indiana" },
  { id: "Franklin", state_id: "Louisiana" },
  { id: "Franklin", state_id: "Massachusetts" },
  { id: "Franklin", state_id: "New Hampshire" },
  { id: "Franklin", state_id: "Ohio" },
  { id: "Franklin", state_id: "Virginia" },
  { id: "Franklin", state_id: "Pennsylvania" },
  { id: "Franklin", state_id: "Wisconsin" },
  { id: "Franklin", state_id: "Tennessee" },
  { id: "Franklin Lakes", state_id: "New Jersey" },
  { id: "Franklin Park", state_id: "Illinois" },
  { id: "Franklin Park", state_id: "Pennsylvania" },
  { id: "Franklin Square", state_id: "New York" },
  { id: "Fraser", state_id: "Michigan" },
  { id: "Frederick", state_id: "Maryland" },
  { id: "Fredericksburg", state_id: "Virginia" },
  { id: "Fredericksburg", state_id: "Texas" },
  { id: "Fredonia", state_id: "New York" },
  { id: "Freehold", state_id: "New Jersey" },
  { id: "Freeport", state_id: "Maine" },
  { id: "Freeport", state_id: "Illinois" },
  { id: "Freeport", state_id: "New York" },
  { id: "Freeport", state_id: "Texas" },
  { id: "Freetown", state_id: "Massachusetts" },
  { id: "Fremont", state_id: "California" },
  { id: "Fremont", state_id: "Ohio" },
  { id: "Fremont", state_id: "Nebraska" },
  { id: "Fresno", state_id: "Texas" },
  { id: "Fresno", state_id: "California" },
  { id: "Fridley", state_id: "Minnesota" },
  { id: "Friendly", state_id: "Maryland" },
  { id: "Friendswood", state_id: "Texas" },
  { id: "Frisco", state_id: "Texas" },
  { id: "Front Royal", state_id: "Virginia" },
  { id: "Frostburg", state_id: "Maryland" },
  { id: "Fruit Cove", state_id: "Florida" },
  { id: "Fruita", state_id: "Colorado" },
  { id: "Fruitvale", state_id: "Colorado" },
  { id: "Fruitville", state_id: "Florida" },
  { id: "Fullerton", state_id: "California" },
  { id: "Fullerton", state_id: "Pennsylvania" },
  { id: "Fulton", state_id: "New York" },
  { id: "Fulton", state_id: "Missouri" },
  { id: "Fultondale", state_id: "Alabama" },
  { id: "Fuquay-Varina", state_id: "North Carolina" },
  { id: "Gadsden", state_id: "Alabama" },
  { id: "Gaffney", state_id: "South Carolina" },
  { id: "Gages Lake", state_id: "Illinois" },
  { id: "Gahanna", state_id: "Ohio" },
  { id: "Gainesville", state_id: "Texas" },
  { id: "Gainesville", state_id: "Georgia" },
  { id: "Gainesville", state_id: "Florida" },
  { id: "Gaithersburg", state_id: "Maryland" },
  { id: "Galax", state_id: "Virginia" },
  { id: "Galena Park", state_id: "Texas" },
  { id: "Galesburg", state_id: "Illinois" },
  { id: "Galion", state_id: "Ohio" },
  { id: "Gallatin", state_id: "Tennessee" },
  { id: "Galliano", state_id: "Louisiana" },
  { id: "Gallup", state_id: "New Mexico" },
  { id: "Galt", state_id: "California" },
  { id: "Galveston", state_id: "Texas" },
  { id: "Gantt", state_id: "South Carolina" },
  { id: "Garden Acres", state_id: "California" },
  { id: "Garden City", state_id: "Georgia" },
  { id: "Garden City", state_id: "Kansas" },
  { id: "Garden City", state_id: "Idaho" },
  { id: "Garden City", state_id: "Michigan" },
  { id: "Garden City", state_id: "South Carolina" },
  { id: "Garden City", state_id: "New York" },
  { id: "Garden City Park", state_id: "New York" },
  { id: "Garden Grove", state_id: "California" },
  { id: "Garden Home-Whitford", state_id: "Oregon" },
  { id: "Gardena", state_id: "California" },
  { id: "Gardendale", state_id: "Alabama" },
  { id: "Gardere", state_id: "Louisiana" },
  { id: "Gardiner", state_id: "Maine" },
  { id: "Gardner", state_id: "Kansas" },
  { id: "Gardner", state_id: "Massachusetts" },
  { id: "Gardnerville Ranchos", state_id: "Nevada" },
  { id: "Garfield", state_id: "New Jersey" },
  { id: "Garfield Heights", state_id: "Ohio" },
  { id: "Garland", state_id: "Texas" },
  { id: "Garner", state_id: "North Carolina" },
  { id: "Garrison", state_id: "Maryland" },
  { id: "Gary", state_id: "Indiana" },
  { id: "Gastonia", state_id: "North Carolina" },
  { id: "Gates", state_id: "New York" },
  { id: "Gates-North Gates", state_id: "New York" },
  { id: "Gatesville", state_id: "Texas" },
  { id: "Gautier", state_id: "Mississippi" },
  { id: "Geddes", state_id: "New York" },
  { id: "Genesee", state_id: "Wisconsin" },
  { id: "Geneseo", state_id: "New York" },
  { id: "Geneseo", state_id: "New York" },
  { id: "Geneseo", state_id: "Illinois" },
  { id: "Geneva", state_id: "Illinois" },
  { id: "Geneva", state_id: "New York" },
  { id: "Geneva", state_id: "Ohio" },
  { id: "Georgetown", state_id: "Texas" },
  { id: "Georgetown", state_id: "South Carolina" },
  { id: "Georgetown", state_id: "Georgia" },
  { id: "Georgetown", state_id: "Massachusetts" },
  { id: "Georgetown", state_id: "Kentucky" },
  { id: "Georgetown County", state_id: "South Carolina" },
  { id: "Gering", state_id: "Nebraska" },
  { id: "German Flatts", state_id: "New York" },
  { id: "Germantown", state_id: "Tennessee" },
  { id: "Germantown", state_id: "Wisconsin" },
  { id: "Germantown", state_id: "Maryland" },
  { id: "Gettysburg", state_id: "Pennsylvania" },
  { id: "Gibsonton", state_id: "Florida" },
  { id: "Gifford", state_id: "Florida" },
  { id: "Gig Harbor", state_id: "Washington" },
  { id: "Gilbert", state_id: "Arizona" },
  { id: "Gilford", state_id: "New Hampshire" },
  { id: "Gillette", state_id: "Wyoming" },
  { id: "Gilroy", state_id: "California" },
  { id: "Girard", state_id: "Ohio" },
  { id: "Gladeview", state_id: "Florida" },
  { id: "Gladewater", state_id: "Texas" },
  { id: "Gladstone", state_id: "Oregon" },
  { id: "Gladstone", state_id: "Missouri" },
  { id: "Glasgow", state_id: "Kentucky" },
  { id: "Glasgow", state_id: "Delaware" },
  { id: "Glassboro", state_id: "New Jersey" },
  { id: "Glastonbury", state_id: "Connecticut" },
  { id: "Glastonbury Center", state_id: "Connecticut" },
  { id: "Glen Allen", state_id: "Virginia" },
  { id: "Glen Avon", state_id: "California" },
  { id: "Glen Burnie", state_id: "Maryland" },
  { id: "Glen Carbon", state_id: "Illinois" },
  { id: "Glen Cove", state_id: "New York" },
  { id: "Glen Ellyn", state_id: "Illinois" },
  { id: "Glen Ridge", state_id: "New Jersey" },
  { id: "Glen Rock", state_id: "New Jersey" },
  { id: "Glenarden", state_id: "Maryland" },
  { id: "Glencoe", state_id: "Illinois" },
  { id: "Glendale", state_id: "California" },
  { id: "Glendale", state_id: "Arizona" },
  { id: "Glendale", state_id: "Wisconsin" },
  { id: "Glendale Heights", state_id: "Illinois" },
  { id: "Glendora", state_id: "California" },
  { id: "Glenn Dale", state_id: "Maryland" },
  { id: "Glenn Heights", state_id: "Texas" },
  { id: "Glenolden", state_id: "Pennsylvania" },
  { id: "Glenpool", state_id: "Oklahoma" },
  { id: "Glens Falls", state_id: "New York" },
  { id: "Glens Falls North", state_id: "New York" },
  { id: "Glenside", state_id: "Pennsylvania" },
  { id: "Glenvar Heights", state_id: "Florida" },
  { id: "Glenview", state_id: "Illinois" },
  { id: "Glenville", state_id: "New York" },
  { id: "Glenwood", state_id: "Illinois" },
  { id: "Glenwood Springs", state_id: "Colorado" },
  { id: "Globe", state_id: "Arizona" },
  { id: "Glocester", state_id: "Rhode Island" },
  { id: "Gloucester", state_id: "Massachusetts" },
  { id: "Gloucester City", state_id: "New Jersey" },
  { id: "Gloucester Point", state_id: "Virginia" },
  { id: "Gloversville", state_id: "New York" },
  { id: "Godfrey", state_id: "Illinois" },
  { id: "Goffstown", state_id: "New Hampshire" },
  { id: "Gold Camp", state_id: "Arizona" },
  { id: "Gold River", state_id: "California" },
  { id: "Golden", state_id: "Colorado" },
  { id: "Golden Gate", state_id: "Florida" },
  { id: "Golden Glades", state_id: "Florida" },
  { id: "Golden Hills", state_id: "California" },
  { id: "Golden Lakes", state_id: "Florida" },
  { id: "Golden Valley", state_id: "Minnesota" },
  { id: "Goldenrod", state_id: "Florida" },
  { id: "Goldsboro", state_id: "North Carolina" },
  { id: "Goleta", state_id: "California" },
  { id: "Gonzales", state_id: "California" },
  { id: "Gonzales", state_id: "Louisiana" },
  { id: "Gonzales", state_id: "Texas" },
  { id: "Gonzalez", state_id: "Florida" },
  { id: "Goodings Grove", state_id: "Illinois" },
  { id: "Goodlettsville", state_id: "Tennessee" },
  { id: "Goodyear", state_id: "Arizona" },
  { id: "Goose Creek", state_id: "South Carolina" },
  { id: "Gorham", state_id: "Maine" },
  { id: "Goshen", state_id: "Indiana" },
  { id: "Goshen", state_id: "New York" },
  { id: "Goulds", state_id: "Florida" },
  { id: "Gouverneur", state_id: "New York" },
  { id: "Grafton", state_id: "Massachusetts" },
  { id: "Grafton", state_id: "Wisconsin" },
  { id: "Graham", state_id: "Washington" },
  { id: "Graham", state_id: "North Carolina" },
  { id: "Graham", state_id: "Texas" },
  { id: "Granby", state_id: "New York" },
  { id: "Granby", state_id: "Connecticut" },
  { id: "Granby", state_id: "Massachusetts" },
  { id: "Grand Blanc", state_id: "Michigan" },
  { id: "Grand Chute", state_id: "Wisconsin" },
  { id: "Grand Forks", state_id: "North Dakota" },
  { id: "Grand Haven", state_id: "Michigan" },
  { id: "Grand Island", state_id: "Nebraska" },
  { id: "Grand Island", state_id: "New York" },
  { id: "Grand Junction", state_id: "Colorado" },
  { id: "Grand Ledge", state_id: "Michigan" },
  { id: "Grand Prairie", state_id: "Texas" },
  { id: "Grand Rapids", state_id: "Wisconsin" },
  { id: "Grand Rapids", state_id: "Michigan" },
  { id: "Grand Rapids", state_id: "Minnesota" },
  { id: "Grand Terrace", state_id: "California" },
  { id: "Grandview", state_id: "Missouri" },
  { id: "Grandview", state_id: "Washington" },
  { id: "Grandview Heights", state_id: "Ohio" },
  { id: "Grandville", state_id: "Michigan" },
  { id: "Granger", state_id: "Indiana" },
  { id: "Granite Bay", state_id: "California" },
  { id: "Granite City", state_id: "Illinois" },
  { id: "Grants", state_id: "New Mexico" },
  { id: "Grants Pass", state_id: "Oregon" },
  { id: "Grantsville", state_id: "Utah" },
  { id: "Granville", state_id: "New York" },
  { id: "Grapevine", state_id: "Texas" },
  { id: "Grass Valley", state_id: "California" },
  { id: "Gray", state_id: "Maine" },
  { id: "Grayslake", state_id: "Illinois" },
  { id: "Great Barrington", state_id: "Massachusetts" },
  { id: "Great Bend", state_id: "Kansas" },
  { id: "Great Falls", state_id: "Montana" },
  { id: "Great Falls", state_id: "Virginia" },
  { id: "Great Neck", state_id: "New York" },
  { id: "Great Neck Plaza", state_id: "New York" },
  { id: "Greater Carrollwood", state_id: "Florida" },
  { id: "Greater Landover", state_id: "Maryland" },
  { id: "Greater Northdale", state_id: "Florida" },
  { id: "Greater Sun Center", state_id: "Florida" },
  { id: "Greater Upper Marlboro", state_id: "Maryland" },
  { id: "Greatwood", state_id: "Texas" },
  { id: "Greece", state_id: "New York" },
  { id: "Greece", state_id: "New York" },
  { id: "Greeley", state_id: "Colorado" },
  { id: "Green", state_id: "Ohio" },
  { id: "Green", state_id: "Oregon" },
  { id: "Green Bay", state_id: "Wisconsin" },
  { id: "Green Haven", state_id: "Maryland" },
  { id: "Green Hill", state_id: "Tennessee" },
  { id: "Green River", state_id: "Wyoming" },
  { id: "Green Valley", state_id: "Maryland" },
  { id: "Green Valley", state_id: "Arizona" },
  { id: "Greenacres", state_id: "Florida" },
  { id: "Greenbelt", state_id: "Maryland" },
  { id: "Greenburgh", state_id: "New York" },
  { id: "Greencastle", state_id: "Indiana" },
  { id: "Greendale", state_id: "Wisconsin" },
  { id: "Greeneville", state_id: "Tennessee" },
  { id: "Greenfield", state_id: "Wisconsin" },
  { id: "Greenfield", state_id: "New York" },
  { id: "Greenfield", state_id: "Indiana" },
  { id: "Greenfield", state_id: "Massachusetts" },
  { id: "Greenfield", state_id: "Massachusetts" },
  { id: "Greenfield", state_id: "California" },
  { id: "Greenlawn", state_id: "New York" },
  { id: "Greensboro", state_id: "North Carolina" },
  { id: "Greensburg", state_id: "Pennsylvania" },
  { id: "Greensburg", state_id: "Indiana" },
  { id: "Greentree", state_id: "New Jersey" },
  { id: "Greenville", state_id: "Mississippi" },
  { id: "Greenville", state_id: "Michigan" },
  { id: "Greenville", state_id: "Alabama" },
  { id: "Greenville", state_id: "Illinois" },
  { id: "Greenville", state_id: "Pennsylvania" },
  { id: "Greenville", state_id: "Rhode Island" },
  { id: "Greenville", state_id: "Texas" },
  { id: "Greenville", state_id: "South Carolina" },
  { id: "Greenville", state_id: "North Carolina" },
  { id: "Greenville", state_id: "Ohio" },
  { id: "Greenville", state_id: "New York" },
  { id: "Greenville", state_id: "Wisconsin" },
  { id: "Greenwich", state_id: "Connecticut" },
  { id: "Greenwood", state_id: "Indiana" },
  { id: "Greenwood", state_id: "Mississippi" },
  { id: "Greenwood", state_id: "Arkansas" },
  { id: "Greenwood", state_id: "South Carolina" },
  { id: "Greenwood Village", state_id: "Colorado" },
  { id: "Greer", state_id: "South Carolina" },
  { id: "Grenada", state_id: "Mississippi" },
  { id: "Gresham", state_id: "Oregon" },
  { id: "Gresham Park", state_id: "Georgia" },
  { id: "Gretna", state_id: "Louisiana" },
  { id: "Griffin", state_id: "Georgia" },
  { id: "Griffith", state_id: "Indiana" },
  { id: "Grinnell", state_id: "Iowa" },
  { id: "Griswold", state_id: "Connecticut" },
  { id: "Groesbeck", state_id: "Ohio" },
  { id: "Grosse Ile", state_id: "Michigan" },
  { id: "Grosse Pointe Farms", state_id: "Michigan" },
  { id: "Grosse Pointe Park", state_id: "Michigan" },
  { id: "Grosse Pointe Woods", state_id: "Michigan" },
  { id: "Groton", state_id: "Massachusetts" },
  { id: "Groton", state_id: "Connecticut" },
  { id: "Groton", state_id: "Connecticut" },
  { id: "Grove City", state_id: "Ohio" },
  { id: "Grove City", state_id: "Pennsylvania" },
  { id: "Groveland", state_id: "Massachusetts" },
  { id: "Grover Beach", state_id: "California" },
  { id: "Groves", state_id: "Texas" },
  { id: "Groveton", state_id: "Virginia" },
  { id: "Grovetown", state_id: "Georgia" },
  { id: "Guilderland", state_id: "New York" },
  { id: "Guilford", state_id: "Connecticut" },
  { id: "Gulf Gate Estates", state_id: "Florida" },
  { id: "Gulfport", state_id: "Florida" },
  { id: "Gulfport", state_id: "Mississippi" },
  { id: "Gunbarrel", state_id: "Colorado" },
  { id: "Guntersville", state_id: "Alabama" },
  { id: "Gurnee", state_id: "Illinois" },
  { id: "Guthrie", state_id: "Oklahoma" },
  { id: "Guttenberg", state_id: "New Jersey" },
  { id: "Guymon", state_id: "Oklahoma" },
  { id: "Hacienda Heights", state_id: "California" },
  { id: "Hackensack", state_id: "New Jersey" },
  { id: "Hackettstown", state_id: "New Jersey" },
  { id: "Haddam", state_id: "Connecticut" },
  { id: "Haddon Heights", state_id: "New Jersey" },
  { id: "Haddonfield", state_id: "New Jersey" },
  { id: "Hagerstown", state_id: "Maryland" },
  { id: "Haiku-Pauwela", state_id: "Hawaii" },
  { id: "Hailey", state_id: "Idaho" },
  { id: "Haines City", state_id: "Florida" },
  { id: "Halawa", state_id: "Hawaii" },
  { id: "Haledon", state_id: "New Jersey" },
  { id: "Hales Corners", state_id: "Wisconsin" },
  { id: "Half Moon", state_id: "North Carolina" },
  { id: "Half Moon Bay", state_id: "California" },
  { id: "Halfmoon", state_id: "New York" },
  { id: "Halfway", state_id: "Maryland" },
  { id: "Halifax", state_id: "Massachusetts" },
  { id: "Hallandale", state_id: "Florida" },
  { id: "Haltom City", state_id: "Texas" },
  { id: "Ham Lake", state_id: "Minnesota" },
  { id: "Hamburg", state_id: "New York" },
  { id: "Hamburg", state_id: "New York" },
  { id: "Hamden", state_id: "Connecticut" },
  { id: "Hamilton", state_id: "Alabama" },
  { id: "Hamilton", state_id: "Massachusetts" },
  { id: "Hamilton", state_id: "Ohio" },
  { id: "Hamlet", state_id: "North Carolina" },
  { id: "Hamlin", state_id: "New York" },
  { id: "Hammond", state_id: "Louisiana" },
  { id: "Hammond", state_id: "Indiana" },
  { id: "Hammonton", state_id: "New Jersey" },
  { id: "Hampden", state_id: "Maine" },
  { id: "Hampstead", state_id: "New Hampshire" },
  { id: "Hampton", state_id: "New Hampshire" },
  { id: "Hampton", state_id: "New Hampshire" },
  { id: "Hampton", state_id: "Virginia" },
  { id: "Hampton Bays", state_id: "New York" },
  { id: "Hampton Township", state_id: "Pennsylvania" },
  { id: "Hamptons at Boca Raton", state_id: "Florida" },
  { id: "Hamtramck", state_id: "Michigan" },
  { id: "Hanahan", state_id: "South Carolina" },
  { id: "Hanford", state_id: "California" },
  { id: "Hannibal", state_id: "Missouri" },
  { id: "Hanover", state_id: "New Hampshire" },
  { id: "Hanover", state_id: "New Hampshire" },
  { id: "Hanover", state_id: "Massachusetts" },
  { id: "Hanover", state_id: "Pennsylvania" },
  { id: "Hanover", state_id: "New York" },
  { id: "Hanover Park", state_id: "Illinois" },
  { id: "Hanson", state_id: "Massachusetts" },
  { id: "Hapeville", state_id: "Georgia" },
  { id: "Harahan", state_id: "Louisiana" },
  { id: "Harker Heights", state_id: "Texas" },
  { id: "Harleysville", state_id: "Pennsylvania" },
  { id: "Harlingen", state_id: "Texas" },
  { id: "Harper Woods", state_id: "Michigan" },
  { id: "Harriman", state_id: "Tennessee" },
  { id: "Harrisburg", state_id: "Pennsylvania" },
  { id: "Harrisburg", state_id: "Illinois" },
  { id: "Harrison", state_id: "Arkansas" },
  { id: "Harrison", state_id: "Michigan" },
  { id: "Harrison", state_id: "New Jersey" },
  { id: "Harrison", state_id: "New York" },
  { id: "Harrison", state_id: "Ohio" },
  { id: "Harrison", state_id: "New York" },
  { id: "Harrison", state_id: "Tennessee" },
  { id: "Harrison Township", state_id: "Pennsylvania" },
  { id: "Harrisonburg", state_id: "Virginia" },
  { id: "Harrisonville", state_id: "Missouri" },
  { id: "Harrodsburg", state_id: "Kentucky" },
  { id: "Hartford", state_id: "Connecticut" },
  { id: "Hartford", state_id: "Connecticut" },
  { id: "Hartford", state_id: "Wisconsin" },
  { id: "Hartford", state_id: "Vermont" },
  { id: "Hartford City", state_id: "Indiana" },
  { id: "Hartland", state_id: "Wisconsin" },
  { id: "Hartsdale", state_id: "New York" },
  { id: "Hartselle", state_id: "Alabama" },
  { id: "Hartsville", state_id: "South Carolina" },
  { id: "Harvard", state_id: "Illinois" },
  { id: "Harvey", state_id: "Illinois" },
  { id: "Harvey", state_id: "Louisiana" },
  { id: "Harwich", state_id: "Massachusetts" },
  { id: "Harwood Heights", state_id: "Illinois" },
  { id: "Hasbrouck Heights", state_id: "New Jersey" },
  { id: "Haslett", state_id: "Michigan" },
  { id: "Hastings", state_id: "Michigan" },
  { id: "Hastings", state_id: "Nebraska" },
  { id: "Hastings", state_id: "Minnesota" },
  { id: "Hastings", state_id: "New York" },
  { id: "Hastings-on-Hudson", state_id: "New York" },
  { id: "Hatboro", state_id: "Pennsylvania" },
  { id: "Hattiesburg", state_id: "Mississippi" },
  { id: "Hauppauge", state_id: "New York" },
  { id: "Havelock", state_id: "North Carolina" },
  { id: "Haverhill", state_id: "Massachusetts" },
  { id: "Haverstraw", state_id: "New York" },
  { id: "Haverstraw", state_id: "New York" },
  { id: "Havre", state_id: "Montana" },
  { id: "Havre de Grace", state_id: "Maryland" },
  { id: "Hawaiian Gardens", state_id: "California" },
  { id: "Hawaiian Paradise Park", state_id: "Hawaii" },
  { id: "Hawthorn Woods", state_id: "Illinois" },
  { id: "Hawthorne", state_id: "California" },
  { id: "Hawthorne", state_id: "New Jersey" },
  { id: "Hayden", state_id: "Idaho" },
  { id: "Hayesville", state_id: "Oregon" },
  { id: "Hays", state_id: "Kansas" },
  { id: "Haysville", state_id: "Kansas" },
  { id: "Hayward", state_id: "California" },
  { id: "Hazel Crest", state_id: "Illinois" },
  { id: "Hazel Dell North", state_id: "Washington" },
  { id: "Hazel Dell South", state_id: "Washington" },
  { id: "Hazel Park", state_id: "Michigan" },
  { id: "Hazelwood", state_id: "Missouri" },
  { id: "Hazleton", state_id: "Pennsylvania" },
  { id: "Healdsburg", state_id: "California" },
  { id: "Heath", state_id: "Ohio" },
  { id: "Heber", state_id: "Utah" },
  { id: "Heber Springs", state_id: "Arkansas" },
  { id: "Hebron", state_id: "Connecticut" },
  { id: "Helena", state_id: "Arkansas" },
  { id: "Helena", state_id: "Alabama" },
  { id: "Helena", state_id: "Montana" },
  { id: "Helena Valley Southeast", state_id: "Montana" },
  { id: "Helena Valley West Central", state_id: "Montana" },
  { id: "Hemet", state_id: "California" },
  { id: "Hempstead", state_id: "New York" },
  { id: "Hempstead", state_id: "New York" },
  { id: "Henderson", state_id: "North Carolina" },
  { id: "Henderson", state_id: "Texas" },
  { id: "Henderson", state_id: "Kentucky" },
  { id: "Henderson", state_id: "Nevada" },
  { id: "Hendersonville", state_id: "Tennessee" },
  { id: "Hendersonville", state_id: "North Carolina" },
  { id: "Henrietta", state_id: "New York" },
  { id: "Henryetta", state_id: "Oklahoma" },
  { id: "Hercules", state_id: "California" },
  { id: "Hereford", state_id: "Texas" },
  { id: "Herkimer", state_id: "New York" },
  { id: "Herkimer", state_id: "New York" },
  { id: "Hermantown", state_id: "Minnesota" },
  { id: "Hermiston", state_id: "Oregon" },
  { id: "Hermitage", state_id: "Pennsylvania" },
  { id: "Hermosa Beach", state_id: "California" },
  { id: "Hernando", state_id: "Florida" },
  { id: "Hernando", state_id: "Mississippi" },
  { id: "Herndon", state_id: "Virginia" },
  { id: "Herrin", state_id: "Illinois" },
  { id: "Hershey", state_id: "Pennsylvania" },
  { id: "Hesperia", state_id: "California" },
  { id: "Hewitt", state_id: "Texas" },
  { id: "Hewlett", state_id: "New York" },
  { id: "Hialeah", state_id: "Florida" },
  { id: "Hialeah Gardens", state_id: "Florida" },
  { id: "Hiawatha", state_id: "Iowa" },
  { id: "Hibbing", state_id: "Minnesota" },
  { id: "Hickory", state_id: "North Carolina" },
  { id: "Hickory Hills", state_id: "Illinois" },
  { id: "Hicksville", state_id: "New York" },
  { id: "Hidalgo", state_id: "Texas" },
  { id: "High Point", state_id: "North Carolina" },
  { id: "Highland", state_id: "Utah" },
  { id: "Highland", state_id: "Illinois" },
  { id: "Highland", state_id: "Indiana" },
  { id: "Highland", state_id: "California" },
  { id: "Highland Heights", state_id: "Kentucky" },
  { id: "Highland Heights", state_id: "Ohio" },
  { id: "Highland Park", state_id: "Texas" },
  { id: "Highland Park", state_id: "Illinois" },
  { id: "Highland Park", state_id: "New Jersey" },
  { id: "Highland Park", state_id: "Michigan" },
  { id: "Highland Springs", state_id: "Virginia" },
  { id: "Highland Village", state_id: "Texas" },
  { id: "Highlands", state_id: "Texas" },
  { id: "Highlands", state_id: "New York" },
  { id: "Highlands Ranch", state_id: "Colorado" },
  { id: "Highview", state_id: "Kentucky" },
  { id: "Hillcrest", state_id: "New York" },
  { id: "Hillcrest Heights", state_id: "Maryland" },
  { id: "Hilliard", state_id: "Ohio" },
  { id: "Hillsboro", state_id: "Ohio" },
  { id: "Hillsboro", state_id: "Oregon" },
  { id: "Hillsboro", state_id: "Texas" },
  { id: "Hillsborough", state_id: "California" },
  { id: "Hillsdale", state_id: "Michigan" },
  { id: "Hillsdale", state_id: "New Jersey" },
  { id: "Hillside", state_id: "New Jersey" },
  { id: "Hillside", state_id: "Illinois" },
  { id: "Hillview", state_id: "Kentucky" },
  { id: "Hilo", state_id: "Hawaii" },
  { id: "Hilton Head Island", state_id: "South Carolina" },
  { id: "Hinesville", state_id: "Georgia" },
  { id: "Hingham", state_id: "Massachusetts" },
  { id: "Hinsdale", state_id: "Illinois" },
  { id: "Hitchcock", state_id: "Texas" },
  { id: "Hobart", state_id: "Washington" },
  { id: "Hobart", state_id: "Indiana" },
  { id: "Hobbs", state_id: "New Mexico" },
  { id: "Hobe Sound", state_id: "Florida" },
  { id: "Hoboken", state_id: "New Jersey" },
  { id: "Hockessin", state_id: "Delaware" },
  { id: "Hoffman Estates", state_id: "Illinois" },
  { id: "Holbrook", state_id: "Massachusetts" },
  { id: "Holbrook", state_id: "Massachusetts" },
  { id: "Holbrook", state_id: "New York" },
  { id: "Holden", state_id: "Massachusetts" },
  { id: "Holiday", state_id: "Florida" },
  { id: "Holiday City-Berkeley", state_id: "New Jersey" },
  { id: "Holladay", state_id: "Utah" },
  { id: "Holland", state_id: "Michigan" },
  { id: "Hollins", state_id: "Virginia" },
  { id: "Hollis", state_id: "New Hampshire" },
  { id: "Hollister", state_id: "California" },
  { id: "Holliston", state_id: "Massachusetts" },
  { id: "Holly", state_id: "Michigan" },
  { id: "Holly Hill", state_id: "Florida" },
  { id: "Holly Springs", state_id: "Mississippi" },
  { id: "Holly Springs", state_id: "North Carolina" },
  { id: "Hollywood", state_id: "Florida" },
  { id: "Holmen", state_id: "Wisconsin" },
  { id: "Holt", state_id: "Michigan" },
  { id: "Holtsville", state_id: "New York" },
  { id: "Holualoa", state_id: "Hawaii" },
  { id: "Holyoke", state_id: "Massachusetts" },
  { id: "Home Gardens", state_id: "California" },
  { id: "Homeacre-Lyndora", state_id: "Pennsylvania" },
  { id: "Homeland Park", state_id: "South Carolina" },
  { id: "Homer", state_id: "New York" },
  { id: "Homestead", state_id: "Florida" },
  { id: "Homestead Meadows South", state_id: "Texas" },
  { id: "Homewood", state_id: "Alabama" },
  { id: "Homewood", state_id: "Illinois" },
  { id: "Homosassa Springs", state_id: "Florida" },
  { id: "Hondo", state_id: "Texas" },
  { id: "Honolulu", state_id: "Hawaii" },
  { id: "Hooksett", state_id: "New Hampshire" },
  { id: "Hoosick", state_id: "New York" },
  { id: "Hoover", state_id: "Alabama" },
  { id: "Hopatcong", state_id: "New Jersey" },
  { id: "Hope", state_id: "Arkansas" },
  { id: "Hope Mills", state_id: "North Carolina" },
  { id: "Hopewell", state_id: "Virginia" },
  { id: "Hopkins", state_id: "Minnesota" },
  { id: "Hopkinsville", state_id: "Kentucky" },
  { id: "Hopkinton", state_id: "Massachusetts" },
  { id: "Hopkinton", state_id: "Rhode Island" },
  { id: "Hoquiam", state_id: "Washington" },
  { id: "Horn Lake", state_id: "Mississippi" },
  { id: "Hornell", state_id: "New York" },
  { id: "Horseheads", state_id: "New York" },
  { id: "Horseheads", state_id: "New York" },
  { id: "Horsham", state_id: "Pennsylvania" },
  { id: "Hot Springs", state_id: "Arkansas" },
  { id: "Hot Springs Village", state_id: "Arkansas" },
  { id: "Houghton", state_id: "Michigan" },
  { id: "Houlton", state_id: "Maine" },
  { id: "Houma", state_id: "Louisiana" },
  { id: "Houston", state_id: "Texas" },
  { id: "Howard", state_id: "Wisconsin" },
  { id: "Howell", state_id: "Michigan" },
  { id: "Howland Center", state_id: "Ohio" },
  { id: "Hubbard", state_id: "Ohio" },
  { id: "Huber Heights", state_id: "Ohio" },
  { id: "Hudson", state_id: "Ohio" },
  { id: "Hudson", state_id: "New York" },
  { id: "Hudson", state_id: "Wisconsin" },
  { id: "Hudson", state_id: "Wisconsin" },
  { id: "Hudson", state_id: "Massachusetts" },
  { id: "Hudson", state_id: "Massachusetts" },
  { id: "Hudson", state_id: "New Hampshire" },
  { id: "Hudson", state_id: "New Hampshire" },
  { id: "Hudson", state_id: "Florida" },
  { id: "Hudson Falls", state_id: "New York" },
  { id: "Hudsonville", state_id: "Michigan" },
  { id: "Hueytown", state_id: "Alabama" },
  { id: "Hugo", state_id: "Minnesota" },
  { id: "Hull", state_id: "Massachusetts" },
  { id: "Hull", state_id: "Massachusetts" },
  { id: "Humble", state_id: "Texas" },
  { id: "Humboldt", state_id: "Tennessee" },
  { id: "Hunters Creek", state_id: "Florida" },
  { id: "Huntersville", state_id: "North Carolina" },
  { id: "Huntingdon", state_id: "Pennsylvania" },
  { id: "Huntington", state_id: "New York" },
  { id: "Huntington", state_id: "New York" },
  { id: "Huntington", state_id: "Virginia" },
  { id: "Huntington", state_id: "West Virginia" },
  { id: "Huntington", state_id: "Indiana" },
  { id: "Huntington Beach", state_id: "California" },
  { id: "Huntington Park", state_id: "California" },
  { id: "Huntington Station", state_id: "New York" },
  { id: "Huntington Woods", state_id: "Michigan" },
  { id: "Huntsville", state_id: "Alabama" },
  { id: "Huntsville", state_id: "Texas" },
  { id: "Hurley", state_id: "New York" },
  { id: "Huron", state_id: "Ohio" },
  { id: "Huron", state_id: "South Dakota" },
  { id: "Huron", state_id: "California" },
  { id: "Hurricane", state_id: "Utah" },
  { id: "Hurst", state_id: "Texas" },
  { id: "Hutchinson", state_id: "Kansas" },
  { id: "Hutchinson", state_id: "Minnesota" },
  { id: "Hyattsville", state_id: "Maryland" },
  { id: "Hybla Valley", state_id: "Virginia" },
  { id: "Hyde Park", state_id: "New York" },
  { id: "Hyrum", state_id: "Utah" },
  { id: "Idabel", state_id: "Oklahoma" },
  { id: "Idaho Falls", state_id: "Idaho" },
  { id: "Idylwood", state_id: "Virginia" },
  { id: "Ilion", state_id: "New York" },
  { id: "Immokalee", state_id: "Florida" },
  { id: "Imperial", state_id: "California" },
  { id: "Imperial Beach", state_id: "California" },
  { id: "Incline Village-Crystal Bay", state_id: "Nevada" },
  { id: "Independence", state_id: "Oregon" },
  { id: "Independence", state_id: "Missouri" },
  { id: "Independence", state_id: "Ohio" },
  { id: "Independence", state_id: "Iowa" },
  { id: "Independence", state_id: "Kansas" },
  { id: "Independence", state_id: "Kentucky" },
  { id: "Indian Harbour Beach", state_id: "Florida" },
  { id: "Indian Trail", state_id: "North Carolina" },
  { id: "Indiana", state_id: "Pennsylvania" },
  { id: "Indianapolis", state_id: "Indiana" },
  { id: "Indianola", state_id: "Mississippi" },
  { id: "Indianola", state_id: "Iowa" },
  { id: "Indio", state_id: "California" },
  { id: "Ingleside", state_id: "Texas" },
  { id: "Inglewood", state_id: "California" },
  { id: "Inglewood-Finn Hill", state_id: "Washington" },
  { id: "Inkster", state_id: "Michigan" },
  { id: "Interlaken", state_id: "California" },
  { id: "International Falls", state_id: "Minnesota" },
  { id: "Inver Grove Heights", state_id: "Minnesota" },
  { id: "Inverness", state_id: "Florida" },
  { id: "Inverness", state_id: "Illinois" },
  { id: "Inwood", state_id: "Florida" },
  { id: "Inwood", state_id: "New York" },
  { id: "Iola", state_id: "Kansas" },
  { id: "Iona", state_id: "Florida" },
  { id: "Ione", state_id: "California" },
  { id: "Ionia", state_id: "Michigan" },
  { id: "Iowa City", state_id: "Iowa" },
  { id: "Iowa Park", state_id: "Texas" },
  { id: "Ipswich", state_id: "Massachusetts" },
  { id: "Irmo", state_id: "South Carolina" },
  { id: "Iron Mountain", state_id: "Michigan" },
  { id: "Irondale", state_id: "Alabama" },
  { id: "Irondale", state_id: "Georgia" },
  { id: "Irondequoit", state_id: "New York" },
  { id: "Irondequoit", state_id: "New York" },
  { id: "Ironton", state_id: "Ohio" },
  { id: "Ironwood", state_id: "Michigan" },
  { id: "Irvine", state_id: "California" },
  { id: "Irving", state_id: "Texas" },
  { id: "Irvington", state_id: "New Jersey" },
  { id: "Irvington", state_id: "New York" },
  { id: "Iselin", state_id: "New Jersey" },
  { id: "Ishpeming", state_id: "Michigan" },
  { id: "Isla Vista", state_id: "California" },
  { id: "Islamorada", state_id: "Florida" },
  { id: "Island Lake", state_id: "Illinois" },
  { id: "Islip", state_id: "New York" },
  { id: "Islip", state_id: "New York" },
  { id: "Issaquah", state_id: "Washington" },
  { id: "Itasca", state_id: "Illinois" },
  { id: "Ithaca", state_id: "New York" },
  { id: "Ithaca", state_id: "New York" },
  { id: "Ives Estates", state_id: "Florida" },
  { id: "Jacinto City", state_id: "Texas" },
  { id: "Jackson", state_id: "Tennessee" },
  { id: "Jackson", state_id: "Ohio" },
  { id: "Jackson", state_id: "Wyoming" },
  { id: "Jackson", state_id: "Michigan" },
  { id: "Jackson", state_id: "Mississippi" },
  { id: "Jackson", state_id: "Missouri" },
  { id: "Lee's Summit", state_id: "Missouri" },
  { id: "Jacksonville", state_id: "Alabama" },
  { id: "Jacksonville", state_id: "Arkansas" },
  { id: "Jacksonville", state_id: "Florida" },
  { id: "Jacksonville", state_id: "Illinois" },
  { id: "Jacksonville", state_id: "North Carolina" },
  { id: "Jacksonville", state_id: "Texas" },
  { id: "Jacksonville Beach", state_id: "Florida" },
  { id: "Jamesburg", state_id: "New Jersey" },
  { id: "Jamestown", state_id: "North Dakota" },
  { id: "Jamestown", state_id: "New York" },
  { id: "Janesville", state_id: "Wisconsin" },
  { id: "Jasmine Estates", state_id: "Florida" },
  { id: "Jasper", state_id: "Alabama" },
  { id: "Jasper", state_id: "Indiana" },
  { id: "Jasper", state_id: "Texas" },
  { id: "Jeannette", state_id: "Pennsylvania" },
  { id: "Jefferson", state_id: "Wisconsin" },
  { id: "Jefferson", state_id: "Virginia" },
  { id: "Jefferson", state_id: "Louisiana" },
  { id: "Jefferson City", state_id: "Missouri" },
  { id: "Jefferson City", state_id: "Tennessee" },
  { id: "Jefferson Hills", state_id: "Pennsylvania" },
  { id: "Jefferson Valley-Yorktown", state_id: "New York" },
  { id: "Jeffersontown", state_id: "Kentucky" },
  { id: "Jeffersonville", state_id: "Indiana" },
  { id: "Jenison", state_id: "Michigan" },
  { id: "Jenks", state_id: "Oklahoma" },
  { id: "Jennings", state_id: "Louisiana" },
  { id: "Jennings", state_id: "Missouri" },
  { id: "Jennings Lodge", state_id: "Oregon" },
  { id: "Jensen Beach", state_id: "Florida" },
  { id: "Jericho", state_id: "New York" },
  { id: "Jerome", state_id: "Idaho" },
  { id: "Jersey City", state_id: "New Jersey" },
  { id: "Jersey Village", state_id: "Texas" },
  { id: "Jerseyville", state_id: "Illinois" },
  { id: "Jessup", state_id: "Maryland" },
  { id: "Jesup", state_id: "Georgia" },
  { id: "Johnson City", state_id: "New York" },
  { id: "Johnson City", state_id: "Tennessee" },
  { id: "Johnston", state_id: "Rhode Island" },
  { id: "Johnston", state_id: "Iowa" },
  { id: "Johnstown", state_id: "New York" },
  { id: "Johnstown", state_id: "New York" },
  { id: "Johnstown", state_id: "Pennsylvania" },
  { id: "Joliet", state_id: "Illinois" },
  { id: "Jollyville", state_id: "Texas" },
  { id: "Jonesboro", state_id: "Arkansas" },
  { id: "Joplin", state_id: "Missouri" },
  { id: "Joppatowne", state_id: "Maryland" },
  { id: "Junction City", state_id: "Kansas" },
  { id: "Juneau and", state_id: "Alaska" },
  { id: "Jupiter", state_id: "Florida" },
  { id: "Justice", state_id: "Illinois" },
  { id: "Kahului", state_id: "Hawaii" },
  { id: "Kailua", state_id: "Hawaii" },
  { id: "Kailua", state_id: "Hawaii" },
  { id: "Kalamazoo", state_id: "Michigan" },
  { id: "Kalaoa", state_id: "Hawaii" },
  { id: "Kalispell", state_id: "Montana" },
  { id: "Kaneohe", state_id: "Hawaii" },
  { id: "Kaneohe Station", state_id: "Hawaii" },
  { id: "Kankakee", state_id: "Illinois" },
  { id: "Kannapolis", state_id: "North Carolina" },
  { id: "Kansas City", state_id: "Missouri" },
  { id: "Kansas City", state_id: "Kansas" },
  { id: "Kapaa", state_id: "Hawaii" },
  { id: "Katy", state_id: "Texas" },
  { id: "Kaufman", state_id: "Texas" },
  { id: "Kaukauna", state_id: "Wisconsin" },
  { id: "Kaysville", state_id: "Utah" },
  { id: "Keansburg", state_id: "New Jersey" },
  { id: "Kearney", state_id: "Nebraska" },
  { id: "Kearns", state_id: "Utah" },
  { id: "Kearny", state_id: "New Jersey" },
  { id: "Keene", state_id: "New Hampshire" },
  { id: "Keizer", state_id: "Oregon" },
  { id: "Keller", state_id: "Texas" },
  { id: "Kelso", state_id: "Washington" },
  { id: "Kemp Mill", state_id: "Maryland" },
  { id: "Ken Caryl", state_id: "Colorado" },
  { id: "Kenai", state_id: "Alaska" },
  { id: "Kendale Lakes", state_id: "Florida" },
  { id: "Kendall", state_id: "Florida" },
  { id: "Kendall Park", state_id: "New Jersey" },
  { id: "Kendall West", state_id: "Florida" },
  { id: "Kendallville", state_id: "Indiana" },
  { id: "Kenilworth", state_id: "New Jersey" },
  { id: "Kenmore", state_id: "New York" },
  { id: "Kenmore", state_id: "Washington" },
  { id: "Kennebunk", state_id: "Maine" },
  { id: "Kennedy Township", state_id: "Pennsylvania" },
  { id: "Kenner", state_id: "Louisiana" },
  { id: "Kennesaw", state_id: "Georgia" },
  { id: "Kennett", state_id: "Missouri" },
  { id: "Kennewick", state_id: "Washington" },
  { id: "Kenosha", state_id: "Wisconsin" },
  { id: "Kensington", state_id: "Connecticut" },
  { id: "Kent", state_id: "Washington" },
  { id: "Kent", state_id: "Ohio" },
  { id: "Kent", state_id: "New York" },
  { id: "Kentfield", state_id: "California" },
  { id: "Kenton", state_id: "Ohio" },
  { id: "Kentwood", state_id: "Michigan" },
  { id: "Kenwood", state_id: "Ohio" },
  { id: "Keokuk", state_id: "Iowa" },
  { id: "Kerman", state_id: "California" },
  { id: "Kernersville", state_id: "North Carolina" },
  { id: "Kerrville", state_id: "Texas" },
  { id: "Ketchikan", state_id: "Alaska" },
  { id: "Kettering", state_id: "Maryland" },
  { id: "Kettering", state_id: "Ohio" },
  { id: "Kewanee", state_id: "Illinois" },
  { id: "Key Biscayne", state_id: "Florida" },
  { id: "Key Largo", state_id: "Florida" },
  { id: "Key West", state_id: "Florida" },
  { id: "Keyport", state_id: "New Jersey" },
  { id: "Keystone", state_id: "Florida" },
  { id: "Kihei", state_id: "Hawaii" },
  { id: "Kilgore", state_id: "Texas" },
  { id: "Killeen", state_id: "Texas" },
  { id: "Killingly", state_id: "Connecticut" },
  { id: "Killingworth", state_id: "Connecticut" },
  { id: "Kimberly", state_id: "Wisconsin" },
  { id: "Kinderhook", state_id: "New York" },
  { id: "King City", state_id: "California" },
  { id: "King of Prussia", state_id: "Pennsylvania" },
  { id: "Kingman", state_id: "Arizona" },
  { id: "Kings Grant", state_id: "North Carolina" },
  { id: "Kings Mountain", state_id: "North Carolina" },
  { id: "Kings Park", state_id: "New York" },
  { id: "Kings Point", state_id: "Florida" },
  { id: "Kingsburg", state_id: "California" },
  { id: "Kingsbury", state_id: "New York" },
  { id: "Kingsgate", state_id: "Washington" },
  { id: "Kingsland", state_id: "Georgia" },
  { id: "Kingsport", state_id: "Tennessee" },
  { id: "Kingston", state_id: "Pennsylvania" },
  { id: "Kingston", state_id: "New York" },
  { id: "Kingston", state_id: "Massachusetts" },
  { id: "Kingsville", state_id: "Texas" },
  { id: "Kinnelon", state_id: "New Jersey" },
  { id: "Kinston", state_id: "North Carolina" },
  { id: "Kirby", state_id: "Texas" },
  { id: "Kirkland", state_id: "Washington" },
  { id: "Kirkland", state_id: "New York" },
  { id: "Kirksville", state_id: "Missouri" },
  { id: "Kirkwood", state_id: "Missouri" },
  { id: "Kirtland", state_id: "New Mexico" },
  { id: "Kirtland", state_id: "Ohio" },
  { id: "Kiryas Joel", state_id: "New York" },
  { id: "Kissimmee", state_id: "Florida" },
  { id: "Kittery", state_id: "Maine" },
  { id: "Klamath Falls", state_id: "Oregon" },
  { id: "Knik-Fairview", state_id: "Alaska" },
  { id: "Knoxville", state_id: "Iowa" },
  { id: "Knoxville", state_id: "Tennessee" },
  { id: "Kodiak", state_id: "Alaska" },
  { id: "Kokomo", state_id: "Indiana" },
  { id: "Kosciusko", state_id: "Mississippi" },
  { id: "Kulpsville", state_id: "Pennsylvania" },
  { id: "La Canada Flintridge", state_id: "California" },
  { id: "La Crescenta-Montrose", state_id: "California" },
  { id: "La Crosse", state_id: "Wisconsin" },
  { id: "La Fayette", state_id: "Georgia" },
  { id: "La Feria", state_id: "Texas" },
  { id: "La Follette", state_id: "Tennessee" },
  { id: "La Grande", state_id: "Oregon" },
  { id: "La Grange", state_id: "New York" },
  { id: "La Grange", state_id: "Illinois" },
  { id: "La Grange Park", state_id: "Illinois" },
  { id: "La Habra", state_id: "California" },
  { id: "La Homa", state_id: "Texas" },
  { id: "La Junta", state_id: "Colorado" },
  { id: "La Marque", state_id: "Texas" },
  { id: "La Mesa", state_id: "California" },
  { id: "La Mirada", state_id: "California" },
  { id: "La Palma", state_id: "California" },
  { id: "La Plata", state_id: "Maryland" },
  { id: "La Porte", state_id: "Indiana" },
  { id: "La Porte", state_id: "Texas" },
  { id: "La Presa", state_id: "California" },
  { id: "La Puente", state_id: "California" },
  { id: "La Quinta", state_id: "California" },
  { id: "La Riviera", state_id: "California" },
  { id: "La Salle", state_id: "Illinois" },
  { id: "La Vergne", state_id: "Tennessee" },
  { id: "La Verne", state_id: "California" },
  { id: "La Vista", state_id: "Nebraska" },
  { id: "Lacey", state_id: "Washington" },
  { id: "Lackawanna", state_id: "New York" },
  { id: "Lackland AFB", state_id: "Texas" },
  { id: "Lacombe", state_id: "Louisiana" },
  { id: "Laconia", state_id: "New Hampshire" },
  { id: "Ladera Heights", state_id: "California" },
  { id: "Ladson", state_id: "South Carolina" },
  { id: "Ladue", state_id: "Missouri" },
  { id: "Lady Lake", state_id: "Florida" },
  { id: "Lafayette", state_id: "Colorado" },
  { id: "Lafayette", state_id: "Louisiana" },
  { id: "Lafayette", state_id: "Indiana" },
  { id: "Lafayette", state_id: "California" },
  { id: "LaGrange", state_id: "Georgia" },
  { id: "Laguna", state_id: "California" },
  { id: "Laguna Beach", state_id: "California" },
  { id: "Laguna Hills", state_id: "California" },
  { id: "Laguna Niguel", state_id: "California" },
  { id: "Laguna West-Lakeside", state_id: "California" },
  { id: "Laguna Woods", state_id: "California" },
  { id: "Lahaina", state_id: "Hawaii" },
  { id: "Lake Arbor", state_id: "Maryland" },
  { id: "Lake Arrowhead", state_id: "California" },
  { id: "Lake Barcroft", state_id: "Virginia" },
  { id: "Lake Bluff", state_id: "Illinois" },
  { id: "Lake Butter", state_id: "Florida" },
  { id: "Lake Carmel", state_id: "New York" },
  { id: "Lake Charles", state_id: "Louisiana" },
  { id: "Lake City", state_id: "Florida" },
  { id: "Lake City", state_id: "South Carolina" },
  { id: "Lake Dallas", state_id: "Texas" },
  { id: "Lake Elmo", state_id: "Minnesota" },
  { id: "Lake Elsinore", state_id: "California" },
  { id: "Lake Forest", state_id: "California" },
  { id: "Lake Forest", state_id: "Illinois" },
  { id: "Lake Forest Park", state_id: "Washington" },
  { id: "Lake Geneva", state_id: "Wisconsin" },
  { id: "Lake Grove", state_id: "New York" },
  { id: "Lake Havasu City", state_id: "Arizona" },
  { id: "Lake in the Hills", state_id: "Illinois" },
  { id: "Lake Jackson", state_id: "Texas" },
  { id: "Lake Lorraine", state_id: "Florida" },
  { id: "Lake Los Angeles", state_id: "California" },
  { id: "Lake Lucerne", state_id: "Florida" },
  { id: "Lake Magdalene", state_id: "Florida" },
  { id: "Lake Mary", state_id: "Florida" },
  { id: "Lake Mohawk", state_id: "New Jersey" },
  { id: "Lake Monticello", state_id: "Virginia" },
  { id: "Lake Morton-Berrydale", state_id: "Washington" },
  { id: "Lake Oswego", state_id: "Oregon" },
  { id: "Lake Park", state_id: "Florida" },
  { id: "Lake Ridge", state_id: "Virginia" },
  { id: "Lake Ronkonkoma", state_id: "New York" },
  { id: "Lake Shore", state_id: "Maryland" },
  { id: "Lake Shore", state_id: "Washington" },
  { id: "Lake St. Louis", state_id: "Missouri" },
  { id: "Lake Station", state_id: "Indiana" },
  { id: "Lake Stevens", state_id: "Washington" },
  { id: "Lake Wales", state_id: "Florida" },
  { id: "Lake Worth", state_id: "Florida" },
  { id: "Lake Worth Corridor", state_id: "Florida" },
  { id: "Lake Zurich", state_id: "Illinois" },
  { id: "Lakeland", state_id: "Florida" },
  { id: "Lakeland", state_id: "Tennessee" },
  { id: "Lakeland Highlands", state_id: "Florida" },
  { id: "Lakeland North", state_id: "Washington" },
  { id: "Lakeland South", state_id: "Washington" },
  { id: "Lakes", state_id: "Alaska" },
  { id: "Lakes by the Bay", state_id: "Florida" },
  { id: "Lakes of the Four Seasons", state_id: "Indiana" },
  { id: "Lakeside", state_id: "Florida" },
  { id: "Lakeside", state_id: "California" },
  { id: "Lakeside", state_id: "Virginia" },
  { id: "Lakeville", state_id: "Minnesota" },
  { id: "Lakeville", state_id: "Massachusetts" },
  { id: "Lakeway", state_id: "Texas" },
  { id: "Lakewood", state_id: "Ohio" },
  { id: "Lakewood", state_id: "New Jersey" },
  { id: "Lakewood", state_id: "California" },
  { id: "Lakewood", state_id: "Colorado" },
  { id: "Lakewood", state_id: "Washington" },
  { id: "Lakewood Park", state_id: "Florida" },
  { id: "Lamar", state_id: "Colorado" },
  { id: "Lambertville", state_id: "Michigan" },
  { id: "Lamesa", state_id: "Texas" },
  { id: "Lamont", state_id: "California" },
  { id: "Lampasas", state_id: "Texas" },
  { id: "Lancaster", state_id: "Texas" },
  { id: "Lancaster", state_id: "South Carolina" },
  { id: "Lancaster", state_id: "Pennsylvania" },
  { id: "Lancaster", state_id: "Ohio" },
  { id: "Lancaster", state_id: "New York" },
  { id: "Lancaster", state_id: "Massachusetts" },
  { id: "Lancaster", state_id: "New York" },
  { id: "Lancaster", state_id: "California" },
  { id: "Land O’ Lakes", state_id: "Florida" },
  { id: "Landen", state_id: "Ohio" },
  { id: "Lander", state_id: "Wyoming" },
  { id: "Lanett", state_id: "Alabama" },
  { id: "Langley Park", state_id: "Maryland" },
  { id: "Lanham-Seabrook", state_id: "Maryland" },
  { id: "Lansdale", state_id: "Pennsylvania" },
  { id: "Lansdowne", state_id: "Pennsylvania" },
  { id: "Lansdowne-Baltimore Highlands", state_id: "Maryland" },
  { id: "Lansing", state_id: "Kansas" },
  { id: "Lansing", state_id: "Illinois" },
  { id: "Lansing", state_id: "New York" },
  { id: "Lansing", state_id: "Michigan" },
  { id: "Lantana", state_id: "Florida" },
  { id: "Lapeer", state_id: "Michigan" },
  { id: "Laplace", state_id: "Louisiana" },
  { id: "Laramie", state_id: "Wyoming" },
  { id: "Larchmont", state_id: "New York" },
  { id: "Laredo", state_id: "Texas" },
  { id: "Largo", state_id: "Maryland" },
  { id: "Largo", state_id: "Florida" },
  { id: "Larkfield-Wikiup", state_id: "California" },
  { id: "Larkspur", state_id: "California" },
  { id: "Larose", state_id: "Louisiana" },
  { id: "Las Cruces", state_id: "New Mexico" },
  { id: "Las Vegas", state_id: "New Mexico" },
  { id: "Las Vegas", state_id: "Nevada" },
  { id: "Lathrop", state_id: "California" },
  { id: "Latrobe", state_id: "Pennsylvania" },
  { id: "Lauderdale Lakes", state_id: "Florida" },
  { id: "Lauderhill", state_id: "Florida" },
  { id: "Laughlin", state_id: "Nevada" },
  { id: "Laurel", state_id: "Mississippi" },
  { id: "Laurel", state_id: "Montana" },
  { id: "Laurel", state_id: "Florida" },
  { id: "Laurel", state_id: "Maryland" },
  { id: "Laurel", state_id: "Virginia" },
  { id: "Laurel Bay", state_id: "South Carolina" },
  { id: "Laurence Harbor", state_id: "New Jersey" },
  { id: "Laurens", state_id: "South Carolina" },
  { id: "Laurinburg", state_id: "North Carolina" },
  { id: "Lawndale", state_id: "California" },
  { id: "Lawrence", state_id: "Kansas" },
  { id: "Lawrence", state_id: "Indiana" },
  { id: "Lawrence", state_id: "New York" },
  { id: "Lawrence", state_id: "Massachusetts" },
  { id: "Lawrenceburg", state_id: "Tennessee" },
  { id: "Lawrenceburg", state_id: "Kentucky" },
  { id: "Lawrenceville", state_id: "Georgia" },
  { id: "Lawton", state_id: "Oklahoma" },
  { id: "Layton", state_id: "Utah" },
  { id: "Le Mars", state_id: "Iowa" },
  { id: "Le Ray", state_id: "New York" },
  { id: "Le Roy", state_id: "New York" },
  { id: "Lea Hill", state_id: "Washington" },
  { id: "Leacock-Leola-Bareville", state_id: "Pennsylvania" },
  { id: "League City", state_id: "Texas" },
  { id: "Leander", state_id: "Texas" },
  { id: "Leavenworth", state_id: "Kansas" },
  { id: "Leawood", state_id: "Kansas" },
  { id: "Lebanon", state_id: "Indiana" },
  { id: "Lebanon", state_id: "Connecticut" },
  { id: "Lebanon", state_id: "Pennsylvania" },
  { id: "Lebanon", state_id: "Tennessee" },
  { id: "Lebanon", state_id: "Oregon" },
  { id: "Lebanon", state_id: "Ohio" },
  { id: "Lebanon", state_id: "New Hampshire" },
  { id: "Lebanon", state_id: "Missouri" },
  { id: "Ledyard", state_id: "Connecticut" },
  { id: "Lee", state_id: "New York" },
  { id: "Lee’s Summit", state_id: "Missouri" },
  { id: "Leeds", state_id: "Alabama" },
  { id: "Leesburg", state_id: "Florida" },
  { id: "Leesburg", state_id: "Virginia" },
  { id: "Leesville", state_id: "Louisiana" },
  { id: "Lehi", state_id: "Utah" },
  { id: "Lehigh Acres", state_id: "Florida" },
  { id: "Leicester", state_id: "Massachusetts" },
  { id: "Leisure City", state_id: "Florida" },
  { id: "Leisure Village West-Pine Lake Park", state_id: "New Jersey" },
  { id: "Leitchfield", state_id: "Kentucky" },
  { id: "Lemay", state_id: "Missouri" },
  { id: "Lemmon Valley-Golden Valley", state_id: "Nevada" },
  { id: "Lemon Grove", state_id: "California" },
  { id: "Lemont", state_id: "Illinois" },
  { id: "Lemoore", state_id: "California" },
  { id: "Lenexa", state_id: "Kansas" },
  { id: "Lennox", state_id: "California" },
  { id: "Lenoir", state_id: "North Carolina" },
  { id: "Lenoir City", state_id: "Tennessee" },
  { id: "Lenox", state_id: "New York" },
  { id: "Leominster", state_id: "Massachusetts" },
  { id: "Leon Valley", state_id: "Texas" },
  { id: "Leonia", state_id: "New Jersey" },
  { id: "Levelland", state_id: "Texas" },
  { id: "Levittown", state_id: "Pennsylvania" },
  { id: "Levittown", state_id: "New York" },
  { id: "Lewisboro", state_id: "New York" },
  { id: "Lewisburg", state_id: "Tennessee" },
  { id: "Lewiston", state_id: "Maine" },
  { id: "Lewiston", state_id: "New York" },
  { id: "Lewiston", state_id: "Idaho" },
  { id: "Lewistown", state_id: "Pennsylvania" },
  { id: "Lewisville", state_id: "North Carolina" },
  { id: "Lewisville", state_id: "Texas" },
  { id: "Lexington", state_id: "Virginia" },
  { id: "Lexington", state_id: "North Carolina" },
  { id: "Lexington", state_id: "South Carolina" },
  { id: "Lexington", state_id: "Tennessee" },
  { id: "Lexington", state_id: "Massachusetts" },
  { id: "Lexington", state_id: "Nebraska" },
  { id: "Lexington", state_id: "Massachusetts" },
  { id: "Lexington Park", state_id: "Maryland" },
  { id: "Lexington-Fayette", state_id: "Kentucky" },
  { id: "Liberal", state_id: "Kansas" },
  { id: "Liberty", state_id: "Missouri" },
  { id: "Liberty", state_id: "New York" },
  { id: "Liberty", state_id: "Texas" },
  { id: "Libertyville", state_id: "Illinois" },
  { id: "Lighthouse Point", state_id: "Florida" },
  { id: "Lilburn", state_id: "Georgia" },
  { id: "Lima", state_id: "Ohio" },
  { id: "Lincoln", state_id: "Rhode Island" },
  { id: "Lincoln", state_id: "Nebraska" },
  { id: "Lincoln", state_id: "Massachusetts" },
  { id: "Lincoln", state_id: "Illinois" },
  { id: "Lincoln", state_id: "California" },
  { id: "Lincoln City", state_id: "Oregon" },
  { id: "Lincoln Park", state_id: "Michigan" },
  { id: "Lincoln Park", state_id: "New Jersey" },
  { id: "Lincoln Village", state_id: "Ohio" },
  { id: "Lincolnia", state_id: "Virginia" },
  { id: "Lincolnshire", state_id: "Illinois" },
  { id: "Lincolnton", state_id: "North Carolina" },
  { id: "Lincolnwood", state_id: "Illinois" },
  { id: "Lincroft", state_id: "New Jersey" },
  { id: "Linda", state_id: "California" },
  { id: "Linden", state_id: "New Jersey" },
  { id: "Lindenhurst", state_id: "New York" },
  { id: "Lindenhurst", state_id: "Illinois" },
  { id: "Lindenwold", state_id: "New Jersey" },
  { id: "Lindon", state_id: "Utah" },
  { id: "Lindsay", state_id: "California" },
  { id: "Linganore-Bartonsville", state_id: "Maryland" },
  { id: "Linglestown", state_id: "Pennsylvania" },
  { id: "Lino Lakes", state_id: "Minnesota" },
  { id: "Linthicum", state_id: "Maryland" },
  { id: "Linton Hall", state_id: "Virginia" },
  { id: "Linwood", state_id: "New Jersey" },
  { id: "Lionville-Marchwood", state_id: "Pennsylvania" },
  { id: "Lisbon", state_id: "Maine" },
  { id: "Lisbon", state_id: "Wisconsin" },
  { id: "Lisle", state_id: "Illinois" },
  { id: "Litchfield", state_id: "Illinois" },
  { id: "Litchfield", state_id: "Connecticut" },
  { id: "Litchfield", state_id: "Minnesota" },
  { id: "Litchfield", state_id: "New Hampshire" },
  { id: "Lititz", state_id: "Pennsylvania" },
  { id: "Little Canada", state_id: "Minnesota" },
  { id: "Little Chute", state_id: "Wisconsin" },
  { id: "Little Cottonwood Creek Valley", state_id: "Utah" },
  { id: "Little Falls", state_id: "Minnesota" },
  { id: "Little Falls", state_id: "New Jersey" },
  { id: "Little Ferry", state_id: "New Jersey" },
  { id: "Little River", state_id: "South Carolina" },
  { id: "Little Rock", state_id: "Arkansas" },
  { id: "Little Silver", state_id: "New Jersey" },
  { id: "Littlefield", state_id: "Texas" },
  { id: "Littleton", state_id: "Massachusetts" },
  { id: "Littleton", state_id: "Colorado" },
  { id: "Live Oak", state_id: "Florida" },
  { id: "Live Oak", state_id: "California" },
  { id: "Live Oak", state_id: "California" },
  { id: "Live Oak", state_id: "Texas" },
  { id: "Livermore", state_id: "California" },
  { id: "Livingston", state_id: "California" },
  { id: "Livingston", state_id: "Montana" },
  { id: "Livingston", state_id: "New Jersey" },
  { id: "Livonia", state_id: "New York" },
  { id: "Livonia", state_id: "Michigan" },
  { id: "Lloyd", state_id: "New York" },
  { id: "Lochearn", state_id: "Maryland" },
  { id: "Lock Haven", state_id: "Pennsylvania" },
  { id: "Lockhart", state_id: "Florida" },
  { id: "Lockhart", state_id: "Texas" },
  { id: "Lockport", state_id: "Illinois" },
  { id: "Lockport", state_id: "New York" },
  { id: "Lockport", state_id: "New York" },
  { id: "Lodi", state_id: "New Jersey" },
  { id: "Lodi", state_id: "California" },
  { id: "Logan", state_id: "Ohio" },
  { id: "Logan", state_id: "Utah" },
  { id: "Logansport", state_id: "Indiana" },
  { id: "Loma Linda", state_id: "California" },
  { id: "Lombard", state_id: "Illinois" },
  { id: "Lomita", state_id: "California" },
  { id: "Lompoc", state_id: "California" },
  { id: "London", state_id: "Ohio" },
  { id: "Londonderry", state_id: "New Hampshire" },
  { id: "Londonderry", state_id: "New Hampshire" },
  { id: "Londontowne", state_id: "Maryland" },
  { id: "Long Beach", state_id: "California" },
  { id: "Long Beach", state_id: "Mississippi" },
  { id: "Long Beach", state_id: "New York" },
  { id: "Long Branch", state_id: "New Jersey" },
  { id: "Long Grove", state_id: "Illinois" },
  { id: "Longboat Key", state_id: "Florida" },
  { id: "Longmeadow", state_id: "Massachusetts" },
  { id: "Longmeadow", state_id: "Massachusetts" },
  { id: "Longmont", state_id: "Colorado" },
  { id: "Longview", state_id: "Washington" },
  { id: "Longview", state_id: "Texas" },
  { id: "Longwood", state_id: "Florida" },
  { id: "Loomis", state_id: "California" },
  { id: "Lorain", state_id: "Ohio" },
  { id: "Lorton", state_id: "Virginia" },
  { id: "Los Alamitos", state_id: "California" },
  { id: "Los Alamos", state_id: "New Mexico" },
  { id: "Los Altos", state_id: "California" },
  { id: "Los Altos Hills", state_id: "California" },
  { id: "Los Angeles", state_id: "California" },
  { id: "Los Banos", state_id: "California" },
  { id: "Los Gatos", state_id: "California" },
  { id: "Los Lunas", state_id: "New Mexico" },
  { id: "Louisville", state_id: "Mississippi" },
  { id: "Louisville", state_id: "Ohio" },
  { id: "Louisville", state_id: "Colorado" },
  { id: "Louisville", state_id: "Kentucky" },
  { id: "Loveland", state_id: "Colorado" },
  { id: "Loveland", state_id: "Ohio" },
  { id: "Loves Park", state_id: "Illinois" },
  { id: "Lovington", state_id: "New Mexico" },
  { id: "Lowell", state_id: "Massachusetts" },
  { id: "Lowell", state_id: "Indiana" },
  { id: "Lower Allen", state_id: "Pennsylvania" },
  { id: "Lower Burrell", state_id: "Pennsylvania" },
  { id: "Lubbock", state_id: "Texas" },
  { id: "Lucas Valley-Marinwood", state_id: "California" },
  { id: "Ludington", state_id: "Michigan" },
  { id: "Ludlow", state_id: "Massachusetts" },
  { id: "Lufkin", state_id: "Texas" },
  { id: "Lugoff", state_id: "South Carolina" },
  { id: "Luling", state_id: "Louisiana" },
  { id: "Lumberton", state_id: "North Carolina" },
  { id: "Lumberton", state_id: "Texas" },
  { id: "Lunenburg", state_id: "Massachusetts" },
  { id: "Lutherville-Timonium", state_id: "Maryland" },
  { id: "Lutz", state_id: "Florida" },
  { id: "Lynbrook", state_id: "New York" },
  { id: "Lynchburg", state_id: "Virginia" },
  { id: "Lynden", state_id: "Washington" },
  { id: "Lyndhurst", state_id: "Ohio" },
  { id: "Lyndhurst", state_id: "New Jersey" },
  { id: "Lyndon", state_id: "Kentucky" },
  { id: "Lynn", state_id: "Massachusetts" },
  { id: "Lynn Haven", state_id: "Florida" },
  { id: "Lynnfield", state_id: "Massachusetts" },
  { id: "Lynnfield", state_id: "Massachusetts" },
  { id: "Lynnwood", state_id: "Washington" },
  { id: "Lynwood", state_id: "Illinois" },
  { id: "Lynwood", state_id: "California" },
  { id: "Lyons", state_id: "Illinois" },
  { id: "Lysander", state_id: "New York" },
  { id: "Mableton", state_id: "Georgia" },
  { id: "Macedon", state_id: "New York" },
  { id: "Macedonia", state_id: "Ohio" },
  { id: "Machesney Park", state_id: "Illinois" },
  { id: "Macomb", state_id: "Illinois" },
  { id: "Macon", state_id: "Georgia" },
  { id: "Madeira", state_id: "Ohio" },
  { id: "Madera", state_id: "California" },
  { id: "Madera Acres", state_id: "California" },
  { id: "Madison", state_id: "Alabama" },
  { id: "Madison", state_id: "Indiana" },
  { id: "Madison", state_id: "Connecticut" },
  { id: "Madison", state_id: "New Jersey" },
  { id: "Madison", state_id: "Mississippi" },
  { id: "Madison", state_id: "South Dakota" },
  { id: "Madison", state_id: "Wisconsin" },
  { id: "Madison", state_id: "Wisconsin" },
  { id: "Madison Heights", state_id: "Virginia" },
  { id: "Madison Heights", state_id: "Michigan" },
  { id: "Madison Park", state_id: "New Jersey" },
  { id: "Madisonville", state_id: "Kentucky" },
  { id: "Magalia", state_id: "California" },
  { id: "Magna", state_id: "Utah" },
  { id: "Magnolia", state_id: "Arkansas" },
  { id: "Mahopac", state_id: "New York" },
  { id: "Mahtomedi", state_id: "Minnesota" },
  { id: "Maitland", state_id: "Florida" },
  { id: "Makaha", state_id: "Hawaii" },
  { id: "Makakilo City", state_id: "Hawaii" },
  { id: "Makawao", state_id: "Hawaii" },
  { id: "Malden", state_id: "Massachusetts" },
  { id: "Malibu", state_id: "California" },
  { id: "Malone", state_id: "New York" },
  { id: "Malone", state_id: "New York" },
  { id: "Malta", state_id: "New York" },
  { id: "Maltby", state_id: "Washington" },
  { id: "Malvern", state_id: "Arkansas" },
  { id: "Malverne", state_id: "New York" },
  { id: "Mamakating", state_id: "New York" },
  { id: "Mamaroneck", state_id: "New York" },
  { id: "Mamaroneck", state_id: "New York" },
  { id: "Mammoth Lakes", state_id: "California" },
  { id: "Manasquan", state_id: "New Jersey" },
  { id: "Manassas", state_id: "Virginia" },
  { id: "Manassas Park", state_id: "Virginia" },
  { id: "Manchester", state_id: "Tennessee" },
  { id: "Manchester", state_id: "New Hampshire" },
  { id: "Manchester", state_id: "New York" },
  { id: "Manchester", state_id: "Missouri" },
  { id: "Manchester", state_id: "Connecticut" },
  { id: "Mandan", state_id: "North Dakota" },
  { id: "Mandeville", state_id: "Louisiana" },
  { id: "Mango", state_id: "Florida" },
  { id: "Madison", state_id: "Florida" },
  { id: "Manhasset", state_id: "New York" },
  { id: "Manhattan", state_id: "Kansas" },
  { id: "Manhattan Beach", state_id: "California" },
  { id: "Manistee", state_id: "Michigan" },
  { id: "Manitowoc", state_id: "Wisconsin" },
  { id: "Mankato", state_id: "Minnesota" },
  { id: "Manlius", state_id: "New York" },
  { id: "Manorhaven", state_id: "New York" },
  { id: "Manorville", state_id: "New York" },
  { id: "Mansfield", state_id: "Massachusetts" },
  { id: "Mansfield", state_id: "Connecticut" },
  { id: "Mansfield", state_id: "Texas" },
  { id: "Mansfield", state_id: "Ohio" },
  { id: "Mansfield Center", state_id: "Massachusetts" },
  { id: "Manteca", state_id: "California" },
  { id: "Manteno", state_id: "Illinois" },
  { id: "Mantua", state_id: "Virginia" },
  { id: "Manville", state_id: "New Jersey" },
  { id: "Maple Glen", state_id: "Pennsylvania" },
  { id: "Maple Grove", state_id: "Minnesota" },
  { id: "Maple Heights", state_id: "Ohio" },
  { id: "Maple Valley", state_id: "Washington" },
  { id: "Maplewood", state_id: "Minnesota" },
  { id: "Maplewood", state_id: "Missouri" },
  { id: "Maplewood", state_id: "New Jersey" },
  { id: "Maquoketa", state_id: "Iowa" },
  { id: "Marana", state_id: "Arizona" },
  { id: "Marathon", state_id: "Florida" },
  { id: "Marblehead", state_id: "Massachusetts" },
  { id: "Marblehead", state_id: "Massachusetts" },
  { id: "Marcellus", state_id: "New York" },
  { id: "Marco Island", state_id: "Florida" },
  { id: "Marcy", state_id: "New York" },
  { id: "Marengo", state_id: "Illinois" },
  { id: "Margate", state_id: "Florida" },
  { id: "Margate City", state_id: "New Jersey" },
  { id: "Marianna", state_id: "Florida" },
  { id: "Marietta", state_id: "Georgia" },
  { id: "Marietta", state_id: "Ohio" },
  { id: "Marina", state_id: "California" },
  { id: "Marina del Rey", state_id: "California" },
  { id: "Marinette", state_id: "Wisconsin" },
  { id: "Marion", state_id: "Virginia" },
  { id: "Marion", state_id: "Ohio" },
  { id: "Marion", state_id: "South Carolina" },
  { id: "Marion", state_id: "Arkansas" },
  { id: "Marion", state_id: "Indiana" },
  { id: "Marion", state_id: "Iowa" },
  { id: "Marion", state_id: "Illinois" },
  { id: "Markham", state_id: "Illinois" },
  { id: "Marlborough", state_id: "New York" },
  { id: "Marlborough", state_id: "Massachusetts" },
  { id: "Marlin", state_id: "Texas" },
  { id: "Marlow Heights", state_id: "Maryland" },
  { id: "Marlton", state_id: "Maryland" },
  { id: "Marlton", state_id: "New Jersey" },
  { id: "Marquette", state_id: "Michigan" },
  { id: "Marrero", state_id: "Louisiana" },
  { id: "Marshall", state_id: "Michigan" },
  { id: "Marshall", state_id: "Minnesota" },
  { id: "Marshall", state_id: "Missouri" },
  { id: "Marshall", state_id: "Texas" },
  { id: "Marshalltown", state_id: "Iowa" },
  { id: "Marshfield", state_id: "Massachusetts" },
  { id: "Marshfield", state_id: "Wisconsin" },
  { id: "Martha Lake", state_id: "Washington" },
  { id: "Martin", state_id: "Tennessee" },
  { id: "Martinez", state_id: "Georgia" },
  { id: "Martinez", state_id: "California" },
  { id: "Martins Ferry", state_id: "Ohio" },
  { id: "Martinsburg", state_id: "West Virginia" },
  { id: "Martinsville", state_id: "Virginia" },
  { id: "Martinsville", state_id: "Indiana" },
  { id: "Maryland City", state_id: "Maryland" },
  { id: "Maryland Heights", state_id: "Missouri" },
  { id: "Marysville", state_id: "Michigan" },
  { id: "Marysville", state_id: "California" },
  { id: "Marysville", state_id: "Washington" },
  { id: "Marysville", state_id: "Ohio" },
  { id: "Maryville", state_id: "Tennessee" },
  { id: "Maryville", state_id: "Missouri" },
  { id: "Mashpee", state_id: "Massachusetts" },
  { id: "Mason", state_id: "Michigan" },
  { id: "Mason", state_id: "Ohio" },
  { id: "Mason City", state_id: "Iowa" },
  { id: "Masonboro", state_id: "North Carolina" },
  { id: "Massapequa", state_id: "New York" },
  { id: "Massapequa Park", state_id: "New York" },
  { id: "Massena", state_id: "New York" },
  { id: "Massena", state_id: "New York" },
  { id: "Massillon", state_id: "Ohio" },
  { id: "Mastic", state_id: "New York" },
  { id: "Mastic Beach", state_id: "New York" },
  { id: "Matawan", state_id: "New Jersey" },
  { id: "Mattapoisett", state_id: "Massachusetts" },
  { id: "Matteson", state_id: "Illinois" },
  { id: "Matthews", state_id: "North Carolina" },
  { id: "Mattoon", state_id: "Illinois" },
  { id: "Mattydale", state_id: "New York" },
  { id: "Mauldin", state_id: "South Carolina" },
  { id: "Maumee", state_id: "Ohio" },
  { id: "Maumelle", state_id: "Arkansas" },
  { id: "Mayfield", state_id: "Kentucky" },
  { id: "Mayfield", state_id: "New York" },
  { id: "Mayfield Heights", state_id: "Ohio" },
  { id: "Maynard", state_id: "Massachusetts" },
  { id: "Maynard", state_id: "Massachusetts" },
  { id: "Mays Chapel", state_id: "Maryland" },
  { id: "Maysville", state_id: "Kentucky" },
  { id: "Maywood", state_id: "Illinois" },
  { id: "Maywood", state_id: "California" },
  { id: "Maywood", state_id: "New Jersey" },
  { id: "McAlester", state_id: "Oklahoma" },
  { id: "McAllen", state_id: "Texas" },
  { id: "McCandless Township", state_id: "Pennsylvania" },
  { id: "McComb", state_id: "Mississippi" },
  { id: "McCook", state_id: "Nebraska" },
  { id: "McDonough", state_id: "Georgia" },
  { id: "McFarland", state_id: "California" },
  { id: "McFarland", state_id: "Wisconsin" },
  { id: "McGregor", state_id: "Florida" },
  { id: "McGuire AFB", state_id: "New Jersey" },
  { id: "McHenry", state_id: "Illinois" },
  { id: "McKees Rocks", state_id: "Pennsylvania" },
  { id: "McKeesport", state_id: "Pennsylvania" },
  { id: "McKinleyville", state_id: "California" },
  { id: "McKinney", state_id: "Texas" },
  { id: "McLean", state_id: "Virginia" },
  { id: "McMinnville", state_id: "Tennessee" },
  { id: "McMinnville", state_id: "Oregon" },
  { id: "McPherson", state_id: "Kansas" },
  { id: "Meadow Woods", state_id: "Florida" },
  { id: "Meadville", state_id: "Pennsylvania" },
  { id: "Mebane", state_id: "North Carolina" },
  { id: "Mechanicsburg", state_id: "Pennsylvania" },
  { id: "Mechanicstown", state_id: "New York" },
  { id: "Mechanicsville", state_id: "Virginia" },
  { id: "Medfield", state_id: "Massachusetts" },
  { id: "Medfield", state_id: "Massachusetts" },
  { id: "Medford", state_id: "Massachusetts" },
  { id: "Medford", state_id: "New York" },
  { id: "Medford", state_id: "Oregon" },
  { id: "Medina", state_id: "Ohio" },
  { id: "Medina", state_id: "New York" },
  { id: "Medulla", state_id: "Florida" },
  { id: "Medway", state_id: "Massachusetts" },
  { id: "Mehlville", state_id: "Missouri" },
  { id: "Melbourne", state_id: "Florida" },
  { id: "Melrose", state_id: "Massachusetts" },
  { id: "Melrose Park", state_id: "Florida" },
  { id: "Melrose Park", state_id: "Illinois" },
  { id: "Melville", state_id: "New York" },
  { id: "Melvindale", state_id: "Michigan" },
  { id: "Memphis", state_id: "Florida" },
  { id: "Memphis", state_id: "Tennessee" },
  { id: "Menasha", state_id: "Wisconsin" },
  { id: "Menasha", state_id: "Wisconsin" },
  { id: "Mendon", state_id: "New York" },
  { id: "Mendota", state_id: "California" },
  { id: "Mendota", state_id: "Illinois" },
  { id: "Mendota Heights", state_id: "Minnesota" },
  { id: "Menlo Park", state_id: "California" },
  { id: "Menominee", state_id: "Michigan" },
  { id: "Menomonee Falls", state_id: "Wisconsin" },
  { id: "Menomonie", state_id: "Wisconsin" },
  { id: "Mentone", state_id: "California" },
  { id: "Mentor", state_id: "Ohio" },
  { id: "Mentor-on-the-Lake", state_id: "Ohio" },
  { id: "Mequon", state_id: "Wisconsin" },
  { id: "Meraux", state_id: "Louisiana" },
  { id: "Merced", state_id: "California" },
  { id: "Mercedes", state_id: "Texas" },
  { id: "Mercer Island", state_id: "Washington" },
  { id: "Mercerville-Hamilton Square", state_id: "New Jersey" },
  { id: "Meriden", state_id: "Connecticut" },
  { id: "Meriden", state_id: "Connecticut" },
  { id: "Meridian", state_id: "Idaho" },
  { id: "Meridian", state_id: "Mississippi" },
  { id: "Merriam", state_id: "Kansas" },
  { id: "Merrick", state_id: "New York" },
  { id: "Merrifield", state_id: "Virginia" },
  { id: "Merrill", state_id: "Wisconsin" },
  { id: "Merrillville", state_id: "Indiana" },
  { id: "Merrimac", state_id: "Massachusetts" },
  { id: "Merrimack", state_id: "New Hampshire" },
  { id: "Merritt Island", state_id: "Florida" },
  { id: "Merrydale", state_id: "Louisiana" },
  { id: "Merton", state_id: "Wisconsin" },
  { id: "Mesa", state_id: "Arizona" },
  { id: "Mesquite", state_id: "Nevada" },
  { id: "Mesquite", state_id: "Texas" },
  { id: "Metairie", state_id: "Louisiana" },
  { id: "Methuen", state_id: "Massachusetts" },
  { id: "Metropolis", state_id: "Illinois" },
  { id: "Metuchen", state_id: "New Jersey" },
  { id: "Mexia", state_id: "Texas" },
  { id: "Mexico", state_id: "Missouri" },
  { id: "Miami", state_id: "Florida" },
  { id: "Miami", state_id: "Oklahoma" },
  { id: "Miami Beach", state_id: "Florida" },
  { id: "Miami Lakes", state_id: "Florida" },
  { id: "Miami Shores", state_id: "Florida" },
  { id: "Miami Springs", state_id: "Florida" },
  { id: "Miamisburg", state_id: "Ohio" },
  { id: "Micco", state_id: "Florida" },
  { id: "Michigan City", state_id: "Indiana" },
  { id: "Middle Island", state_id: "New York" },
  { id: "Middle River", state_id: "Maryland" },
  { id: "Middle Valley", state_id: "Tennessee" },
  { id: "Middleborough", state_id: "Massachusetts" },
  { id: "Middleborough Center", state_id: "Massachusetts" },
  { id: "Middleburg", state_id: "Florida" },
  { id: "Middleburg Heights", state_id: "Ohio" },
  { id: "Middlebury", state_id: "Vermont" },
  { id: "Middlebury", state_id: "Vermont" },
  { id: "Middlebury", state_id: "Connecticut" },
  { id: "Middlesborough", state_id: "Kentucky" },
  { id: "Middlesex", state_id: "New Jersey" },
  { id: "Middleton", state_id: "Massachusetts" },
  { id: "Middleton", state_id: "Wisconsin" },
  { id: "Middletown", state_id: "Ohio" },
  { id: "Middletown", state_id: "Pennsylvania" },
  { id: "Middletown", state_id: "Pennsylvania" },
  { id: "Middletown", state_id: "Rhode Island" },
  { id: "Middletown", state_id: "New York" },
  { id: "Middletown", state_id: "Connecticut" },
  { id: "Middletown", state_id: "Connecticut" },
  { id: "Middletown", state_id: "Delaware" },
  { id: "Midland", state_id: "Michigan" },
  { id: "Midland", state_id: "Texas" },
  { id: "Midland", state_id: "Washington" },
  { id: "Midland Park", state_id: "New Jersey" },
  { id: "Midlothian", state_id: "Illinois" },
  { id: "Midlothian", state_id: "Texas" },
  { id: "Midvale", state_id: "Utah" },
  { id: "Midwest City", state_id: "Oklahoma" },
  { id: "Milan", state_id: "Tennessee" },
  { id: "Miles City", state_id: "Montana" },
  { id: "Milford", state_id: "New Hampshire" },
  { id: "Milford", state_id: "New Hampshire" },
  { id: "Milford", state_id: "Michigan" },
  { id: "Milford", state_id: "Massachusetts" },
  { id: "Milford", state_id: "Massachusetts" },
  { id: "Milford", state_id: "Delaware" },
  { id: "Milford", state_id: "Connecticut" },
  { id: "Milford", state_id: "Connecticut" },
  { id: "Milford", state_id: "Ohio" },
  { id: "Milford Mill", state_id: "Maryland" },
  { id: "Mililani Town", state_id: "Hawaii" },
  { id: "Mill Creek", state_id: "Washington" },
  { id: "Mill Plain", state_id: "Washington" },
  { id: "Mill Valley", state_id: "California" },
  { id: "Millbrae", state_id: "California" },
  { id: "Millbrook", state_id: "Alabama" },
  { id: "Millburn", state_id: "New Jersey" },
  { id: "Millbury", state_id: "Massachusetts" },
  { id: "Millcreek", state_id: "Utah" },
  { id: "Milledgeville", state_id: "Georgia" },
  { id: "Miller Place", state_id: "New York" },
  { id: "Millersville", state_id: "Pennsylvania" },
  { id: "Millington", state_id: "Tennessee" },
  { id: "Millis", state_id: "Massachusetts" },
  { id: "Milltown", state_id: "New Jersey" },
  { id: "Millville", state_id: "New Jersey" },
  { id: "Milo", state_id: "New York" },
  { id: "Milpitas", state_id: "California" },
  { id: "Milton", state_id: "Florida" },
  { id: "Milton", state_id: "New York" },
  { id: "Milton", state_id: "Massachusetts" },
  { id: "Milton", state_id: "Massachusetts" },
  { id: "Milton", state_id: "Pennsylvania" },
  { id: "Milton", state_id: "Vermont" },
  { id: "Milton-Freewater", state_id: "Oregon" },
  { id: "Milwaukee", state_id: "Wisconsin" },
  { id: "Milwaukie", state_id: "Oregon" },
  { id: "Mims", state_id: "Florida" },
  { id: "Minden", state_id: "Louisiana" },
  { id: "Mineola", state_id: "New York" },
  { id: "Mineral Wells", state_id: "Texas" },
  { id: "Minneapolis", state_id: "Minnesota" },
  { id: "Minnehaha", state_id: "Washington" },
  { id: "Minnetonka", state_id: "Minnesota" },
  { id: "Minot", state_id: "North Dakota" },
  { id: "Minot AFB", state_id: "North Dakota" },
  { id: "Mint Hill", state_id: "North Carolina" },
  { id: "Mira Loma", state_id: "California" },
  { id: "Mira Monte", state_id: "California" },
  { id: "Miramar", state_id: "Florida" },
  { id: "Mishawaka", state_id: "Indiana" },
  { id: "Mission", state_id: "Kansas" },
  { id: "Mission", state_id: "Texas" },
  { id: "Mission Bend", state_id: "Texas" },
  { id: "Mission Viejo", state_id: "California" },
  { id: "Missoula", state_id: "Montana" },
  { id: "Missouri City", state_id: "Texas" },
  { id: "Mitchell", state_id: "South Dakota" },
  { id: "Mitchellville", state_id: "Maryland" },
  { id: "Moberly", state_id: "Missouri" },
  { id: "Mobile", state_id: "Alabama" },
  { id: "Modesto", state_id: "California" },
  { id: "Mohave Valley", state_id: "Arizona" },
  { id: "Mokena", state_id: "Illinois" },
  { id: "Moline", state_id: "Illinois" },
  { id: "Monaca", state_id: "Pennsylvania" },
  { id: "Monahans", state_id: "Texas" },
  { id: "Monessen", state_id: "Pennsylvania" },
  { id: "Monett", state_id: "Missouri" },
  { id: "Monmouth", state_id: "Illinois" },
  { id: "Monmouth", state_id: "Oregon" },
  { id: "Monona", state_id: "Wisconsin" },
  { id: "Monroe", state_id: "Wisconsin" },
  { id: "Monroe", state_id: "Washington" },
  { id: "Monroe", state_id: "Ohio" },
  { id: "Monroe", state_id: "Connecticut" },
  { id: "Monroe", state_id: "Georgia" },
  { id: "Monroe", state_id: "Michigan" },
  { id: "Monroe", state_id: "Louisiana" },
  { id: "Monroe", state_id: "North Carolina" },
  { id: "Monroe", state_id: "New York" },
  { id: "Monroe", state_id: "New York" },
  { id: "Monroeville", state_id: "Alabama" },
  { id: "Monrovia", state_id: "California" },
  { id: "Monsey", state_id: "New York" },
  { id: "Monson", state_id: "Massachusetts" },
  { id: "Montague", state_id: "Massachusetts" },
  { id: "Montclair", state_id: "New Jersey" },
  { id: "Montclair", state_id: "California" },
  { id: "Montclair", state_id: "Virginia" },
  { id: "Montebello", state_id: "California" },
  { id: "Montecito", state_id: "California" },
  { id: "Monterey", state_id: "California" },
  { id: "Monterey Park", state_id: "California" },
  { id: "Montgomery", state_id: "Alabama" },
  { id: "Montgomery", state_id: "New York" },
  { id: "Montgomery", state_id: "Ohio" },
  { id: "Montgomery Village", state_id: "Maryland" },
  { id: "Montgomeryville", state_id: "Pennsylvania" },
  { id: "Monticello", state_id: "Minnesota" },
  { id: "Monticello", state_id: "New York" },
  { id: "Monticello", state_id: "Arkansas" },
  { id: "Montpelier", state_id: "Vermont" },
  { id: "Montrose", state_id: "Virginia" },
  { id: "Montrose", state_id: "Colorado" },
  { id: "Montvale", state_id: "New Jersey" },
  { id: "Montville", state_id: "Connecticut" },
  { id: "Moody", state_id: "Alabama" },
  { id: "Moore", state_id: "Oklahoma" },
  { id: "Moorestown-Lenola", state_id: "New Jersey" },
  { id: "Mooresville", state_id: "North Carolina" },
  { id: "Mooresville", state_id: "Indiana" },
  { id: "Moorhead", state_id: "Minnesota" },
  { id: "Moorpark", state_id: "California" },
  { id: "Moraga", state_id: "California" },
  { id: "Moraine", state_id: "Ohio" },
  { id: "Moreau", state_id: "New York" },
  { id: "Morehead City", state_id: "North Carolina" },
  { id: "Moreno Valley", state_id: "California" },
  { id: "Morgan City", state_id: "Louisiana" },
  { id: "Morgan Hill", state_id: "California" },
  { id: "Morganton", state_id: "North Carolina" },
  { id: "Morgantown", state_id: "West Virginia" },
  { id: "Morganville", state_id: "New Jersey" },
  { id: "Morrilton", state_id: "Arkansas" },
  { id: "Morris", state_id: "Illinois" },
  { id: "Morristown", state_id: "New Jersey" },
  { id: "Morristown", state_id: "Tennessee" },
  { id: "Morrisville", state_id: "Pennsylvania" },
  { id: "Morro Bay", state_id: "California" },
  { id: "Morton", state_id: "Illinois" },
  { id: "Morton Grove", state_id: "Illinois" },
  { id: "Moscow", state_id: "Idaho" },
  { id: "Moses Lake", state_id: "Washington" },
  { id: "Moss Bluff", state_id: "Louisiana" },
  { id: "Moss Point", state_id: "Mississippi" },
  { id: "Moultrie", state_id: "Georgia" },
  { id: "Mound", state_id: "Minnesota" },
  { id: "Mounds View", state_id: "Minnesota" },
  { id: "Moundsville", state_id: "West Virginia" },
  { id: "Mount Airy", state_id: "Maryland" },
  { id: "Mount Airy", state_id: "North Carolina" },
  { id: "Mount Carmel", state_id: "Illinois" },
  { id: "Mount Carmel", state_id: "Pennsylvania" },
  { id: "Mount Clemens", state_id: "Michigan" },
  { id: "Mount Dora", state_id: "Florida" },
  { id: "Mount Healthy", state_id: "Ohio" },
  { id: "Mount Holly", state_id: "North Carolina" },
  { id: "Mount Hope", state_id: "New York" },
  { id: "Mount Ivy", state_id: "New York" },
  { id: "Mount Joy", state_id: "Pennsylvania" },
  { id: "Mount Juliet", state_id: "Tennessee" },
  { id: "Mount Kisco", state_id: "New York" },
  { id: "Mount Kisco", state_id: "New York" },
  { id: "Mount Lebanon", state_id: "Pennsylvania" },
  { id: "Mount Olympus", state_id: "Utah" },
  { id: "Mount Pleasant", state_id: "Texas" },
  { id: "Mount Pleasant", state_id: "Wisconsin" },
  { id: "Mount Pleasant", state_id: "South Carolina" },
  { id: "Mount Pleasant", state_id: "New York" },
  { id: "Mount Pleasant", state_id: "Michigan" },
  { id: "Mount Pleasant", state_id: "Iowa" },
  { id: "Mount Prospect", state_id: "Illinois" },
  { id: "Mount Rainier", state_id: "Maryland" },
  { id: "Mount Sinai", state_id: "New York" },
  { id: "Mount Vernon", state_id: "New York" },
  { id: "Mount Vernon", state_id: "Illinois" },
  { id: "Mount Vernon", state_id: "Indiana" },
  { id: "Mount Vernon", state_id: "Ohio" },
  { id: "Mount Vernon", state_id: "Washington" },
  { id: "Mount Vernon", state_id: "Virginia" },
  { id: "Mount Washington", state_id: "Kentucky" },
  { id: "Mountain Brook", state_id: "Alabama" },
  { id: "Mountain Home", state_id: "Arkansas" },
  { id: "Mountain Home", state_id: "Idaho" },
  { id: "Mountain Home AFB", state_id: "Idaho" },
  { id: "Mountain Park", state_id: "Georgia" },
  { id: "Mountain Top", state_id: "Pennsylvania" },
  { id: "Mountain View", state_id: "California" },
  { id: "Mountainside", state_id: "New Jersey" },
  { id: "Mountlake Terrace", state_id: "Washington" },
  { id: "Mukilteo", state_id: "Washington" },
  { id: "Mukwonago", state_id: "Wisconsin" },
  { id: "Mukwonago", state_id: "Wisconsin" },
  { id: "Muncie", state_id: "Indiana" },
  { id: "Mundelein", state_id: "Illinois" },
  { id: "Munhall", state_id: "Pennsylvania" },
  { id: "Municipality of Monroeville", state_id: "Pennsylvania" },
  { id: "Municipality of Murrysville", state_id: "Pennsylvania" },
  { id: "Munster", state_id: "Indiana" },
  { id: "Murfreesboro", state_id: "Tennessee" },
  { id: "Murphy", state_id: "Missouri" },
  { id: "Murphysboro", state_id: "Illinois" },
  { id: "Murray", state_id: "Kentucky" },
  { id: "Murray", state_id: "New York" },
  { id: "Murray", state_id: "Utah" },
  { id: "Murraysville", state_id: "North Carolina" },
  { id: "Murrieta", state_id: "California" },
  { id: "Muscatine", state_id: "Iowa" },
  { id: "Muscle Shoals", state_id: "Alabama" },
  { id: "Muscoy", state_id: "California" },
  { id: "Muskego", state_id: "Wisconsin" },
  { id: "Muskegon", state_id: "Michigan" },
  { id: "Muskegon Heights", state_id: "Michigan" },
  { id: "Muskogee", state_id: "Oklahoma" },
  { id: "Mustang", state_id: "Oklahoma" },
  { id: "Myrtle Beach", state_id: "South Carolina" },
  { id: "Myrtle Grove", state_id: "North Carolina" },
  { id: "Myrtle Grove", state_id: "Florida" },
  { id: "Mystic Island", state_id: "New Jersey" },
  { id: "Nacogdoches", state_id: "Texas" },
  { id: "Nampa", state_id: "Idaho" },
  { id: "Nanakuli", state_id: "Hawaii" },
  { id: "Nanticoke", state_id: "Pennsylvania" },
  { id: "Nantucket", state_id: "Massachusetts" },
  { id: "Nanuet", state_id: "New York" },
  { id: "Napa", state_id: "California" },
  { id: "Naperville", state_id: "Illinois" },
  { id: "Napili-Honokowai", state_id: "Hawaii" },
  { id: "Naples", state_id: "Florida" },
  { id: "Naples Park", state_id: "Florida" },
  { id: "Napoleon", state_id: "Ohio" },
  { id: "Nappanee", state_id: "Indiana" },
  { id: "Narragansett", state_id: "Rhode Island" },
  { id: "Nashua", state_id: "New Hampshire" },
  { id: "Nashville-Davidson", state_id: "Tennessee" },
  { id: "Natchez", state_id: "Mississippi" },
  { id: "Natchitoches", state_id: "Louisiana" },
  { id: "Natick", state_id: "Massachusetts" },
  { id: "National City", state_id: "California" },
  { id: "Naugatuck", state_id: "Connecticut" },
  { id: "Naugatuck", state_id: "Connecticut" },
  { id: "Navasota", state_id: "Texas" },
  { id: "Nazareth", state_id: "Pennsylvania" },
  { id: "Nebraska City", state_id: "Nebraska" },
  { id: "Nederland", state_id: "Texas" },
  { id: "Needham", state_id: "Massachusetts" },
  { id: "Needham", state_id: "Massachusetts" },
  { id: "Neenah", state_id: "Wisconsin" },
  { id: "Nellis AFB", state_id: "Nevada" },
  { id: "Neosho", state_id: "Missouri" },
  { id: "Neptune Beach", state_id: "Florida" },
  { id: "Nesconset", state_id: "New York" },
  { id: "Nether Providence Township", state_id: "Pennsylvania" },
  { id: "Nevada", state_id: "Missouri" },
  { id: "Nevada", state_id: "Iowa" },
  { id: "New Albany", state_id: "Indiana" },
  { id: "New Albany", state_id: "Mississippi" },
  { id: "New Baltimore", state_id: "Michigan" },
  { id: "New Bedford", state_id: "Massachusetts" },
  { id: "New Berlin", state_id: "Wisconsin" },
  { id: "New Bern", state_id: "North Carolina" },
  { id: "New Braunfels", state_id: "Texas" },
  { id: "New Brighton", state_id: "Pennsylvania" },
  { id: "New Brighton", state_id: "Minnesota" },
  { id: "New Britain", state_id: "Connecticut" },
  { id: "New Britain", state_id: "Connecticut" },
  { id: "New Brunswick", state_id: "New Jersey" },
  { id: "New Canaan", state_id: "Connecticut" },
  { id: "New Carrollton", state_id: "Maryland" },
  { id: "New Cassel", state_id: "New York" },
  { id: "New Castle", state_id: "New York" },
  { id: "New Castle", state_id: "Pennsylvania" },
  { id: "New Castle", state_id: "Indiana" },
  { id: "New City", state_id: "New York" },
  { id: "New Cumberland", state_id: "Pennsylvania" },
  { id: "New Fairfield", state_id: "Connecticut" },
  { id: "New Hartford", state_id: "Connecticut" },
  { id: "New Hartford", state_id: "New York" },
  { id: "New Haven", state_id: "Connecticut" },
  { id: "New Haven", state_id: "Connecticut" },
  { id: "New Haven", state_id: "Indiana" },
  { id: "New Hope", state_id: "Minnesota" },
  { id: "New Hyde Park", state_id: "New York" },
  { id: "New Iberia", state_id: "Louisiana" },
  { id: "New Kensington", state_id: "Pennsylvania" },
  { id: "New Kingman-Butler", state_id: "Arizona" },
  { id: "New Lenox", state_id: "Illinois" },
  { id: "New London", state_id: "Connecticut" },
  { id: "New London", state_id: "Connecticut" },
  { id: "New London", state_id: "Wisconsin" },
  { id: "New Milford", state_id: "Connecticut" },
  { id: "New Milford", state_id: "Connecticut" },
  { id: "New Milford", state_id: "New Jersey" },
  { id: "New Orleans", state_id: "Louisiana" },
  { id: "New Paltz", state_id: "New York" },
  { id: "New Paltz", state_id: "New York" },
  { id: "New Philadelphia", state_id: "Ohio" },
  { id: "New Port Richey", state_id: "Florida" },
  { id: "New Port Richey East", state_id: "Florida" },
  { id: "New Providence", state_id: "New Jersey" },
  { id: "New Richmond", state_id: "Wisconsin" },
  { id: "New River", state_id: "Arizona" },
  { id: "New Rochelle", state_id: "New York" },
  { id: "New Scotland", state_id: "New York" },
  { id: "New Smyrna Beach", state_id: "Florida" },
  { id: "New Territory", state_id: "Texas" },
  { id: "New Ulm", state_id: "Minnesota" },
  { id: "New Windsor", state_id: "New York" },
  { id: "New Windsor", state_id: "New York" },
  { id: "New York", state_id: "New York" },
  { id: "Newark", state_id: "New Jersey" },
  { id: "Newark", state_id: "New York" },
  { id: "Newark", state_id: "Ohio" },
  { id: "Newark", state_id: "Delaware" },
  { id: "Newark", state_id: "California" },
  { id: "Newberg", state_id: "Oregon" },
  { id: "Newberry", state_id: "South Carolina" },
  { id: "Newburg", state_id: "Kentucky" },
  { id: "Newburgh", state_id: "New York" },
  { id: "Newburgh", state_id: "New York" },
  { id: "Newbury", state_id: "Massachusetts" },
  { id: "Newburyport", state_id: "Massachusetts" },
  { id: "Newcastle", state_id: "Washington" },
  { id: "Newfane", state_id: "New York" },
  { id: "Newington", state_id: "Virginia" },
  { id: "Newington", state_id: "Connecticut" },
  { id: "Newington", state_id: "Connecticut" },
  { id: "Newman", state_id: "California" },
  { id: "Newmarket", state_id: "New Hampshire" },
  { id: "Newnan", state_id: "Georgia" },
  { id: "Newport", state_id: "Arkansas" },
  { id: "Newport", state_id: "Kentucky" },
  { id: "Newport", state_id: "New Hampshire" },
  { id: "Newport", state_id: "Tennessee" },
  { id: "Newport", state_id: "Rhode Island" },
  { id: "Newport", state_id: "Oregon" },
  { id: "Newport Beach", state_id: "California" },
  { id: "Newport East", state_id: "Rhode Island" },
  { id: "Newport News", state_id: "Virginia" },
  { id: "Newstead", state_id: "New York" },
  { id: "Newton", state_id: "North Carolina" },
  { id: "Newton", state_id: "Kansas" },
  { id: "Newton", state_id: "Iowa" },
  { id: "Newton", state_id: "Massachusetts" },
  { id: "Newton", state_id: "New Jersey" },
  { id: "Newtown", state_id: "Connecticut" },
  { id: "Niagara", state_id: "New York" },
  { id: "Niagara Falls", state_id: "New York" },
  { id: "Niceville", state_id: "Florida" },
  { id: "Nicholasville", state_id: "Kentucky" },
  { id: "Niles", state_id: "Michigan" },
  { id: "Niles", state_id: "Illinois" },
  { id: "Niles", state_id: "Ohio" },
  { id: "Nipomo", state_id: "California" },
  { id: "Niskayuna", state_id: "New York" },
  { id: "Nitro", state_id: "West Virginia" },
  { id: "Nixa", state_id: "Missouri" },
  { id: "Noblesville", state_id: "Indiana" },
  { id: "Nogales", state_id: "Arizona" },
  { id: "Norco", state_id: "California" },
  { id: "Norcross", state_id: "Georgia" },
  { id: "Norfolk", state_id: "Massachusetts" },
  { id: "Norfolk", state_id: "Nebraska" },
  { id: "Norfolk", state_id: "Virginia" },
  { id: "Norland", state_id: "Florida" },
  { id: "Keystone Heights", state_id: "Florida" },
  { id: "Normal", state_id: "Illinois" },
  { id: "Norman", state_id: "Oklahoma" },
  { id: "Normandy Park", state_id: "Washington" },
  { id: "Norridge", state_id: "Illinois" },
  { id: "Norristown", state_id: "Pennsylvania" },
  { id: "North Adams", state_id: "Massachusetts" },
  { id: "North Amherst", state_id: "Massachusetts" },
  { id: "North Amityville", state_id: "New York" },
  { id: "North Andover", state_id: "Massachusetts" },
  { id: "North Andrews Gardens", state_id: "Florida" },
  { id: "North Arlington", state_id: "New Jersey" },
  { id: "North Atlanta", state_id: "Georgia" },
  { id: "North Attleborough", state_id: "Massachusetts" },
  { id: "North Attleborough Center", state_id: "Massachusetts" },
  { id: "North Auburn", state_id: "California" },
  { id: "North Augusta", state_id: "South Carolina" },
  { id: "North Aurora", state_id: "Illinois" },
  { id: "North Babylon", state_id: "New York" },
  { id: "North Bay Shore", state_id: "New York" },
  { id: "North Bay Village", state_id: "Florida" },
  { id: "North Bellmore", state_id: "New York" },
  { id: "North Bellport", state_id: "New York" },
  { id: "North Bend", state_id: "Oregon" },
  { id: "North Bethesda", state_id: "Maryland" },
  { id: "North Braddock", state_id: "Pennsylvania" },
  { id: "North Branch", state_id: "Minnesota" },
  { id: "North Branford", state_id: "Connecticut" },
  { id: "North Brunswick Township", state_id: "New Jersey" },
  { id: "North Caldwell", state_id: "New Jersey" },
  { id: "North Canton", state_id: "Ohio" },
  { id: "North Castle", state_id: "New York" },
  { id: "North Charleston", state_id: "South Carolina" },
  { id: "North Chicago", state_id: "Illinois" },
  { id: "North College Hill", state_id: "Ohio" },
  { id: "North Creek", state_id: "Washington" },
  { id: "North Decatur", state_id: "Georgia" },
  { id: "North Druid Hills", state_id: "Georgia" },
  { id: "North Elba", state_id: "New York" },
  { id: "North Fair Oaks", state_id: "California" },
  { id: "North Fort Myers", state_id: "Florida" },
  { id: "North Greenbush", state_id: "New York" },
  { id: "North Haledon", state_id: "New Jersey" },
  { id: "North Haven", state_id: "Connecticut" },
  { id: "North Haven", state_id: "Connecticut" },
  { id: "North Hempstead", state_id: "New York" },
  { id: "North Highlands", state_id: "California" },
  { id: "North Kensington", state_id: "Maryland" },
  { id: "North Kingstown", state_id: "Rhode Island" },
  { id: "North Las Vegas", state_id: "Nevada" },
  { id: "North Lauderdale", state_id: "Florida" },
  { id: "North Laurel", state_id: "Maryland" },
  { id: "North Lindenhurst", state_id: "New York" },
  { id: "North Little Rock", state_id: "Arkansas" },
  { id: "North Logan", state_id: "Utah" },
  { id: "North Madison", state_id: "Ohio" },
  { id: "North Manchester", state_id: "Indiana" },
  { id: "North Mankato", state_id: "Minnesota" },
  { id: "North Marysville", state_id: "Washington" },
  { id: "North Massapequa", state_id: "New York" },
  { id: "North Merrick", state_id: "New York" },
  { id: "North Miami", state_id: "Florida" },
  { id: "North Miami Beach", state_id: "Florida" },
  { id: "North Myrtle Beach", state_id: "South Carolina" },
  { id: "North New Hyde Park", state_id: "New York" },
  { id: "North Ogden", state_id: "Utah" },
  { id: "North Olmsted", state_id: "Ohio" },
  { id: "North Palm Beach", state_id: "Florida" },
  { id: "North Patchogue", state_id: "New York" },
  { id: "North Plainfield", state_id: "New Jersey" },
  { id: "North Platte", state_id: "Nebraska" },
  { id: "North Port", state_id: "Florida" },
  { id: "North Potomac", state_id: "Maryland" },
  { id: "North Providence", state_id: "Rhode Island" },
  { id: "North Providence", state_id: "Rhode Island" },
  { id: "North Reading", state_id: "Massachusetts" },
  { id: "North Richland Hills", state_id: "Texas" },
  { id: "North Ridgeville", state_id: "Ohio" },
  { id: "North Riverside", state_id: "Illinois" },
  { id: "North Royalton", state_id: "Ohio" },
  { id: "North Salt Lake", state_id: "Utah" },
  { id: "North Sarasota", state_id: "Florida" },
  { id: "North Smithfield", state_id: "Rhode Island" },
  { id: "North Springfield", state_id: "Virginia" },
  { id: "North St. Paul", state_id: "Minnesota" },
  { id: "North Star", state_id: "Delaware" },
  { id: "North Syracuse", state_id: "New York" },
  { id: "North Tonawanda", state_id: "New York" },
  { id: "North Valley", state_id: "New Mexico" },
  { id: "North Valley Stream", state_id: "New York" },
  { id: "North Vernon", state_id: "Indiana" },
  { id: "North Versailles", state_id: "Pennsylvania" },
  { id: "North Wantagh", state_id: "New York" },
  { id: "Northampton", state_id: "Pennsylvania" },
  { id: "Northampton", state_id: "Massachusetts" },
  { id: "Northborough", state_id: "Massachusetts" },
  { id: "Northborough", state_id: "Massachusetts" },
  { id: "Northbridge", state_id: "Massachusetts" },
  { id: "Northbrook", state_id: "Illinois" },
  { id: "Northbrook", state_id: "Ohio" },
  { id: "Northfield", state_id: "Minnesota" },
  { id: "Northfield", state_id: "New Jersey" },
  { id: "Northgate", state_id: "Ohio" },
  { id: "Northglenn", state_id: "Colorado" },
  { id: "Northlake", state_id: "Illinois" },
  { id: "Northport", state_id: "Alabama" },
  { id: "Northport", state_id: "New York" },
  { id: "Northridge", state_id: "Ohio" },
  { id: "Northridge", state_id: "Ohio" },
  { id: "Northview", state_id: "Michigan" },
  { id: "Northville", state_id: "Michigan" },
  { id: "Northwest Harborcreek", state_id: "Pennsylvania" },
  { id: "Norton", state_id: "Ohio" },
  { id: "Norton", state_id: "Massachusetts" },
  { id: "Norton Shores", state_id: "Michigan" },
  { id: "Norwalk", state_id: "Iowa" },
  { id: "Norwalk", state_id: "Connecticut" },
  { id: "Norwalk", state_id: "California" },
  { id: "Norwalk", state_id: "Connecticut" },
  { id: "Norwalk", state_id: "Ohio" },
  { id: "Norway", state_id: "Wisconsin" },
  { id: "Norwell", state_id: "Massachusetts" },
  { id: "Norwich", state_id: "New York" },
  { id: "Norwich", state_id: "Connecticut" },
  { id: "Norwich", state_id: "Connecticut" },
  { id: "Norwood", state_id: "Massachusetts" },
  { id: "Norwood", state_id: "Massachusetts" },
  { id: "Norwood", state_id: "Ohio" },
  { id: "Novato", state_id: "California" },
  { id: "Novi", state_id: "Michigan" },
  { id: "Nutley", state_id: "New Jersey" },
  { id: "Nyack", state_id: "New York" },
  { id: "O’Fallon", state_id: "Illinois" },
  { id: "O’Fallon", state_id: "Missouri" },
  { id: "O’Hara Township", state_id: "Pennsylvania" },
  { id: "Oak Brook", state_id: "Illinois" },
  { id: "Oak Creek", state_id: "Wisconsin" },
  { id: "Oak Forest", state_id: "Illinois" },
  { id: "Oak Grove", state_id: "Kentucky" },
  { id: "Oak Grove", state_id: "South Carolina" },
  { id: "Oak Grove", state_id: "Oregon" },
  { id: "Oak Grove", state_id: "Minnesota" },
  { id: "Oak Harbor", state_id: "Washington" },
  { id: "Oak Hill", state_id: "West Virginia" },
  { id: "Oak Hills", state_id: "Oregon" },
  { id: "Oak Hills Place", state_id: "Louisiana" },
  { id: "Oak Island", state_id: "North Carolina" },
  { id: "Oak Lawn", state_id: "Illinois" },
  { id: "Oak Park", state_id: "Illinois" },
  { id: "Oak Park", state_id: "Michigan" },
  { id: "Oak Ridge", state_id: "Tennessee" },
  { id: "Oak Ridge", state_id: "Florida" },
  { id: "Oakbrook", state_id: "Kentucky" },
  { id: "Oakdale", state_id: "Louisiana" },
  { id: "Oakdale", state_id: "California" },
  { id: "Oakdale", state_id: "Minnesota" },
  { id: "Oakdale", state_id: "New York" },
  { id: "Oakland", state_id: "New Jersey" },
  { id: "Oakland", state_id: "California" },
  { id: "Oakland Park", state_id: "Florida" },
  { id: "Oakley", state_id: "California" },
  { id: "Oakmont", state_id: "Pennsylvania" },
  { id: "Oakton", state_id: "Virginia" },
  { id: "Oakville", state_id: "Missouri" },
  { id: "Oakville", state_id: "Connecticut" },
  { id: "Oakwood", state_id: "Ohio" },
  { id: "Oatfield", state_id: "Oregon" },
  { id: "Oberlin", state_id: "Ohio" },
  { id: "Ocala", state_id: "Florida" },
  { id: "Ocean Acres", state_id: "New Jersey" },
  { id: "Ocean City", state_id: "New Jersey" },
  { id: "Ocean City", state_id: "Maryland" },
  { id: "Ocean Pines", state_id: "Maryland" },
  { id: "Ocean Springs", state_id: "Mississippi" },
  { id: "Oceano", state_id: "California" },
  { id: "Oceanside", state_id: "California" },
  { id: "Oceanside", state_id: "New York" },
  { id: "Ocoee", state_id: "Florida" },
  { id: "Oconomowoc", state_id: "Wisconsin" },
  { id: "Oconomowoc", state_id: "Wisconsin" },
  { id: "Odenton", state_id: "Maryland" },
  { id: "Odessa", state_id: "Texas" },
  { id: "San Augustine", state_id: "Texas" },
  { id: "Oelwein", state_id: "Iowa" },
  { id: "Offutt AFB", state_id: "Nebraska" },
  { id: "Ogden", state_id: "New York" },
  { id: "Ogden", state_id: "Utah" },
  { id: "Ogdensburg", state_id: "New York" },
  { id: "Oil City", state_id: "Pennsylvania" },
  { id: "Oildale", state_id: "California" },
  { id: "Ojai", state_id: "California" },
  { id: "Ojus", state_id: "Florida" },
  { id: "Okemos", state_id: "Michigan" },
  { id: "Oklahoma City", state_id: "Oklahoma" },
  { id: "Okmulgee", state_id: "Oklahoma" },
  { id: "Foss", state_id: "Oklahoma" },
  { id: "Okolona", state_id: "Kentucky" },
  { id: "Olathe", state_id: "Kansas" },
  { id: "Old Bridge", state_id: "New Jersey" },
  { id: "Old Forge", state_id: "Pennsylvania" },
  { id: "Old Lyme", state_id: "Connecticut" },
  { id: "Old Orchard Beach", state_id: "Maine" },
  { id: "Old Orchard Beach", state_id: "Maine" },
  { id: "Old Saybrook", state_id: "Connecticut" },
  { id: "Old Town", state_id: "Maine" },
  { id: "Oldsmar", state_id: "Florida" },
  { id: "Olean", state_id: "New York" },
  { id: "Olive Branch", state_id: "Mississippi" },
  { id: "Olivehurst", state_id: "California" },
  { id: "Tel Aviv", state_id: "California" },
  { id: "Olivette", state_id: "Missouri" },
  { id: "Olmsted Falls", state_id: "Ohio" },
  { id: "Olney", state_id: "Maryland" },
  { id: "Olney", state_id: "Illinois" },
  { id: "Olympia", state_id: "Washington" },
  { id: "Olympia Heights", state_id: "Florida" },
  { id: "Omaha", state_id: "Nebraska" },
  { id: "Onalaska", state_id: "Wisconsin" },
  { id: "Oneida", state_id: "New York" },
  { id: "Oneonta", state_id: "New York" },
  { id: "Onondaga", state_id: "New York" },
  { id: "Ontario", state_id: "New York" },
  { id: "Ontario", state_id: "Oregon" },
  { id: "Ontario", state_id: "California" },
  { id: "Opa-locka", state_id: "Florida" },
  { id: "Opa-locka North", state_id: "Florida" },
  { id: "Opal Cliffs", state_id: "California" },
  { id: "Opelika", state_id: "Alabama" },
  { id: "Opelousas", state_id: "Louisiana" },
  { id: "Opp", state_id: "Alabama" },
  { id: "Opportunity", state_id: "Washington" },
  { id: "Oquirrh", state_id: "Utah" },
  { id: "Oradell", state_id: "New Jersey" },
  { id: "Orange", state_id: "New Jersey" },
  { id: "Orange", state_id: "Massachusetts" },
  { id: "Orange", state_id: "Texas" },
  { id: "Orange", state_id: "Connecticut" },
  { id: "Orange", state_id: "California" },
  { id: "Orange", state_id: "Connecticut" },
  { id: "Orange City", state_id: "Florida" },
  { id: "Orange Cove", state_id: "California" },
  { id: "Orange Lake", state_id: "New York" },
  { id: "Orange Park", state_id: "Florida" },
  { id: "Orangeburg", state_id: "South Carolina" },
  { id: "Orangetown", state_id: "New York" },
  { id: "Orangevale", state_id: "California" },
  { id: "Orchard Mesa", state_id: "Colorado" },
  { id: "Orchard Park", state_id: "New York" },
  { id: "Orchards", state_id: "Washington" },
  { id: "Orcutt", state_id: "California" },
  { id: "Oregon", state_id: "Ohio" },
  { id: "Oregon", state_id: "Wisconsin" },
  { id: "Oregon City", state_id: "Oregon" },
  { id: "Orem", state_id: "Utah" },
  { id: "Orinda", state_id: "California" },
  { id: "Orland", state_id: "California" },
  { id: "Orland Hills", state_id: "Illinois" },
  { id: "Orland Park", state_id: "Illinois" },
  { id: "Orlando", state_id: "Florida" },
  { id: "Orleans", state_id: "Massachusetts" },
  { id: "Orlovista", state_id: "Florida" },
  { id: "Ormond Beach", state_id: "Florida" },
  { id: "Ormond-By-The-Sea", state_id: "Florida" },
  { id: "Oro Valley", state_id: "Arizona" },
  { id: "Orono", state_id: "Maine" },
  { id: "Orono", state_id: "Maine" },
  { id: "Orono", state_id: "Minnesota" },
  { id: "Orosi", state_id: "California" },
  { id: "Oroville", state_id: "California" },
  { id: "Oroville East", state_id: "California" },
  { id: "Orrville", state_id: "Ohio" },
  { id: "Osceola", state_id: "Arkansas" },
  { id: "Oshkosh", state_id: "Wisconsin" },
  { id: "Oskaloosa", state_id: "Iowa" },
  { id: "Ossining", state_id: "New York" },
  { id: "Ossining", state_id: "New York" },
  { id: "Oswego", state_id: "New York" },
  { id: "Oswego", state_id: "New York" },
  { id: "Oswego", state_id: "Illinois" },
  { id: "Otis Orchards-East Farms", state_id: "Washington" },
  { id: "Otsego", state_id: "Minnesota" },
  { id: "Ottawa", state_id: "Illinois" },
  { id: "Ottawa", state_id: "Kansas" },
  { id: "Ottumwa", state_id: "Iowa" },
  { id: "Overland", state_id: "Missouri" },
  { id: "Overland Park", state_id: "Kansas" },
  { id: "Overlea", state_id: "Maryland" },
  { id: "Oviedo", state_id: "Florida" },
  { id: "Owasso", state_id: "Oklahoma" },
  { id: "Owatonna", state_id: "Minnesota" },
  { id: "Owego", state_id: "New York" },
  { id: "Owensboro", state_id: "Kentucky" },
  { id: "Owings Mills", state_id: "Maryland" },
  { id: "Owosso", state_id: "Michigan" },
  { id: "Oxford", state_id: "Massachusetts" },
  { id: "Oxford", state_id: "Mississippi" },
  { id: "Oxford", state_id: "North Carolina" },
  { id: "Oxford", state_id: "Ohio" },
  { id: "Oxford", state_id: "Connecticut" },
  { id: "Oxford", state_id: "Alabama" },
  { id: "Oxnard", state_id: "California" },
  { id: "Oxon Hill-Glassmanor", state_id: "Maryland" },
  { id: "Oyster Bay", state_id: "New York" },
  { id: "Oyster Bay", state_id: "New York" },
  { id: "Ozark", state_id: "Missouri" },
  { id: "Ozark", state_id: "Alabama" },
  { id: "Pace", state_id: "Florida" },
  { id: "Pacific Grove", state_id: "California" },
  { id: "Pacifica", state_id: "California" },
  { id: "Paducah", state_id: "Kentucky" },
  { id: "Page", state_id: "Arizona" },
  { id: "Pahrump", state_id: "Nevada" },
  { id: "Paine Field-Lake Stickney", state_id: "Washington" },
  { id: "Painesville", state_id: "Ohio" },
  { id: "Palestine", state_id: "Ohio" },
  { id: "Palatine", state_id: "Illinois" },
  { id: "Palatka", state_id: "Florida" },
  { id: "Palestine", state_id: "Texas" },
  { id: "Palisades Park", state_id: "New Jersey" },
  { id: "Palm Bay", state_id: "Florida" },
  { id: "Palm Beach", state_id: "Florida" },
  { id: "Palm Beach Gardens", state_id: "Florida" },
  { id: "Palm City", state_id: "Florida" },
  { id: "Palm Coast", state_id: "Florida" },
  { id: "Palm Desert", state_id: "California" },
  { id: "Palm Harbor", state_id: "Florida" },
  { id: "Palm River-Clair Mel", state_id: "Florida" },
  { id: "Palm Springs", state_id: "Florida" },
  { id: "Palm Springs", state_id: "California" },
  { id: "Palm Valley", state_id: "Florida" },
  { id: "Palmdale", state_id: "California" },
  { id: "Palmer", state_id: "Massachusetts" },
  { id: "Palmetto", state_id: "Florida" },
  { id: "Palmetto Estates", state_id: "Florida" },
  { id: "Palmview South", state_id: "Texas" },
  { id: "McGregor", state_id: "Texas" },
  { id: "Palmyra", state_id: "Pennsylvania" },
  { id: "Ramey", state_id: "Pennsylvania" },
  { id: "Palmyra", state_id: "New Jersey" },
  { id: "Palmyra", state_id: "New York" },
  { id: "Palo Alto", state_id: "California" },
  { id: "Palos Heights", state_id: "Illinois" },
  { id: "Palos Hills", state_id: "Illinois" },
  { id: "Palos Verdes Estates", state_id: "California" },
  { id: "Pampa", state_id: "Texas" },
  { id: "Panama City", state_id: "Florida" },
  { id: "Panama City Beach", state_id: "Florida" },
  { id: "Panthersville", state_id: "Georgia" },
  { id: "Papillion", state_id: "Nebraska" },
  { id: "Paradise", state_id: "Nevada" },
  { id: "Paradise", state_id: "California" },
  { id: "Paradise Valley", state_id: "Arizona" },
  { id: "Paragould", state_id: "Arkansas" },
  { id: "Paramount", state_id: "California" },
  { id: "Paramus", state_id: "New Jersey" },
  { id: "Paris", state_id: "Texas" },
  { id: "Paris", state_id: "Tennessee" },
  { id: "Paris", state_id: "Illinois" },
  { id: "Paris", state_id: "Kentucky" },
  { id: "Park City", state_id: "Illinois" },
  { id: "Park City", state_id: "Utah" },
  { id: "Park Forest", state_id: "Illinois" },
  { id: "Park Forest Village", state_id: "Pennsylvania" },
  { id: "Park Hills", state_id: "Missouri" },
  { id: "Park Ridge", state_id: "New Jersey" },
  { id: "Park Ridge", state_id: "Illinois" },
  { id: "Parker", state_id: "Colorado" },
  { id: "Parker", state_id: "South Carolina" },
  { id: "Parkersburg", state_id: "West Virginia" },
  { id: "Parkland", state_id: "Washington" },
  { id: "Parkland", state_id: "Florida" },
  { id: "Parkville", state_id: "Maryland" },
  { id: "Parkville", state_id: "Pennsylvania" },
  { id: "Parkway-South Sacramento", state_id: "California" },
  { id: "Parkwood", state_id: "Washington" },
  { id: "Parlier", state_id: "California" },
  { id: "Parma", state_id: "New York" },
  { id: "Parma", state_id: "Ohio" },
  { id: "Parma Heights", state_id: "Ohio" },
  { id: "Parole", state_id: "Maryland" },
  { id: "Parsons", state_id: "Kansas" },
  { id: "Pasadena", state_id: "Maryland" },
  { id: "Pasadena", state_id: "California" },
  { id: "Pasadena", state_id: "Texas" },
  { id: "Pascagoula", state_id: "Mississippi" },
  { id: "Pasco", state_id: "Washington" },
  { id: "Pass Christian", state_id: "Mississippi" },
  { id: "Passaic", state_id: "New Jersey" },
  { id: "Pataskala", state_id: "Ohio" },
  { id: "Patchogue", state_id: "New York" },
  { id: "Paterson", state_id: "New Jersey" },
  { id: "Patterson", state_id: "New York" },
  { id: "Patterson", state_id: "California" },
  { id: "Pauls Valley", state_id: "Oklahoma" },
  { id: "Paulsboro", state_id: "New Jersey" },
  { id: "Pawling", state_id: "New York" },
  { id: "Pawtucket", state_id: "Rhode Island" },
  { id: "Payette", state_id: "Idaho" },
  { id: "Payson", state_id: "Arizona" },
  { id: "Payson", state_id: "Utah" },
  { id: "Pea Ridge", state_id: "West Virginia" },
  { id: "Peabody", state_id: "Massachusetts" },
  { id: "Peachtree City", state_id: "Georgia" },
  { id: "Pearl", state_id: "Mississippi" },
  { id: "Pearl City", state_id: "Hawaii" },
  { id: "Pearl River", state_id: "New York" },
  { id: "Pearland", state_id: "Texas" },
  { id: "Pearsall", state_id: "Texas" },
  { id: "Pecan Grove", state_id: "Texas" },
  { id: "Pecos", state_id: "Texas" },
  { id: "Pedley", state_id: "California" },
  { id: "Peekskill", state_id: "New York" },
  { id: "Pekin", state_id: "Illinois" },
  { id: "Pelham", state_id: "Alabama" },
  { id: "Pelham", state_id: "New York" },
  { id: "Pelham", state_id: "New York" },
  { id: "Pelham", state_id: "New Hampshire" },
  { id: "Pell City", state_id: "Alabama" },
  { id: "Pella", state_id: "Iowa" },
  { id: "Pembroke", state_id: "Massachusetts" },
  { id: "Pembroke", state_id: "New Hampshire" },
  { id: "Pembroke Park", state_id: "Florida" },
  { id: "Pembroke Pines", state_id: "Florida" },
  { id: "Pendleton", state_id: "New York" },
  { id: "Pendleton", state_id: "Oregon" },
  { id: "Penfield", state_id: "New York" },
  { id: "Penn Hills", state_id: "Pennsylvania" },
  { id: "Pennsauken", state_id: "New Jersey" },
  { id: "Pennsville", state_id: "New Jersey" },
  { id: "Pensacola", state_id: "Florida" },
  { id: "Peoria", state_id: "Arizona" },
  { id: "Peoria", state_id: "Illinois" },
  { id: "Peoria Heights", state_id: "Illinois" },
  { id: "Pepper Pike", state_id: "Ohio" },
  { id: "Pepperell", state_id: "Massachusetts" },
  { id: "Perinton", state_id: "New York" },
  { id: "Perkasie", state_id: "Pennsylvania" },
  { id: "Perris", state_id: "California" },
  { id: "Perry", state_id: "Florida" },
  { id: "Perry", state_id: "Iowa" },
  { id: "Perry", state_id: "Georgia" },
  { id: "Perry", state_id: "New York" },
  { id: "Perry Hall", state_id: "Maryland" },
  { id: "Perry Heights", state_id: "Ohio" },
  { id: "Perrysburg", state_id: "Ohio" },
  { id: "Perryton", state_id: "Texas" },
  { id: "Perryville", state_id: "Missouri" },
  { id: "Perth Amboy", state_id: "New Jersey" },
  { id: "Peru", state_id: "New York" },
  { id: "Peru", state_id: "Illinois" },
  { id: "Peru", state_id: "Indiana" },
  { id: "Petal", state_id: "Mississippi" },
  { id: "Petaluma", state_id: "California" },
  { id: "Petersburg", state_id: "Virginia" },
  { id: "Petoskey", state_id: "Michigan" },
  { id: "Pewaukee", state_id: "Wisconsin" },
  { id: "Pewaukee", state_id: "Wisconsin" },
  { id: "Pflugerville", state_id: "Texas" },
  { id: "Pharr", state_id: "Texas" },
  { id: "Phelps", state_id: "New York" },
  { id: "Phenix City", state_id: "Alabama" },
  { id: "Philadelphia", state_id: "Mississippi" },
  { id: "Philadelphia", state_id: "Pennsylvania" },
  { id: "Philipstown", state_id: "New York" },
  { id: "Phillipsburg", state_id: "New Jersey" },
  { id: "Phoenix", state_id: "Arizona" },
  { id: "Phoenixville", state_id: "Pennsylvania" },
  { id: "Picayune", state_id: "Mississippi" },
  { id: "Pickerington", state_id: "Ohio" },
  { id: "Picnic Point-North Lynnwood", state_id: "Washington" },
  { id: "Pico Rivera", state_id: "California" },
  { id: "Picture Rocks", state_id: "Arizona" },
  { id: "Piedmont", state_id: "California" },
  { id: "Pierre", state_id: "South Dakota" },
  { id: "Pike Creek", state_id: "Delaware" },
  { id: "Pikesville", state_id: "Maryland" },
  { id: "Pikeville", state_id: "Kentucky" },
  { id: "Pimmit Hills", state_id: "Virginia" },
  { id: "Pine Bluff", state_id: "Arkansas" },
  { id: "Pine Castle", state_id: "Florida" },
  { id: "Pine Hill", state_id: "New Jersey" },
  { id: "Pine Hills", state_id: "Florida" },
  { id: "Pinecrest", state_id: "Florida" },
  { id: "Pinehurst", state_id: "Massachusetts" },
  { id: "Pinehurst", state_id: "North Carolina" },
  { id: "Pinellas Park", state_id: "Florida" },
  { id: "Pineville", state_id: "Louisiana" },
  { id: "Pinewood", state_id: "Florida" },
  { id: "Piney Green", state_id: "North Carolina" },
  { id: "Pinole", state_id: "California" },
  { id: "Piqua", state_id: "Ohio" },
  { id: "Pismo Beach", state_id: "California" },
  { id: "Pitman", state_id: "New Jersey" },
  { id: "Pittsburg", state_id: "California" },
  { id: "Pittsburg", state_id: "Kansas" },
  { id: "Pittsburgh", state_id: "Pennsylvania" },
  { id: "Pittsfield", state_id: "Massachusetts" },
  { id: "Pittsford", state_id: "New York" },
  { id: "Pittston", state_id: "Pennsylvania" },
  { id: "Placentia", state_id: "California" },
  { id: "Placerville", state_id: "California" },
  { id: "Plainedge", state_id: "New York" },
  { id: "Plainfield", state_id: "New Jersey" },
  { id: "Plainfield", state_id: "Connecticut" },
  { id: "Plainfield", state_id: "Indiana" },
  { id: "Plainfield", state_id: "Illinois" },
  { id: "Plainview", state_id: "New York" },
  { id: "Plainview", state_id: "Texas" },
  { id: "Plainville", state_id: "Massachusetts" },
  { id: "Plainville", state_id: "Connecticut" },
  { id: "Plaistow", state_id: "New Hampshire" },
  { id: "Plano", state_id: "Texas" },
  { id: "Plant City", state_id: "Florida" },
  { id: "Plantation", state_id: "Florida" },
  { id: "Plaquemine", state_id: "Louisiana" },
  { id: "Plattekill", state_id: "New York" },
  { id: "Platteville", state_id: "Wisconsin" },
  { id: "Plattsburgh", state_id: "New York" },
  { id: "Plattsburgh", state_id: "New York" },
  { id: "Plattsmouth", state_id: "Nebraska" },
  { id: "Pleasant Grove", state_id: "Utah" },
  { id: "Pleasant Grove", state_id: "Alabama" },
  { id: "Pleasant Hill", state_id: "California" },
  { id: "Pleasant Hills", state_id: "Pennsylvania" },
  { id: "Pleasant Prairie", state_id: "Wisconsin" },
  { id: "Pleasant Valley", state_id: "New York" },
  { id: "Pleasanton", state_id: "Texas" },
  { id: "Pleasanton", state_id: "California" },
  { id: "Pleasantville", state_id: "New York" },
  { id: "Pleasantville", state_id: "New Jersey" },
  { id: "Pleasure Ridge Park", state_id: "Kentucky" },
  { id: "Plover", state_id: "Wisconsin" },
  { id: "Plum", state_id: "Pennsylvania" },
  { id: "Plymouth", state_id: "Pennsylvania" },
  { id: "Plymouth", state_id: "Massachusetts" },
  { id: "Plymouth", state_id: "Michigan" },
  { id: "Plymouth", state_id: "Massachusetts" },
  { id: "Plymouth", state_id: "Minnesota" },
  { id: "Plymouth", state_id: "Indiana" },
  { id: "Plymouth", state_id: "Connecticut" },
  { id: "Plymouth", state_id: "Wisconsin" },
  { id: "Plymouth Township", state_id: "Michigan" },
  { id: "Pocahontas", state_id: "Arkansas" },
  { id: "Pocatello", state_id: "Idaho" },
  { id: "Poinciana", state_id: "Florida" },
  { id: "Point Pleasant", state_id: "New Jersey" },
  { id: "Pomfret", state_id: "New York" },
  { id: "Pomona", state_id: "California" },
  { id: "Pompano Beach", state_id: "Florida" },
  { id: "Pompano Beach Highlands", state_id: "Florida" },
  { id: "Pompey", state_id: "New York" },
  { id: "Pompton Lakes", state_id: "New Jersey" },
  { id: "Ponca City", state_id: "Oklahoma" },
  { id: "Pontiac", state_id: "Illinois" },
  { id: "Pontiac", state_id: "Michigan" },
  { id: "Pooler", state_id: "Georgia" },
  { id: "Poplar Bluff", state_id: "Missouri" },
  { id: "Poquoson", state_id: "Virginia" },
  { id: "Port Angeles", state_id: "Washington" },
  { id: "Port Arthur", state_id: "Texas" },
  { id: "Port Charlotte", state_id: "Florida" },
  { id: "Port Chester", state_id: "New York" },
  { id: "Port Clinton", state_id: "Ohio" },
  { id: "Port Hueneme", state_id: "California" },
  { id: "Port Huron", state_id: "Michigan" },
  { id: "Port Jefferson", state_id: "New York" },
  { id: "Port Jefferson Station", state_id: "New York" },
  { id: "Port Jervis", state_id: "New York" },
  { id: "Port Lavaca", state_id: "Texas" },
  { id: "Port Neches", state_id: "Texas" },
  { id: "Port Orange", state_id: "Florida" },
  { id: "Port Orchard", state_id: "Washington" },
  { id: "Port Salerno", state_id: "Florida" },
  { id: "Port St. John", state_id: "Florida" },
  { id: "Port St. Lucie", state_id: "Florida" },
  { id: "Port Townsend", state_id: "Washington" },
  { id: "Port Washington", state_id: "New York" },
  { id: "Port Washington", state_id: "Wisconsin" },
  { id: "Portage", state_id: "Wisconsin" },
  { id: "Portage", state_id: "Indiana" },
  { id: "Portage", state_id: "Michigan" },
  { id: "Portage Lakes", state_id: "Ohio" },
  { id: "Portales", state_id: "New Mexico" },
  { id: "Porter", state_id: "New York" },
  { id: "Porterville", state_id: "California" },
  { id: "Portland", state_id: "Connecticut" },
  { id: "Portland", state_id: "Maine" },
  { id: "Portland", state_id: "Indiana" },
  { id: "Portland", state_id: "Tennessee" },
  { id: "Portland", state_id: "Texas" },
  { id: "Portland", state_id: "Oregon" },
  { id: "Portola Hills", state_id: "California" },
  { id: "Portsmouth", state_id: "Rhode Island" },
  { id: "Portsmouth", state_id: "Ohio" },
  { id: "Portsmouth", state_id: "New Hampshire" },
  { id: "Portsmouth", state_id: "Virginia" },
  { id: "Post Falls", state_id: "Idaho" },
  { id: "Poteau", state_id: "Oklahoma" },
  { id: "Potomac", state_id: "Maryland" },
  { id: "Potsdam", state_id: "New York" },
  { id: "Potsdam", state_id: "New York" },
  { id: "Pottstown", state_id: "Pennsylvania" },
  { id: "Pottsville", state_id: "Pennsylvania" },
  { id: "Poughkeepsie", state_id: "New York" },
  { id: "Poughkeepsie", state_id: "New York" },
  { id: "Poulsbo", state_id: "Washington" },
  { id: "Poway", state_id: "California" },
  { id: "Powder Springs", state_id: "Georgia" },
  { id: "Powell", state_id: "Ohio" },
  { id: "Prairie du Chien", state_id: "Wisconsin" },
  { id: "Prairie Ridge", state_id: "Washington" },
  { id: "Prairie Village", state_id: "Kansas" },
  { id: "Pratt", state_id: "Kansas" },
  { id: "Prattville", state_id: "Alabama" },
  { id: "Prescott", state_id: "Arizona" },
  { id: "Prescott Valley", state_id: "Arizona" },
  { id: "Presque Isle", state_id: "Maine" },
  { id: "Price", state_id: "Utah" },
  { id: "Prichard", state_id: "Alabama" },
  { id: "Prien", state_id: "Louisiana" },
  { id: "Princeton", state_id: "Kentucky" },
  { id: "Princeton", state_id: "Florida" },
  { id: "Princeton", state_id: "Indiana" },
  { id: "Princeton", state_id: "Illinois" },
  { id: "Princeton", state_id: "New Jersey" },
  { id: "Princeton", state_id: "West Virginia" },
  { id: "Princeton Meadows", state_id: "New Jersey" },
  { id: "Prineville", state_id: "Oregon" },
  { id: "Prior Lake", state_id: "Minnesota" },
  { id: "Progress", state_id: "Pennsylvania" },
  { id: "Prospect", state_id: "Connecticut" },
  { id: "Prospect Heights", state_id: "Illinois" },
  { id: "Prospect Park", state_id: "Pennsylvania" },
  { id: "Providence", state_id: "Rhode Island" },
  { id: "Provo", state_id: "Utah" },
  { id: "Prunedale", state_id: "California" },
  { id: "Pryor Creek", state_id: "Oklahoma" },
  { id: "Pueblo", state_id: "Colorado" },
  { id: "Pueblo West", state_id: "Colorado" },
  { id: "Pukalani", state_id: "Hawaii" },
  { id: "Pulaski", state_id: "Tennessee" },
  { id: "Pulaski", state_id: "Virginia" },
  { id: "Pullman", state_id: "Washington" },
  { id: "Punta Gorda", state_id: "Florida" },
  { id: "Punxsutawney", state_id: "Pennsylvania" },
  { id: "Putnam", state_id: "Connecticut" },
  { id: "Putnam District", state_id: "Connecticut" },
  { id: "Putnam Valley", state_id: "New York" },
  { id: "Puyallup", state_id: "Washington" },
  { id: "Quakertown", state_id: "Pennsylvania" },
  { id: "Quantico Station", state_id: "Virginia" },
  { id: "Quartz Hill", state_id: "California" },
  { id: "Queensbury", state_id: "New York" },
  { id: "Quincy", state_id: "Florida" },
  { id: "Quincy", state_id: "Illinois" },
  { id: "Quincy", state_id: "Massachusetts" },
  { id: "Raceland", state_id: "Louisiana" },
  { id: "Racine", state_id: "Wisconsin" },
  { id: "Radcliff", state_id: "Kentucky" },
  { id: "Radford", state_id: "Virginia" },
  { id: "Radnor Township", state_id: "Pennsylvania" },
  { id: "Rahway", state_id: "New Jersey" },
  { id: "Rainbow City", state_id: "Alabama" },
  { id: "Raleigh", state_id: "North Carolina" },
  { id: "Ralston", state_id: "Nebraska" },
  { id: "Ramapo", state_id: "New York" },
  { id: "Ramblewood", state_id: "New Jersey" },
  { id: "Ramona", state_id: "California" },
  { id: "Ramsey", state_id: "New Jersey" },
  { id: "Ramsey", state_id: "Minnesota" },
  { id: "Rancho Cordova", state_id: "California" },
  { id: "Rancho Cucamonga", state_id: "California" },
  { id: "Rancho Mirage", state_id: "California" },
  { id: "Rancho Palos Verdes", state_id: "California" },
  { id: "Rancho San Diego", state_id: "California" },
  { id: "Rancho Santa Margarita", state_id: "California" },
  { id: "Randallstown", state_id: "Maryland" },
  { id: "Randolph", state_id: "Massachusetts" },
  { id: "Randolph", state_id: "Massachusetts" },
  { id: "Rantoul", state_id: "Illinois" },
  { id: "Rapid City", state_id: "South Dakota" },
  { id: "Rapid Valley", state_id: "South Dakota" },
  { id: "Raritan", state_id: "New Jersey" },
  { id: "Raton", state_id: "New Mexico" },
  { id: "Ravenna", state_id: "Ohio" },
  { id: "Rawlins", state_id: "Wyoming" },
  { id: "Raymond", state_id: "New Hampshire" },
  { id: "Raymondville", state_id: "Texas" },
  { id: "Raymore", state_id: "Missouri" },
  { id: "Rayne", state_id: "Louisiana" },
  { id: "Raynham", state_id: "Massachusetts" },
  { id: "Raytown", state_id: "Missouri" },
  { id: "Reading", state_id: "Massachusetts" },
  { id: "Reading", state_id: "Massachusetts" },
  { id: "Reading", state_id: "Pennsylvania" },
  { id: "Reading", state_id: "Ohio" },
  { id: "Red Bank", state_id: "South Carolina" },
  { id: "Red Bank", state_id: "Tennessee" },
  { id: "Red Bank", state_id: "New Jersey" },
  { id: "Red Bluff", state_id: "California" },
  { id: "Red Hill", state_id: "South Carolina" },
  { id: "Red Hook", state_id: "New York" },
  { id: "Red Lion", state_id: "Pennsylvania" },
  { id: "Red Oak", state_id: "Iowa" },
  { id: "Red Wing", state_id: "Minnesota" },
  { id: "Redan", state_id: "Georgia" },
  { id: "Redding", state_id: "Connecticut" },
  { id: "Redding", state_id: "California" },
  { id: "Redford", state_id: "Michigan" },
  { id: "Redland", state_id: "Maryland" },
  { id: "Redlands", state_id: "California" },
  { id: "Redlands", state_id: "Colorado" },
  { id: "Redmond", state_id: "Oregon" },
  { id: "Redmond", state_id: "Washington" },
  { id: "Redondo Beach", state_id: "California" },
  { id: "Redwood City", state_id: "California" },
  { id: "Reedley", state_id: "California" },
  { id: "Reedsburg", state_id: "Wisconsin" },
  { id: "Rehoboth", state_id: "Massachusetts" },
  { id: "Reidsville", state_id: "North Carolina" },
  { id: "Reisterstown", state_id: "Maryland" },
  { id: "Rendon", state_id: "Texas" },
  { id: "Reno", state_id: "Nevada" },
  { id: "Rensselaer", state_id: "New York" },
  { id: "Renton", state_id: "Washington" },
  { id: "Republic", state_id: "Missouri" },
  { id: "Reserve", state_id: "Louisiana" },
  { id: "Reston", state_id: "Virginia" },
  { id: "Revere", state_id: "Massachusetts" },
  { id: "Rexburg", state_id: "Idaho" },
  { id: "Reynoldsburg", state_id: "Ohio" },
  { id: "Rhinebeck", state_id: "New York" },
  { id: "Rhinelander", state_id: "Wisconsin" },
  { id: "Rialto", state_id: "California" },
  { id: "Rib Mountain", state_id: "Wisconsin" },
  { id: "Rib Mountain", state_id: "Wisconsin" },
  { id: "Rice Lake", state_id: "Wisconsin" },
  { id: "Richardson", state_id: "Texas" },
  { id: "Richboro", state_id: "Pennsylvania" },
  { id: "Richfield", state_id: "Utah" },
  { id: "Richfield", state_id: "Wisconsin" },
  { id: "Richfield", state_id: "Minnesota" },
  { id: "Richland", state_id: "Washington" },
  { id: "Richland", state_id: "Mississippi" },
  { id: "Richland Hills", state_id: "Texas" },
  { id: "Richmond", state_id: "Texas" },
  { id: "Richmond", state_id: "Rhode Island" },
  { id: "Richmond", state_id: "Virginia" },
  { id: "Richmond", state_id: "Missouri" },
  { id: "Richmond", state_id: "Kentucky" },
  { id: "Richmond", state_id: "Indiana" },
  { id: "Richmond", state_id: "California" },
  { id: "Richmond Heights", state_id: "Florida" },
  { id: "Richmond Heights", state_id: "Missouri" },
  { id: "Richmond Heights", state_id: "Ohio" },
  { id: "Richmond Hill", state_id: "Georgia" },
  { id: "Richmond West", state_id: "Florida" },
  { id: "Richton Park", state_id: "Illinois" },
  { id: "Ridge", state_id: "New York" },
  { id: "Ridgecrest", state_id: "California" },
  { id: "Ridgefield", state_id: "Connecticut" },
  { id: "Ridgefield", state_id: "Connecticut" },
  { id: "Ridgefield", state_id: "New Jersey" },
  { id: "Ridgefield Park", state_id: "New Jersey" },
  { id: "Ridgeland", state_id: "Mississippi" },
  { id: "Ridgeway", state_id: "New York" },
  { id: "Ridgewood", state_id: "New Jersey" },
  { id: "Ridley Park", state_id: "Pennsylvania" },
  { id: "Rifle", state_id: "Colorado" },
  { id: "Ringwood", state_id: "New Jersey" },
  { id: "Rio del Mar", state_id: "California" },
  { id: "Rio Grande City", state_id: "Texas" },
  { id: "Rio Linda", state_id: "California" },
  { id: "Rio Rancho", state_id: "New Mexico" },
  { id: "Ripley", state_id: "Tennessee" },
  { id: "Ripon", state_id: "Wisconsin" },
  { id: "Ripon", state_id: "California" },
  { id: "Rittman", state_id: "Ohio" },
  { id: "River Edge", state_id: "New Jersey" },
  { id: "River Falls", state_id: "Wisconsin" },
  { id: "River Forest", state_id: "Illinois" },
  { id: "River Grove", state_id: "Illinois" },
  { id: "River Oaks", state_id: "Texas" },
  { id: "River Ridge", state_id: "Louisiana" },
  { id: "River Rouge", state_id: "Michigan" },
  { id: "River Vale", state_id: "New Jersey" },
  { id: "Riverbank", state_id: "California" },
  { id: "Riverdale", state_id: "Illinois" },
  { id: "Riverdale", state_id: "Georgia" },
  { id: "Riverdale", state_id: "Utah" },
  { id: "Riverdale Park", state_id: "Maryland" },
  { id: "Riverhead", state_id: "New York" },
  { id: "Riverhead", state_id: "New York" },
  { id: "Riverside", state_id: "Ohio" },
  { id: "Riverside", state_id: "Maryland" },
  { id: "Riverside", state_id: "Illinois" },
  { id: "Riverside", state_id: "California" },
  { id: "Riverton", state_id: "Utah" },
  { id: "Riverton", state_id: "Wyoming" },
  { id: "Riverton-Boulevard Park", state_id: "Washington" },
  { id: "Riverview", state_id: "Florida" },
  { id: "Riverview", state_id: "Michigan" },
  { id: "Riviera Beach", state_id: "Maryland" },
  { id: "Riviera Beach", state_id: "Florida" },
  { id: "Roanoke", state_id: "Alabama" },
  { id: "Roanoke", state_id: "Virginia" },
  { id: "Roanoke Rapids", state_id: "North Carolina" },
  { id: "Robbins", state_id: "Illinois" },
  { id: "Robbinsdale", state_id: "Minnesota" },
  { id: "Robinson", state_id: "Illinois" },
  { id: "Robinson", state_id: "Texas" },
  { id: "Robinson Township", state_id: "Pennsylvania" },
  { id: "Robstown", state_id: "Texas" },
  { id: "Rochelle", state_id: "Illinois" },
  { id: "Rochester", state_id: "Minnesota" },
  { id: "Rochester", state_id: "Michigan" },
  { id: "Rochester", state_id: "Indiana" },
  { id: "Rochester", state_id: "New York" },
  { id: "Rochester", state_id: "New York" },
  { id: "Rochester", state_id: "New Hampshire" },
  { id: "Rochester Hills", state_id: "Michigan" },
  { id: "Rock Falls", state_id: "Illinois" },
  { id: "Rock Hill", state_id: "South Carolina" },
  { id: "Rock Island", state_id: "Illinois" },
  { id: "Rock Springs", state_id: "Wyoming" },
  { id: "Rockaway", state_id: "New Jersey" },
  { id: "Rockcreek", state_id: "Oregon" },
  { id: "Rockford", state_id: "Illinois" },
  { id: "Rockingham", state_id: "North Carolina" },
  { id: "Rockland", state_id: "Massachusetts" },
  { id: "Rockland", state_id: "Maine" },
  { id: "Rockledge", state_id: "Florida" },
  { id: "Rocklin", state_id: "California" },
  { id: "Rockport", state_id: "Massachusetts" },
  { id: "Rockport", state_id: "Texas" },
  { id: "Rockville", state_id: "Maryland" },
  { id: "Rockville", state_id: "Connecticut" },
  { id: "Rockville Centre", state_id: "New York" },
  { id: "Rockwall", state_id: "Texas" },
  { id: "Rocky Hill", state_id: "Connecticut" },
  { id: "Rocky Mount", state_id: "North Carolina" },
  { id: "Rocky Point", state_id: "New York" },
  { id: "Rocky River", state_id: "Ohio" },
  { id: "Rodeo", state_id: "California" },
  { id: "Roeland Park", state_id: "Kansas" },
  { id: "Rogers", state_id: "Arkansas" },
  { id: "Rohnert Park", state_id: "California" },
  { id: "Rolla", state_id: "Missouri" },
  { id: "Rolling Hills Estates", state_id: "California" },
  { id: "Rolling Meadows", state_id: "Illinois" },
  { id: "Roma", state_id: "Texas" },
  { id: "Rome", state_id: "New York" },
  { id: "Rome", state_id: "Georgia" },
  { id: "Romeoville", state_id: "Illinois" },
  { id: "Romulus", state_id: "Michigan" },
  { id: "Ronkonkoma", state_id: "New York" },
  { id: "Roosevelt", state_id: "New York" },
  { id: "Rosamond", state_id: "California" },
  { id: "Rosaryville", state_id: "Maryland" },
  { id: "Roscoe", state_id: "Illinois" },
  { id: "Rose Hill", state_id: "Virginia" },
  { id: "Roseburg", state_id: "Oregon" },
  { id: "Rosedale", state_id: "Maryland" },
  { id: "Rosedale", state_id: "California" },
  { id: "Roseland", state_id: "California" },
  { id: "Roselle", state_id: "Illinois" },
  { id: "Roselle", state_id: "New Jersey" },
  { id: "Roselle Park", state_id: "New Jersey" },
  { id: "Rosemead", state_id: "California" },
  { id: "Rosemont", state_id: "California" },
  { id: "Rosemount", state_id: "Minnesota" },
  { id: "Rosenberg", state_id: "Texas" },
  { id: "Rosendale", state_id: "New York" },
  { id: "Roseville", state_id: "Minnesota" },
  { id: "Roseville", state_id: "Michigan" },
  { id: "Roseville", state_id: "California" },
  { id: "Roslyn Heights", state_id: "New York" },
  { id: "Ross Township", state_id: "Pennsylvania" },
  { id: "Rossford", state_id: "Ohio" },
  { id: "Rossmoor", state_id: "California" },
  { id: "Rossmoor", state_id: "Maryland" },
  { id: "Rossville", state_id: "Maryland" },
  { id: "Roswell", state_id: "Georgia" },
  { id: "Roswell", state_id: "New Mexico" },
  { id: "Rotonda", state_id: "Florida" },
  { id: "Rotterdam", state_id: "New York" },
  { id: "Rotterdam", state_id: "New York" },
  { id: "Round Lake Beach", state_id: "Illinois" },
  { id: "Round Lake Park", state_id: "Illinois" },
  { id: "Round Rock", state_id: "Texas" },
  { id: "Rowland Heights", state_id: "California" },
  { id: "Rowlett", state_id: "Texas" },
  { id: "Roxboro", state_id: "North Carolina" },
  { id: "Roy", state_id: "Utah" },
  { id: "Royal Oak", state_id: "Michigan" },
  { id: "Royal Palm Beach", state_id: "Florida" },
  { id: "Royalton", state_id: "New York" },
  { id: "Rubidoux", state_id: "California" },
  { id: "Ruidoso", state_id: "New Mexico" },
  { id: "Rumford", state_id: "Maine" },
  { id: "Rumson", state_id: "New Jersey" },
  { id: "Runnemede", state_id: "New Jersey" },
  { id: "Ruskin", state_id: "Florida" },
  { id: "Russellville", state_id: "Arkansas" },
  { id: "Russellville", state_id: "Alabama" },
  { id: "Russellville", state_id: "Kentucky" },
  { id: "Ruston", state_id: "Louisiana" },
  { id: "Rutherford", state_id: "New Jersey" },
  { id: "Rutland", state_id: "Massachusetts" },
  { id: "Rutland", state_id: "Vermont" },
  { id: "Rye", state_id: "New York" },
  { id: "Rye", state_id: "New York" },
  { id: "Rye Brook", state_id: "New York" },
  { id: "Sachse", state_id: "Texas" },
  { id: "Saco", state_id: "Maine" },
  { id: "Sacramento", state_id: "California" },
  { id: "Saddle Brook", state_id: "New Jersey" },
  { id: "Safety Harbor", state_id: "Florida" },
  { id: "Safford", state_id: "Arizona" },
  { id: "Saginaw", state_id: "Michigan" },
  { id: "Saginaw", state_id: "Texas" },
  { id: "Saginaw Township North", state_id: "Michigan" },
  { id: "Saginaw Township South", state_id: "Michigan" },
  { id: "Saks", state_id: "Alabama" },
  { id: "Salamanca", state_id: "New York" },
  { id: "Salem", state_id: "New Hampshire" },
  { id: "Salem", state_id: "Ohio" },
  { id: "Salem", state_id: "Oregon" },
  { id: "Salem", state_id: "Virginia" },
  { id: "Salem", state_id: "Wisconsin" },
  { id: "Salem", state_id: "Massachusetts" },
  { id: "Salem", state_id: "Illinois" },
  { id: "Salem", state_id: "Indiana" },
  { id: "Salida", state_id: "California" },
  { id: "Salina", state_id: "Kansas" },
  { id: "Salina", state_id: "New York" },
  { id: "Salinas", state_id: "California" },
  { id: "Saline", state_id: "Michigan" },
  { id: "Salisbury", state_id: "Massachusetts" },
  { id: "Salisbury", state_id: "Maryland" },
  { id: "Salisbury", state_id: "New York" },
  { id: "Salisbury", state_id: "North Carolina" },
  { id: "Sallisaw", state_id: "Oklahoma" },
  { id: "Salmon Creek", state_id: "Washington" },
  { id: "Salt Lake City", state_id: "Utah" },
  { id: "Sammamish", state_id: "Washington" },
  { id: "San Angelo", state_id: "Texas" },
  { id: "San Anselmo", state_id: "California" },
  { id: "San Antonio", state_id: "Texas" },
  { id: "San Benito", state_id: "Texas" },
  { id: "San Bernardino", state_id: "California" },
  { id: "San Bruno", state_id: "California" },
  { id: "San Buenaventura", state_id: "California" },
  { id: "San Carlos", state_id: "California" },
  { id: "San Carlos Park", state_id: "Florida" },
  { id: "San Clemente", state_id: "California" },
  { id: "San Diego", state_id: "California" },
  { id: "San Diego Country Estates", state_id: "California" },
  { id: "San Dimas", state_id: "California" },
  { id: "San Elizario", state_id: "Texas" },
  { id: "Pottsboro", state_id: "Texas" },
  { id: "San Fernando", state_id: "California" },
  { id: "San Francisco", state_id: "California" },
  { id: "San Gabriel", state_id: "California" },
  { id: "San Jacinto", state_id: "California" },
  { id: "San Jose", state_id: "California" },
  { id: "San Juan", state_id: "Texas" },
  { id: "San Juan Capistrano", state_id: "California" },
  { id: "San Leandro", state_id: "California" },
  { id: "San Lorenzo", state_id: "California" },
  { id: "San Luis", state_id: "Arizona" },
  { id: "San Luis Obispo", state_id: "California" },
  { id: "San Marcos", state_id: "California" },
  { id: "San Marcos", state_id: "Texas" },
  { id: "San Marino", state_id: "California" },
  { id: "San Mateo", state_id: "California" },
  { id: "San Pablo", state_id: "California" },
  { id: "San Rafael", state_id: "California" },
  { id: "San Ramon", state_id: "California" },
  { id: "Sanatoga", state_id: "Pennsylvania" },
  { id: "Sand Lake", state_id: "New York" },
  { id: "Sand Springs", state_id: "Oklahoma" },
  { id: "Sandalfoot Cove", state_id: "Florida" },
  { id: "Sandersville", state_id: "Georgia" },
  { id: "Sandpoint", state_id: "Idaho" },
  { id: "Sandusky", state_id: "Ohio" },
  { id: "Sandusky South", state_id: "Ohio" },
  { id: "Sandwich", state_id: "Illinois" },
  { id: "Sandwich", state_id: "Massachusetts" },
  { id: "Sandy", state_id: "Utah" },
  { id: "Sandy Springs", state_id: "Georgia" },
  { id: "Sanford", state_id: "Florida" },
  { id: "Sanford", state_id: "Maine" },
  { id: "Sanford", state_id: "Maine" },
  { id: "Sanford", state_id: "North Carolina" },
  { id: "Sanger", state_id: "California" },
  { id: "Sanibel", state_id: "Florida" },
  { id: "Sans Souci", state_id: "South Carolina" },
  { id: "Santa Ana", state_id: "California" },
  { id: "Santa Barbara", state_id: "California" },
  { id: "Santa Clara", state_id: "California" },
  { id: "Santa Clarita", state_id: "California" },
  { id: "Santa Cruz", state_id: "California" },
  { id: "Santa Fe", state_id: "New Mexico" },
  { id: "Santa Fe", state_id: "Texas" },
  { id: "Santa Fe Springs", state_id: "California" },
  { id: "Santa Maria", state_id: "California" },
  { id: "Santa Monica", state_id: "California" },
  { id: "Santa Paula", state_id: "California" },
  { id: "Santa Rosa", state_id: "California" },
  { id: "Santee", state_id: "California" },
  { id: "Sappington", state_id: "Missouri" },
  { id: "Sapulpa", state_id: "Oklahoma" },
  { id: "Saraland", state_id: "Alabama" },
  { id: "Sarasota", state_id: "Florida" },
  { id: "Sarasota Springs", state_id: "Florida" },
  { id: "Saratoga", state_id: "California" },
  { id: "Saratoga Springs", state_id: "New York" },
  { id: "Sartell", state_id: "Minnesota" },
  { id: "Satellite Beach", state_id: "Florida" },
  { id: "Saugerties", state_id: "New York" },
  { id: "Saugus", state_id: "Massachusetts" },
  { id: "Saugus", state_id: "Massachusetts" },
  { id: "Sauk Rapids", state_id: "Minnesota" },
  { id: "Sauk Village", state_id: "Illinois" },
  { id: "Sault Ste. Marie", state_id: "Michigan" },
  { id: "Sausalito", state_id: "California" },
  { id: "Savage", state_id: "Minnesota" },
  { id: "Savage-Guilford", state_id: "Maryland" },
  { id: "Savannah", state_id: "Georgia" },
  { id: "Savannah", state_id: "Tennessee" },
  { id: "Sayreville", state_id: "New Jersey" },
  { id: "Sayville", state_id: "New York" },
  { id: "Scarborough", state_id: "Maine" },
  { id: "Scarsdale", state_id: "New York" },
  { id: "Scarsdale", state_id: "New York" },
  { id: "Schaghticoke", state_id: "New York" },
  { id: "Schaumburg", state_id: "Illinois" },
  { id: "Schenectady", state_id: "New York" },
  { id: "Schererville", state_id: "Indiana" },
  { id: "Schertz", state_id: "Texas" },
  { id: "Schiller Park", state_id: "Illinois" },
  { id: "Schodack", state_id: "New York" },
  { id: "Schofield Barracks", state_id: "Hawaii" },
  { id: "Schroeppel", state_id: "New York" },
  { id: "Scituate", state_id: "Rhode Island" },
  { id: "Scituate", state_id: "Massachusetts" },
  { id: "Scotch Plains", state_id: "New Jersey" },
  { id: "Scotchtown", state_id: "New York" },
  { id: "Scotia", state_id: "New York" },
  { id: "Scott", state_id: "Louisiana" },
  { id: "Scott Lake", state_id: "Florida" },
  { id: "Scott Township", state_id: "Pennsylvania" },
  { id: "Scottdale", state_id: "Georgia" },
  { id: "Scotts Valley", state_id: "California" },
  { id: "Scottsbluff", state_id: "Nebraska" },
  { id: "Scottsboro", state_id: "Alabama" },
  { id: "Scottsburg", state_id: "Indiana" },
  { id: "Scottsdale", state_id: "Arizona" },
  { id: "Scranton", state_id: "Pennsylvania" },
  { id: "Scriba", state_id: "New York" },
  { id: "Seabrook", state_id: "New Hampshire" },
  { id: "Seabrook", state_id: "Texas" },
  { id: "Seaford", state_id: "New York" },
  { id: "Seaford", state_id: "Delaware" },
  { id: "Seagoville", state_id: "Texas" },
  { id: "Seal Beach", state_id: "California" },
  { id: "Searcy", state_id: "Arkansas" },
  { id: "Seaside", state_id: "California" },
  { id: "SeaTac", state_id: "Washington" },
  { id: "Seattle", state_id: "Washington" },
  { id: "Seattle Hill-Silver Firs", state_id: "Washington" },
  { id: "Sebastian", state_id: "Florida" },
  { id: "Sebastopol", state_id: "California" },
  { id: "Sebring", state_id: "Florida" },
  { id: "Secaucus", state_id: "New Jersey" },
  { id: "Security-Widefield", state_id: "Colorado" },
  { id: "Sedalia", state_id: "Missouri" },
  { id: "Sedona", state_id: "Arizona" },
  { id: "Sedro-Woolley", state_id: "Washington" },
  { id: "Seekonk", state_id: "Massachusetts" },
  { id: "Seguin", state_id: "Texas" },
  { id: "Selah", state_id: "Washington" },
  { id: "Selden", state_id: "New York" },
  { id: "Sellersburg", state_id: "Indiana" },
  { id: "Selma", state_id: "Alabama" },
  { id: "Selma", state_id: "California" },
  { id: "Seminole", state_id: "Florida" },
  { id: "Seminole", state_id: "Oklahoma" },
  { id: "Senatobia", state_id: "Mississippi" },
  { id: "Seneca", state_id: "South Carolina" },
  { id: "Seneca Falls", state_id: "New York" },
  { id: "Seneca Falls", state_id: "New York" },
  { id: "Setauket-East Setauket", state_id: "New York" },
  { id: "Seven Corners", state_id: "Virginia" },
  { id: "Seven Hills", state_id: "Ohio" },
  { id: "Seven Oaks", state_id: "South Carolina" },
  { id: "Severn", state_id: "Maryland" },
  { id: "Severna Park", state_id: "Maryland" },
  { id: "Sevierville", state_id: "Tennessee" },
  { id: "Seward", state_id: "Nebraska" },
  { id: "Seymour", state_id: "Tennessee" },
  { id: "Seymour", state_id: "Indiana" },
  { id: "Seymour", state_id: "Connecticut" },
  { id: "Shady Hills", state_id: "Florida" },
  { id: "Shafter", state_id: "California" },
  { id: "Shaker Heights", state_id: "Ohio" },
  { id: "Shakopee", state_id: "Minnesota" },
  { id: "Shaler Township", state_id: "Pennsylvania" },
  { id: "Shamokin", state_id: "Pennsylvania" },
  { id: "Sharon", state_id: "Pennsylvania" },
  { id: "Sharon", state_id: "Massachusetts" },
  { id: "Sharonville", state_id: "Ohio" },
  { id: "Shasta Lake", state_id: "California" },
  { id: "Shawangunk", state_id: "New York" },
  { id: "Shawano", state_id: "Wisconsin" },
  { id: "Shawnee", state_id: "Oklahoma" },
  { id: "Shawnee", state_id: "Kansas" },
  { id: "Sheboygan", state_id: "Wisconsin" },
  { id: "Sheboygan Falls", state_id: "Wisconsin" },
  { id: "Sheffield", state_id: "Alabama" },
  { id: "Sheffield Lake", state_id: "Ohio" },
  { id: "Shelburne", state_id: "Vermont" },
  { id: "Shelby", state_id: "Ohio" },
  { id: "Shelby", state_id: "North Carolina" },
  { id: "Shelby", state_id: "Michigan" },
  { id: "Shelbyville", state_id: "Kentucky" },
  { id: "Shelbyville", state_id: "Indiana" },
  { id: "Shelbyville", state_id: "Tennessee" },
  { id: "Shelton", state_id: "Washington" },
  { id: "Shelton", state_id: "Connecticut" },
  { id: "Shelton", state_id: "Connecticut" },
  { id: "Shenandoah", state_id: "Louisiana" },
  { id: "Shepherdsville", state_id: "Kentucky" },
  { id: "Sheridan", state_id: "Wyoming" },
  { id: "Sherman", state_id: "Texas" },
  { id: "Sherrelwood", state_id: "Colorado" },
  { id: "Sherwood", state_id: "Arkansas" },
  { id: "Sherwood", state_id: "Oregon" },
  { id: "Shields", state_id: "Michigan" },
  { id: "Shiloh", state_id: "Illinois" },
  { id: "Shiloh", state_id: "Pennsylvania" },
  { id: "Shiloh", state_id: "Ohio" },
  { id: "Shiprock", state_id: "New Mexico" },
  { id: "Shirley", state_id: "New York" },
  { id: "Shirley", state_id: "Massachusetts" },
  { id: "Shively", state_id: "Kentucky" },
  { id: "Shoreline", state_id: "Washington" },
  { id: "Shoreview", state_id: "Minnesota" },
  { id: "Shorewood", state_id: "Minnesota" },
  { id: "Shorewood", state_id: "Illinois" },
  { id: "Shorewood", state_id: "Wisconsin" },
  { id: "Show Low", state_id: "Arizona" },
  { id: "Shreveport", state_id: "Louisiana" },
  { id: "Shrewsbury", state_id: "Missouri" },
  { id: "Shrewsbury", state_id: "Massachusetts" },
  { id: "Sidney", state_id: "New York" },
  { id: "Sidney", state_id: "Nebraska" },
  { id: "Sidney", state_id: "Ohio" },
  { id: "Sierra Madre", state_id: "California" },
  { id: "Sierra Vista", state_id: "Arizona" },
  { id: "Sierra Vista Southeast", state_id: "Arizona" },
  { id: "Siesta Key", state_id: "Florida" },
  { id: "Signal Hill", state_id: "California" },
  { id: "Signal Mountain", state_id: "Tennessee" },
  { id: "Sikeston", state_id: "Missouri" },
  { id: "Siler City", state_id: "North Carolina" },
  { id: "Siloam Springs", state_id: "Arkansas" },
  { id: "Silsbee", state_id: "Texas" },
  { id: "Silver City", state_id: "New Mexico" },
  { id: "Silver Spring", state_id: "Maryland" },
  { id: "Silver Springs Shores", state_id: "Florida" },
  { id: "Silverdale", state_id: "Washington" },
  { id: "Silverton", state_id: "Oregon" },
  { id: "Silvis", state_id: "Illinois" },
  { id: "Simi Valley", state_id: "California" },
  { id: "Simpsonville", state_id: "South Carolina" },
  { id: "Simsbury", state_id: "Connecticut" },
  { id: "Sioux Center", state_id: "Iowa" },
  { id: "Sioux City", state_id: "Iowa" },
  { id: "Sioux Falls", state_id: "South Dakota" },
  { id: "Sitka and", state_id: "Alaska" },
  { id: "Skaneateles", state_id: "New York" },
  { id: "Skidaway Island", state_id: "Georgia" },
  { id: "Skokie", state_id: "Illinois" },
  { id: "Skowhegan", state_id: "Maine" },
  { id: "Skowhegan", state_id: "Maine" },
  { id: "Slaton", state_id: "Texas" },
  { id: "Sleepy Hollow", state_id: "New York" },
  { id: "Slidell", state_id: "Louisiana" },
  { id: "Smithfield", state_id: "North Carolina" },
  { id: "Smithfield", state_id: "Rhode Island" },
  { id: "Smithfield", state_id: "Utah" },
  { id: "Smithfield", state_id: "Virginia" },
  { id: "Smiths", state_id: "Alabama" },
  { id: "Smithtown", state_id: "New York" },
  { id: "Smithtown", state_id: "New York" },
  { id: "Smyrna", state_id: "Tennessee" },
  { id: "Smyrna", state_id: "Georgia" },
  { id: "Snellville", state_id: "Georgia" },
  { id: "Snohomish", state_id: "Washington" },
  { id: "Snyder", state_id: "Texas" },
  { id: "Socastee", state_id: "South Carolina" },
  { id: "Socorro", state_id: "New Mexico" },
  { id: "Socorro", state_id: "Texas" },
  { id: "Soddy-Daisy", state_id: "Tennessee" },
  { id: "Sodus", state_id: "New York" },
  { id: "Solana Beach", state_id: "California" },
  { id: "Soledad", state_id: "California" },
  { id: "Solon", state_id: "Ohio" },
  { id: "Solvay", state_id: "New York" },
  { id: "Somers", state_id: "New York" },
  { id: "Somers", state_id: "Wisconsin" },
  { id: "Somers", state_id: "Connecticut" },
  { id: "Somers Point", state_id: "New Jersey" },
  { id: "Somerset", state_id: "New Jersey" },
  { id: "Somerset", state_id: "Pennsylvania" },
  { id: "Somerset", state_id: "Kentucky" },
  { id: "Somerset", state_id: "Massachusetts" },
  { id: "Somerset", state_id: "Massachusetts" },
  { id: "Somersworth", state_id: "New Hampshire" },
  { id: "Somerton", state_id: "Arizona" },
  { id: "Somerville", state_id: "Massachusetts" },
  { id: "Somerville", state_id: "New Jersey" },
  { id: "Sonoma", state_id: "California" },
  { id: "Souderton", state_id: "Pennsylvania" },
  { id: "Sound Beach", state_id: "New York" },
  { id: "South Amboy", state_id: "New Jersey" },
  { id: "South Bend", state_id: "Indiana" },
  { id: "South Berwick", state_id: "Maine" },
  { id: "South Boston", state_id: "Virginia" },
  { id: "South Bradenton", state_id: "Florida" },
  { id: "South Burlington", state_id: "Vermont" },
  { id: "South Charleston", state_id: "West Virginia" },
  { id: "South Cleveland", state_id: "Tennessee" },
  { id: "South Daytona", state_id: "Florida" },
  { id: "South El Monte", state_id: "California" },
  { id: "South Elgin", state_id: "Illinois" },
  { id: "South Euclid", state_id: "Ohio" },
  { id: "South Farmingdale", state_id: "New York" },
  { id: "South Gate", state_id: "Maryland" },
  { id: "South Gate", state_id: "California" },
  { id: "South Hadley", state_id: "Massachusetts" },
  { id: "South Highpoint", state_id: "Florida" },
  { id: "South Hill", state_id: "New York" },
  { id: "South Hill", state_id: "Washington" },
  { id: "South Holland", state_id: "Illinois" },
  { id: "South Houston", state_id: "Texas" },
  { id: "South Huntington", state_id: "New York" },
  { id: "South Jordan", state_id: "Utah" },
  { id: "South Kensington", state_id: "Maryland" },
  { id: "South Kingstown", state_id: "Rhode Island" },
  { id: "South Lake Tahoe", state_id: "California" },
  { id: "South Laurel", state_id: "Maryland" },
  { id: "South Lockport", state_id: "New York" },
  { id: "South Lyon", state_id: "Michigan" },
  { id: "South Miami", state_id: "Florida" },
  { id: "South Miami Heights", state_id: "Florida" },
  { id: "South Milwaukee", state_id: "Wisconsin" },
  { id: "South Monroe", state_id: "Michigan" },
  { id: "South Ogden", state_id: "Utah" },
  { id: "South Orange", state_id: "New Jersey" },
  { id: "South Oroville", state_id: "California" },
  { id: "South Park Township", state_id: "Pennsylvania" },
  { id: "South Pasadena", state_id: "California" },
  { id: "South Patrick Shores", state_id: "Florida" },
  { id: "South Plainfield", state_id: "New Jersey" },
  { id: "South Portland", state_id: "Maine" },
  { id: "South River", state_id: "New Jersey" },
  { id: "South Salt Lake", state_id: "Utah" },
  { id: "South San Francisco", state_id: "California" },
  { id: "South San Gabriel", state_id: "California" },
  { id: "South San Jose Hills", state_id: "California" },
  { id: "South Sioux City", state_id: "Nebraska" },
  { id: "South St. Paul", state_id: "Minnesota" },
  { id: "South Valley", state_id: "New Mexico" },
  { id: "South Venice", state_id: "Florida" },
  { id: "South Whittier", state_id: "California" },
  { id: "South Williamsport", state_id: "Pennsylvania" },
  { id: "South Windsor", state_id: "Connecticut" },
  { id: "South Yarmouth", state_id: "Massachusetts" },
  { id: "South Yuba City", state_id: "California" },
  { id: "Southampton", state_id: "New York" },
  { id: "Southaven", state_id: "Mississippi" },
  { id: "Southborough", state_id: "Massachusetts" },
  { id: "Southbridge", state_id: "Massachusetts" },
  { id: "Southbridge", state_id: "Massachusetts" },
  { id: "Southbury", state_id: "Connecticut" },
  { id: "Southeast", state_id: "New York" },
  { id: "Southeast Arcadia", state_id: "Florida" },
  { id: "Southern Pines", state_id: "North Carolina" },
  { id: "Southfield", state_id: "Michigan" },
  { id: "Southgate", state_id: "Michigan" },
  { id: "Southgate", state_id: "Florida" },
  { id: "Southglenn", state_id: "Colorado" },
  { id: "Southington", state_id: "Connecticut" },
  { id: "Southlake", state_id: "Texas" },
  { id: "Southold", state_id: "New York" },
  { id: "Southport", state_id: "New York" },
  { id: "Southport", state_id: "New York" },
  { id: "Southside", state_id: "Alabama" },
  { id: "Southwick", state_id: "Massachusetts" },
  { id: "Southwood Acres", state_id: "Connecticut" },
  { id: "Spanaway", state_id: "Washington" },
  { id: "Spanish Fork", state_id: "Utah" },
  { id: "Spanish Lake", state_id: "Missouri" },
  { id: "Spanish Springs", state_id: "Nevada" },
  { id: "Sparks", state_id: "Nevada" },
  { id: "Sparta", state_id: "Wisconsin" },
  { id: "Spartanburg", state_id: "South Carolina" },
  { id: "Spearfish", state_id: "South Dakota" },
  { id: "Speedway", state_id: "Indiana" },
  { id: "Spencer", state_id: "Iowa" },
  { id: "Spencer", state_id: "Massachusetts" },
  { id: "Spencer", state_id: "Massachusetts" },
  { id: "Spokane", state_id: "Washington" },
  { id: "Spotswood", state_id: "New Jersey" },
  { id: "Spring", state_id: "Texas" },
  { id: "Spring Creek", state_id: "Nevada" },
  { id: "Spring Hill", state_id: "Tennessee" },
  { id: "Spring Hill", state_id: "Florida" },
  { id: "Spring Lake", state_id: "North Carolina" },
  { id: "Spring Lake Park", state_id: "Minnesota" },
  { id: "Spring Valley", state_id: "California" },
  { id: "Spring Valley", state_id: "Nevada" },
  { id: "Spring Valley", state_id: "New York" },
  { id: "Springboro", state_id: "Ohio" },
  { id: "Springdale", state_id: "Ohio" },
  { id: "Springdale", state_id: "New Jersey" },
  { id: "Springdale", state_id: "Arkansas" },
  { id: "Springfield", state_id: "Florida" },
  { id: "Springfield", state_id: "Missouri" },
  { id: "Springfield", state_id: "Massachusetts" },
  { id: "Springfield", state_id: "Illinois" },
  { id: "Springfield", state_id: "New Jersey" },
  { id: "Springfield", state_id: "Ohio" },
  { id: "Springfield", state_id: "Oregon" },
  { id: "Springfield", state_id: "Pennsylvania" },
  { id: "Springfield", state_id: "Vermont" },
  { id: "Springfield", state_id: "Tennessee" },
  { id: "Springfield", state_id: "Virginia" },
  { id: "Springville", state_id: "Utah" },
  { id: "St. Albans", state_id: "Vermont" },
  { id: "St. Albans", state_id: "West Virginia" },
  { id: "St. Andrews", state_id: "South Carolina" },
  { id: "St. Ann", state_id: "Missouri" },
  { id: "St. Anthony", state_id: "Minnesota" },
  { id: "St. Augustine", state_id: "Florida" },
  { id: "St. Charles", state_id: "Missouri" },
  { id: "St. Charles", state_id: "Illinois" },
  { id: "St. Charles", state_id: "Maryland" },
  { id: "St. Clair Shores", state_id: "Michigan" },
  { id: "St. Cloud", state_id: "Minnesota" },
  { id: "St. Cloud", state_id: "Florida" },
  { id: "St. Dennis", state_id: "Kentucky" },
  { id: "St. Francis", state_id: "Wisconsin" },
  { id: "St. George", state_id: "Utah" },
  { id: "St. Helens", state_id: "Oregon" },
  { id: "St. James", state_id: "New York" },
  { id: "St. John", state_id: "Indiana" },
  { id: "St. John", state_id: "Missouri" },
  { id: "St. Johns", state_id: "Michigan" },
  { id: "St. Johnsbury", state_id: "Vermont" },
  { id: "St. Johnsbury", state_id: "Vermont" },
  { id: "St. Joseph", state_id: "Michigan" },
  { id: "St. Joseph", state_id: "Missouri" },
  { id: "St. Louis", state_id: "Missouri" },
  { id: "St. Louis Park", state_id: "Minnesota" },
  { id: "St. Martin", state_id: "Mississippi" },
  { id: "St. Martinville", state_id: "Louisiana" },
  { id: "St. Marys", state_id: "Georgia" },
  { id: "St. Marys", state_id: "Pennsylvania" },
  { id: "St. Marys", state_id: "Ohio" },
  { id: "St. Matthews", state_id: "Kentucky" },
  { id: "St. Michael", state_id: "Minnesota" },
  { id: "St. Paul", state_id: "Minnesota" },
  { id: "St. Pete Beach", state_id: "Florida" },
  { id: "St. Peter", state_id: "Minnesota" },
  { id: "St. Peters", state_id: "Missouri" },
  { id: "St. Petersburg", state_id: "Florida" },
  { id: "St. Rose", state_id: "Louisiana" },
  { id: "St. Simons", state_id: "Georgia" },
  { id: "St. Stephens", state_id: "North Carolina" },
  { id: "Stafford", state_id: "Texas" },
  { id: "Stafford", state_id: "Connecticut" },
  { id: "Stamford", state_id: "Connecticut" },
  { id: "Stamford", state_id: "Connecticut" },
  { id: "Standish", state_id: "Maine" },
  { id: "Stanford", state_id: "California" },
  { id: "Stanton", state_id: "California" },
  { id: "Starkville", state_id: "Mississippi" },
  { id: "State College", state_id: "Pennsylvania" },
  { id: "Statesboro", state_id: "Georgia" },
  { id: "Statesville", state_id: "North Carolina" },
  { id: "Staunton", state_id: "Virginia" },
  { id: "Stayton", state_id: "Oregon" },
  { id: "Steamboat Springs", state_id: "Colorado" },
  { id: "Steger", state_id: "Illinois" },
  { id: "Steilacoom", state_id: "Washington" },
  { id: "Stephenville", state_id: "Texas" },
  { id: "Sterling", state_id: "Illinois" },
  { id: "Sterling", state_id: "Massachusetts" },
  { id: "Sterling", state_id: "Colorado" },
  { id: "Sterling Heights", state_id: "Michigan" },
  { id: "Steubenville", state_id: "Ohio" },
  { id: "Stevens Point", state_id: "Wisconsin" },
  { id: "Stickney", state_id: "Illinois" },
  { id: "Stillwater", state_id: "Minnesota" },
  { id: "Stillwater", state_id: "New York" },
  { id: "Stillwater", state_id: "Oklahoma" },
  { id: "Stockbridge", state_id: "Georgia" },
  { id: "Stockton", state_id: "California" },
  { id: "Stone Mountain", state_id: "Georgia" },
  { id: "Stonegate", state_id: "Colorado" },
  { id: "Stoneham", state_id: "Massachusetts" },
  { id: "Stoneham", state_id: "Massachusetts" },
  { id: "Stonington", state_id: "Connecticut" },
  { id: "Stony Brook", state_id: "New York" },
  { id: "Stony Point", state_id: "New York" },
  { id: "Stony Point", state_id: "New York" },
  { id: "Storm Lake", state_id: "Iowa" },
  { id: "Storrs", state_id: "Connecticut" },
  { id: "Stoughton", state_id: "Massachusetts" },
  { id: "Stoughton", state_id: "Wisconsin" },
  { id: "Stow", state_id: "Ohio" },
  { id: "Stowe Township", state_id: "Pennsylvania" },
  { id: "Stratford", state_id: "New Jersey" },
  { id: "Stratford", state_id: "Connecticut" },
  { id: "Stratford", state_id: "Connecticut" },
  { id: "Stratham", state_id: "New Hampshire" },
  { id: "Strathmore", state_id: "New Jersey" },
  { id: "Stratmoor", state_id: "Colorado" },
  { id: "Streamwood", state_id: "Illinois" },
  { id: "Streator", state_id: "Illinois" },
  { id: "Streetsboro", state_id: "Ohio" },
  { id: "Strongsville", state_id: "Ohio" },
  { id: "Struthers", state_id: "Ohio" },
  { id: "Stuart", state_id: "Florida" },
  { id: "Stuarts Draft", state_id: "Virginia" },
  { id: "Sturbridge", state_id: "Massachusetts" },
  { id: "Sturgeon Bay", state_id: "Wisconsin" },
  { id: "Sturgis", state_id: "South Dakota" },
  { id: "Sturgis", state_id: "Michigan" },
  { id: "Stuttgart", state_id: "Arkansas" },
  { id: "Suamico", state_id: "Wisconsin" },
  { id: "Succasunna-Kenvil", state_id: "New Jersey" },
  { id: "Sudbury", state_id: "Massachusetts" },
  { id: "Sudley", state_id: "Virginia" },
  { id: "Suffern", state_id: "New York" },
  { id: "Suffield", state_id: "Connecticut" },
  { id: "Suffolk", state_id: "Virginia" },
  { id: "Sugar Hill", state_id: "Georgia" },
  { id: "Sugar Land", state_id: "Texas" },
  { id: "Sugarmill Woods", state_id: "Florida" },
  { id: "Suisun City", state_id: "California" },
  { id: "Suitland-Silver Hill", state_id: "Maryland" },
  { id: "Sullivan", state_id: "Missouri" },
  { id: "Sullivan", state_id: "New York" },
  { id: "Sulphur", state_id: "Louisiana" },
  { id: "Sulphur Springs", state_id: "Texas" },
  { id: "Summerfield", state_id: "North Carolina" },
  { id: "Summerville", state_id: "South Carolina" },
  { id: "Summit", state_id: "New Jersey" },
  { id: "Summit", state_id: "Washington" },
  { id: "Summit", state_id: "Illinois" },
  { id: "Summit Park", state_id: "Utah" },
  { id: "Sumner", state_id: "Washington" },
  { id: "Sumter", state_id: "South Carolina" },
  { id: "Sun City", state_id: "California" },
  { id: "Sun City", state_id: "Arizona" },
  { id: "Sun City West", state_id: "Arizona" },
  { id: "Sun Lakes", state_id: "Arizona" },
  { id: "Sun Prairie", state_id: "Wisconsin" },
  { id: "Sun Valley", state_id: "Nevada" },
  { id: "Sunbury", state_id: "Pennsylvania" },
  { id: "Sunland Park", state_id: "New Mexico" },
  { id: "Sunny Isles Beach", state_id: "Florida" },
  { id: "Sunnyside", state_id: "Oregon" },
  { id: "Sunnyside", state_id: "Washington" },
  { id: "Sunnyvale", state_id: "California" },
  { id: "Sunrise", state_id: "Florida" },
  { id: "Sunrise Manor", state_id: "Nevada" },
  { id: "Sunset", state_id: "Florida" },
  { id: "Sunset Hills", state_id: "Missouri" },
  { id: "Superior", state_id: "Colorado" },
  { id: "Superior", state_id: "Wisconsin" },
  { id: "Surprise", state_id: "Arizona" },
  { id: "Susanville", state_id: "California" },
  { id: "Sussex", state_id: "Wisconsin" },
  { id: "Sutherlin", state_id: "Oregon" },
  { id: "Sutton", state_id: "Massachusetts" },
  { id: "Suwanee", state_id: "Georgia" },
  { id: "Swainsboro", state_id: "Georgia" },
  { id: "Swampscott", state_id: "Massachusetts" },
  { id: "Swampscott", state_id: "Massachusetts" },
  { id: "Swansea", state_id: "Illinois" },
  { id: "Swansea", state_id: "Massachusetts" },
  { id: "Swanton", state_id: "Vermont" },
  { id: "Swanzey", state_id: "New Hampshire" },
  { id: "Swarthmore", state_id: "Pennsylvania" },
  { id: "Sweden", state_id: "New York" },
  { id: "Sweet Home", state_id: "Oregon" },
  { id: "Sweetwater", state_id: "Texas" },
  { id: "Sweetwater", state_id: "Florida" },
  { id: "Swissvale", state_id: "Pennsylvania" },
  { id: "Sycamore", state_id: "Illinois" },
  { id: "Sylacauga", state_id: "Alabama" },
  { id: "Sylvania", state_id: "Ohio" },
  { id: "Syosset", state_id: "New York" },
  { id: "Syracuse", state_id: "New York" },
  { id: "Syracuse", state_id: "Utah" },
  { id: "Tacoma", state_id: "Washington" },
  { id: "Taft", state_id: "California" },
  { id: "Tahlequah", state_id: "Oklahoma" },
  { id: "Takoma Park", state_id: "Maryland" },
  { id: "Talladega", state_id: "Alabama" },
  { id: "Tallahassee", state_id: "Florida" },
  { id: "Tallmadge", state_id: "Ohio" },
  { id: "Tallulah", state_id: "Louisiana" },
  { id: "Tamalpais-Homestead Valley", state_id: "California" },
  { id: "Tamaqua", state_id: "Pennsylvania" },
  { id: "Tamarac", state_id: "Florida" },
  { id: "Tamiami", state_id: "Florida" },
  { id: "Tampa", state_id: "Florida" },
  { id: "Tanque Verde", state_id: "Arizona" },
  { id: "Tappan", state_id: "New York" },
  { id: "Tarboro", state_id: "North Carolina" },
  { id: "Tarpon Springs", state_id: "Florida" },
  { id: "Tarrant", state_id: "Alabama" },
  { id: "Tarrytown", state_id: "New York" },
  { id: "Taunton", state_id: "Massachusetts" },
  { id: "Tavares", state_id: "Florida" },
  { id: "Taylor", state_id: "Michigan" },
  { id: "Taylor", state_id: "Pennsylvania" },
  { id: "Taylor", state_id: "Texas" },
  { id: "Taylor Mill", state_id: "Kentucky" },
  { id: "Taylors", state_id: "South Carolina" },
  { id: "Taylorsville", state_id: "Utah" },
  { id: "Taylorville", state_id: "Illinois" },
  { id: "Teaneck", state_id: "New Jersey" },
  { id: "Teays Valley", state_id: "West Virginia" },
  { id: "Tecumseh", state_id: "Michigan" },
  { id: "Tecumseh", state_id: "Oklahoma" },
  { id: "Tehachapi", state_id: "California" },
  { id: "Tell City", state_id: "Indiana" },
  { id: "Temecula", state_id: "California" },
  { id: "Tempe", state_id: "Arizona" },
  { id: "Temperance", state_id: "Michigan" },
  { id: "Temple", state_id: "Texas" },
  { id: "Temple City", state_id: "California" },
  { id: "Temple Hills", state_id: "Maryland" },
  { id: "Temple Terrace", state_id: "Florida" },
  { id: "Templeton", state_id: "Massachusetts" },
  { id: "Tenafly", state_id: "New Jersey" },
  { id: "Terrace Heights", state_id: "Washington" },
  { id: "Terre Haute", state_id: "Indiana" },
  { id: "Terrell", state_id: "Texas" },
  { id: "Terrytown", state_id: "Louisiana" },
  { id: "Terryville", state_id: "New York" },
  { id: "Tewksbury", state_id: "Massachusetts" },
  { id: "Texarkana", state_id: "Texas" },
  { id: "Texarkana", state_id: "Arkansas" },
  { id: "Texas City", state_id: "Texas" },
  { id: "The Colony", state_id: "Texas" },
  { id: "The Crossings", state_id: "Florida" },
  { id: "The Hammocks", state_id: "Florida" },
  { id: "The Pinery", state_id: "Colorado" },
  { id: "The Village", state_id: "Oklahoma" },
  { id: "The Villages", state_id: "Florida" },
  { id: "The Woodlands", state_id: "Texas" },
  { id: "Theodore", state_id: "Alabama" },
  { id: "Thermalito", state_id: "California" },
  { id: "Thibodaux", state_id: "Louisiana" },
  { id: "Thief River Falls", state_id: "Minnesota" },
  { id: "Thomaston", state_id: "Georgia" },
  { id: "Thomaston", state_id: "Connecticut" },
  { id: "Thomasville", state_id: "Georgia" },
  { id: "Thomasville", state_id: "North Carolina" },
  { id: "Thompson", state_id: "New York" },
  { id: "Thompson", state_id: "Connecticut" },
  { id: "Thompsonville", state_id: "Connecticut" },
  { id: "Thomson", state_id: "Georgia" },
  { id: "Thonotosassa", state_id: "Florida" },
  { id: "Thornton", state_id: "Colorado" },
  { id: "Thousand Oaks", state_id: "California" },
  { id: "Three Lakes", state_id: "Florida" },
  { id: "Three Rivers", state_id: "Michigan" },
  { id: "Tiburon", state_id: "California" },
  { id: "Tiffin", state_id: "Ohio" },
  { id: "Tifton", state_id: "Georgia" },
  { id: "Tigard", state_id: "Oregon" },
  { id: "Tillmans Corner", state_id: "Alabama" },
  { id: "Timberlake", state_id: "Virginia" },
  { id: "Timberlane", state_id: "Louisiana" },
  { id: "Tinley Park", state_id: "Illinois" },
  { id: "Tinton Falls", state_id: "New Jersey" },
  { id: "Tipp City", state_id: "Ohio" },
  { id: "Titusville", state_id: "Pennsylvania" },
  { id: "Titusville", state_id: "Florida" },
  { id: "Tiverton", state_id: "Rhode Island" },
  { id: "Tiverton", state_id: "Rhode Island" },
  { id: "Toccoa", state_id: "Georgia" },
  { id: "Toledo", state_id: "Ohio" },
  { id: "Tolland", state_id: "Connecticut" },
  { id: "Tomah", state_id: "Wisconsin" },
  { id: "Tomball", state_id: "Texas" },
  { id: "Toms River", state_id: "New Jersey" },
  { id: "Tonawanda", state_id: "New York" },
  { id: "Tonawanda", state_id: "New York" },
  { id: "Tonawanda", state_id: "New York" },
  { id: "Tooele", state_id: "Utah" },
  { id: "Topeka", state_id: "Kansas" },
  { id: "Toppenish", state_id: "Washington" },
  { id: "Topsfield", state_id: "Massachusetts" },
  { id: "Topsham", state_id: "Maine" },
  { id: "Topsham", state_id: "Maine" },
  { id: "Torrance", state_id: "California" },
  { id: "Torrington", state_id: "Connecticut" },
  { id: "Torrington", state_id: "Connecticut" },
  { id: "Totowa", state_id: "New Jersey" },
  { id: "Town Country", state_id: "Florida" },
  { id: "Town and Country", state_id: "Missouri" },
  { id: "Townsend", state_id: "Massachusetts" },
  { id: "Towson", state_id: "Maryland" },
  { id: "Tracy", state_id: "California" },
  { id: "Traverse City", state_id: "Michigan" },
  { id: "Travilah", state_id: "Maryland" },
  { id: "Treasure Island", state_id: "Florida" },
  { id: "Trenton", state_id: "Michigan" },
  { id: "Trenton", state_id: "Missouri" },
  { id: "Trenton", state_id: "New Jersey" },
  { id: "Trenton", state_id: "Ohio" },
  { id: "Trinidad", state_id: "Colorado" },
  { id: "Trinity", state_id: "North Carolina" },
  { id: "Trooper", state_id: "Pennsylvania" },
  { id: "Trophy Club", state_id: "Texas" },
  { id: "Trotwood", state_id: "Ohio" },
  { id: "Troutdale", state_id: "Oregon" },
  { id: "Troy", state_id: "Ohio" },
  { id: "Troy", state_id: "New York" },
  { id: "Troy", state_id: "Missouri" },
  { id: "Troy", state_id: "Michigan" },
  { id: "Troy", state_id: "Alabama" },
  { id: "Troy", state_id: "Illinois" },
  { id: "Truckee", state_id: "California" },
  { id: "Trumann", state_id: "Arkansas" },
  { id: "Trumbull", state_id: "Connecticut" },
  { id: "Trumbull", state_id: "Connecticut" },
  { id: "Trussville", state_id: "Alabama" },
  { id: "Truth or Consequences", state_id: "New Mexico" },
  { id: "Tualatin", state_id: "Oregon" },
  { id: "Tuba City", state_id: "Arizona" },
  { id: "Tuckahoe", state_id: "New York" },
  { id: "Tuckahoe", state_id: "Virginia" },
  { id: "Tucker", state_id: "Georgia" },
  { id: "Tucson", state_id: "Arizona" },
  { id: "Tucson Estates", state_id: "Arizona" },
  { id: "Tukwila", state_id: "Washington" },
  { id: "Tulare", state_id: "California" },
  { id: "Tullahoma", state_id: "Tennessee" },
  { id: "Tulsa", state_id: "Oklahoma" },
  { id: "Tumwater", state_id: "Washington" },
  { id: "Tupelo", state_id: "Mississippi" },
  { id: "Turlock", state_id: "California" },
  { id: "Turtle Creek", state_id: "Pennsylvania" },
  { id: "Tuscaloosa", state_id: "Alabama" },
  { id: "Tuscumbia", state_id: "Alabama" },
  { id: "Tuskegee", state_id: "Alabama" },
  { id: "Tustin", state_id: "California" },
  { id: "Tustin Foothills", state_id: "California" },
  { id: "Twentynine Palms", state_id: "California" },
  { id: "Twentynine Palms Base", state_id: "California" },
  { id: "Twin Falls", state_id: "Idaho" },
  { id: "Twin Lakes", state_id: "Colorado" },
  { id: "Twin Rivers", state_id: "New Jersey" },
  { id: "Twinsburg", state_id: "Ohio" },
  { id: "Two Rivers", state_id: "Wisconsin" },
  { id: "Tyler", state_id: "Texas" },
  { id: "Tyngsborough", state_id: "Massachusetts" },
  { id: "Tysons Corner", state_id: "Virginia" },
  { id: "Ukiah", state_id: "California" },
  { id: "Ulster", state_id: "New York" },
  { id: "Union", state_id: "South Carolina" },
  { id: "Union", state_id: "New York" },
  { id: "Union", state_id: "Missouri" },
  { id: "Union", state_id: "New Jersey" },
  { id: "Union Beach", state_id: "New Jersey" },
  { id: "Union City", state_id: "New Jersey" },
  { id: "Union City", state_id: "California" },
  { id: "Union City", state_id: "Georgia" },
  { id: "Union City", state_id: "Tennessee" },
  { id: "Union Hill-Novelty Hill", state_id: "Washington" },
  { id: "Union Park", state_id: "Florida" },
  { id: "Uniondale", state_id: "New York" },
  { id: "Uniontown", state_id: "Pennsylvania" },
  { id: "Universal City", state_id: "Texas" },
  { id: "University", state_id: "Florida" },
  { id: "University City", state_id: "Missouri" },
  { id: "University Heights", state_id: "Ohio" },
  { id: "University Park", state_id: "Texas" },
  { id: "University Park", state_id: "Florida" },
  { id: "University Park", state_id: "Illinois" },
  { id: "University Place", state_id: "Washington" },
  { id: "Upland", state_id: "California" },
  { id: "Upper Arlington", state_id: "Ohio" },
  { id: "Upper Grand Lagoon", state_id: "Florida" },
  { id: "Upper Providence Township", state_id: "Pennsylvania" },
  { id: "Upper Saddle River", state_id: "New Jersey" },
  { id: "Upper Sandusky", state_id: "Ohio" },
  { id: "Upper St. Clair", state_id: "Pennsylvania" },
  { id: "Urbana", state_id: "Ohio" },
  { id: "Urbana", state_id: "Illinois" },
  { id: "Urbandale", state_id: "Iowa" },
  { id: "Utica", state_id: "New York" },
  { id: "Uvalde", state_id: "Texas" },
  { id: "Uxbridge", state_id: "Massachusetts" },
  { id: "Vacaville", state_id: "California" },
  { id: "Vadnais Heights", state_id: "Minnesota" },
  { id: "Valdosta", state_id: "Georgia" },
  { id: "Valinda", state_id: "California" },
  { id: "Valle Vista", state_id: "California" },
  { id: "Vallejo", state_id: "California" },
  { id: "Valley", state_id: "Alabama" },
  { id: "Valley Center", state_id: "California" },
  { id: "Valley City", state_id: "North Dakota" },
  { id: "Valley Cottage", state_id: "New York" },
  { id: "Valley Falls", state_id: "Rhode Island" },
  { id: "Valley Park", state_id: "Missouri" },
  { id: "Valley Station", state_id: "Kentucky" },
  { id: "Valley Stream", state_id: "New York" },
  { id: "Valparaiso", state_id: "Indiana" },
  { id: "Valparaiso", state_id: "Florida" },
  { id: "Valrico", state_id: "Florida" },
  { id: "Van Buren", state_id: "Arkansas" },
  { id: "Van Buren", state_id: "New York" },
  { id: "Van Wert", state_id: "Ohio" },
  { id: "Vancouver", state_id: "Washington" },
  { id: "Vandalia", state_id: "Ohio" },
  { id: "Vandalia", state_id: "Illinois" },
  { id: "Vandenberg AFB", state_id: "California" },
  { id: "Vashon", state_id: "Washington" },
  { id: "Venice", state_id: "Florida" },
  { id: "Venice Gardens", state_id: "Florida" },
  { id: "Ventnor City", state_id: "New Jersey" },
  { id: "Veradale", state_id: "Washington" },
  { id: "Vermilion", state_id: "Ohio" },
  { id: "Vermillion", state_id: "South Dakota" },
  { id: "Vernal", state_id: "Utah" },
  { id: "Vernon", state_id: "Wisconsin" },
  { id: "Vernon", state_id: "Texas" },
  { id: "Vernon", state_id: "Connecticut" },
  { id: "Vernon Hills", state_id: "Illinois" },
  { id: "Vero Beach", state_id: "Florida" },
  { id: "Vero Beach South", state_id: "Florida" },
  { id: "Verona", state_id: "New Jersey" },
  { id: "Verona", state_id: "New York" },
  { id: "Verona", state_id: "Wisconsin" },
  { id: "Versailles", state_id: "Kentucky" },
  { id: "Vestal", state_id: "New York" },
  { id: "Vestavia Hills", state_id: "Alabama" },
  { id: "Vicksburg", state_id: "Mississippi" },
  { id: "Victor", state_id: "New York" },
  { id: "Victoria", state_id: "Texas" },
  { id: "Victorville", state_id: "California" },
  { id: "Vidalia", state_id: "Georgia" },
  { id: "Vidor", state_id: "Texas" },
  { id: "Vienna", state_id: "Virginia" },
  { id: "Vienna", state_id: "West Virginia" },
  { id: "View Park-Windsor Hills", state_id: "California" },
  { id: "Villa Hills", state_id: "Kentucky" },
  { id: "Villa Park", state_id: "Illinois" },
  { id: "Village Green-Green Ridge", state_id: "Pennsylvania" },
  { id: "Village Park", state_id: "Hawaii" },
  { id: "Village St. George", state_id: "Louisiana" },
  { id: "Villas", state_id: "Florida" },
  { id: "Villas", state_id: "New Jersey" },
  { id: "Ville Platte", state_id: "Louisiana" },
  { id: "Vincennes", state_id: "Indiana" },
  { id: "Vincent", state_id: "California" },
  { id: "Vineland", state_id: "New Jersey" },
  { id: "Vineyard", state_id: "California" },
  { id: "Vinings", state_id: "Georgia" },
  { id: "Vinita", state_id: "Oklahoma" },
  { id: "Vinton", state_id: "Virginia" },
  { id: "Violet", state_id: "Louisiana" },
  { id: "Virginia", state_id: "Minnesota" },
  { id: "Virginia Beach", state_id: "Virginia" },
  { id: "Visalia", state_id: "California" },
  { id: "Vista", state_id: "California" },
  { id: "Volney", state_id: "New York" },
  { id: "Wabash", state_id: "Indiana" },
  { id: "Waco", state_id: "Texas" },
  { id: "Waconia", state_id: "Minnesota" },
  { id: "Wade Hampton", state_id: "South Carolina" },
  { id: "Wading River", state_id: "New York" },
  { id: "Wadsworth", state_id: "Ohio" },
  { id: "Waggaman", state_id: "Louisiana" },
  { id: "Wagoner", state_id: "Oklahoma" },
  { id: "Wahiawa", state_id: "Hawaii" },
  { id: "Wahpeton", state_id: "North Dakota" },
  { id: "Waianae", state_id: "Hawaii" },
  { id: "Waihee-Waiehu", state_id: "Hawaii" },
  { id: "Wailuku", state_id: "Hawaii" },
  { id: "Waimalu", state_id: "Hawaii" },
  { id: "Waimea", state_id: "Hawaii" },
  { id: "Waipahu", state_id: "Hawaii" },
  { id: "Waipio", state_id: "Hawaii" },
  { id: "Waite Park", state_id: "Minnesota" },
  { id: "Wake Forest", state_id: "North Carolina" },
  { id: "Wakefield", state_id: "Massachusetts" },
  { id: "Wakefield", state_id: "Massachusetts" },
  { id: "Wakefield-Peacedale", state_id: "Rhode Island" },
  { id: "Walden", state_id: "New York" },
  { id: "Waldorf", state_id: "Maryland" },
  { id: "Waldwick", state_id: "New Jersey" },
  { id: "Walker", state_id: "Michigan" },
  { id: "Walker Mill", state_id: "Maryland" },
  { id: "Walla Walla", state_id: "Washington" },
  { id: "Walled Lake", state_id: "Michigan" },
  { id: "Waller", state_id: "Washington" },
  { id: "Wallingford", state_id: "Connecticut" },
  { id: "Wallingford Center", state_id: "Connecticut" },
  { id: "Wallington", state_id: "New Jersey" },
  { id: "Wallkill", state_id: "New York" },
  { id: "Walnut", state_id: "California" },
  { id: "Walnut Creek", state_id: "California" },
  { id: "Walnut Grove", state_id: "Washington" },
  { id: "Walnut Park", state_id: "California" },
  { id: "Walpole", state_id: "Massachusetts" },
  { id: "Waltham", state_id: "Massachusetts" },
  { id: "Walworth", state_id: "New York" },
  { id: "Wanaque", state_id: "New Jersey" },
  { id: "Wantagh", state_id: "New York" },
  { id: "Wapakoneta", state_id: "Ohio" },
  { id: "Wappinger", state_id: "New York" },
  { id: "Ware", state_id: "Massachusetts" },
  { id: "Ware", state_id: "Massachusetts" },
  { id: "Wareham", state_id: "Massachusetts" },
  { id: "Warner Robins", state_id: "Georgia" },
  { id: "Warr Acres", state_id: "Oklahoma" },
  { id: "Warren", state_id: "Pennsylvania" },
  { id: "Warren", state_id: "Rhode Island" },
  { id: "Warren", state_id: "Arkansas" },
  { id: "Warren", state_id: "Michigan" },
  { id: "Warren", state_id: "Ohio" },
  { id: "Warrensburg", state_id: "Missouri" },
  { id: "Warrensville Heights", state_id: "Ohio" },
  { id: "Warrenton", state_id: "Virginia" },
  { id: "Warrenville", state_id: "Illinois" },
  { id: "Warrington", state_id: "Florida" },
  { id: "Warsaw", state_id: "Indiana" },
  { id: "Warwick", state_id: "New York" },
  { id: "Warwick", state_id: "New York" },
  { id: "Warwick", state_id: "Rhode Island" },
  { id: "Wasco", state_id: "California" },
  { id: "Waseca", state_id: "Minnesota" },
  { id: "Washington", state_id: "Missouri" },
  { id: "Washington", state_id: "New Jersey" },
  { id: "Washington", state_id: "Ohio" },
  { id: "Washington", state_id: "North Carolina" },
  { id: "Washington", state_id: "Indiana" },
  { id: "Washington", state_id: "Iowa" },
  { id: "Washington", state_id: "Illinois" },
  { id: "Washington", state_id: "Pennsylvania" },
  { id: "Washington", state_id: "Utah" },
  { id: "Washington", state_id: "Wisconsin" },
  { id: "Washington", state_id: "Washington" },
  { id: "Washington Terrace", state_id: "Utah" },
  { id: "Washington Township", state_id: "New Jersey" },
  { id: "Washougal", state_id: "Washington" },
  { id: "Watauga", state_id: "Texas" },
  { id: "Waterboro", state_id: "Maine" },
  { id: "Waterbury", state_id: "Connecticut" },
  { id: "Waterbury", state_id: "Connecticut" },
  { id: "Waterford", state_id: "Connecticut" },
  { id: "Waterford", state_id: "California" },
  { id: "Waterford", state_id: "Michigan" },
  { id: "Waterford", state_id: "New York" },
  { id: "Waterloo", state_id: "New York" },
  { id: "Waterloo", state_id: "Illinois" },
  { id: "Waterloo", state_id: "Iowa" },
  { id: "Watertown", state_id: "Connecticut" },
  { id: "Watertown", state_id: "New York" },
  { id: "Watertown", state_id: "Massachusetts" },
  { id: "Watertown", state_id: "South Dakota" },
  { id: "Watertown", state_id: "Wisconsin" },
  { id: "Waterville", state_id: "Maine" },
  { id: "Watervliet", state_id: "New York" },
  { id: "Watsonville", state_id: "California" },
  { id: "Wauconda", state_id: "Illinois" },
  { id: "Waukegan", state_id: "Illinois" },
  { id: "Waukesha", state_id: "Wisconsin" },
  { id: "Waukesha", state_id: "Wisconsin" },
  { id: "Waunakee", state_id: "Wisconsin" },
  { id: "Waupun", state_id: "Wisconsin" },
  { id: "Wausau", state_id: "Wisconsin" },
  { id: "Wauseon", state_id: "Ohio" },
  { id: "Wauwatosa", state_id: "Wisconsin" },
  { id: "Waveland", state_id: "Mississippi" },
  { id: "Waverly", state_id: "Michigan" },
  { id: "Waverly", state_id: "Iowa" },
  { id: "Wawarsing", state_id: "New York" },
  { id: "Wawayanda", state_id: "New York" },
  { id: "Waxahachie", state_id: "Texas" },
  { id: "Waycross", state_id: "Georgia" },
  { id: "Wayland", state_id: "Massachusetts" },
  { id: "Wayne", state_id: "Michigan" },
  { id: "Wayne", state_id: "New Jersey" },
  { id: "Waynesboro", state_id: "Virginia" },
  { id: "Waynesboro", state_id: "Pennsylvania" },
  { id: "Waynesville", state_id: "North Carolina" },
  { id: "Weare", state_id: "New Hampshire" },
  { id: "Weatherford", state_id: "Oklahoma" },
  { id: "Purcell", state_id: "Oklahoma" },
  { id: "Weatherford", state_id: "Texas" },
  { id: "Webb City", state_id: "Missouri" },
  { id: "Webster", state_id: "Massachusetts" },
  { id: "Webster", state_id: "Massachusetts" },
  { id: "Webster", state_id: "New York" },
  { id: "Webster", state_id: "Texas" },
  { id: "Webster City", state_id: "Iowa" },
  { id: "Webster Groves", state_id: "Missouri" },
  { id: "Weddington", state_id: "North Carolina" },
  { id: "Weigelstown", state_id: "Pennsylvania" },
  { id: "Weirton", state_id: "West Virginia" },
  { id: "Wekiwa Springs", state_id: "Florida" },
  { id: "Welby", state_id: "Colorado" },
  { id: "Welcome", state_id: "South Carolina" },
  { id: "Wellesley", state_id: "Massachusetts" },
  { id: "Wellesley", state_id: "Massachusetts" },
  { id: "Wellington", state_id: "Florida" },
  { id: "Wellington", state_id: "Kansas" },
  { id: "Wells", state_id: "Maine" },
  { id: "Wells Branch", state_id: "Texas" },
  { id: "Wellston", state_id: "Ohio" },
  { id: "Wellsville", state_id: "New York" },
  { id: "Wenatchee", state_id: "Washington" },
  { id: "Wentzville", state_id: "Missouri" },
  { id: "Weslaco", state_id: "Texas" },
  { id: "West Allis", state_id: "Wisconsin" },
  { id: "West and East Lealman", state_id: "Florida" },
  { id: "West Athens", state_id: "California" },
  { id: "West Babylon", state_id: "New York" },
  { id: "West Bend", state_id: "Wisconsin" },
  { id: "West Bloomfield Township", state_id: "Michigan" },
  { id: "West Boylston", state_id: "Massachusetts" },
  { id: "West Bridgewater", state_id: "Massachusetts" },
  { id: "West Caldwell", state_id: "New Jersey" },
  { id: "West Carrollton City", state_id: "Ohio" },
  { id: "West Carson", state_id: "California" },
  { id: "West Chester", state_id: "Pennsylvania" },
  { id: "West Chicago", state_id: "Illinois" },
  { id: "West Columbia", state_id: "South Carolina" },
  { id: "West Covina", state_id: "California" },
  { id: "West Des Moines", state_id: "Iowa" },
  { id: "West Fargo", state_id: "North Dakota" },
  { id: "West Frankfort", state_id: "Illinois" },
  { id: "West Freehold", state_id: "New Jersey" },
  { id: "West Gate", state_id: "Virginia" },
  { id: "West Glens Falls", state_id: "New York" },
  { id: "West Goshen", state_id: "Pennsylvania" },
  { id: "West Hartford", state_id: "Connecticut" },
  { id: "West Hartford", state_id: "Connecticut" },
  { id: "West Hattiesburg", state_id: "Mississippi" },
  { id: "West Haven", state_id: "Connecticut" },
  { id: "West Haven", state_id: "Connecticut" },
  { id: "West Haven-Sylvan", state_id: "Oregon" },
  { id: "West Haverstraw", state_id: "New York" },
  { id: "West Helena", state_id: "Arkansas" },
  { id: "West Hempstead", state_id: "New York" },
  { id: "West Hollywood", state_id: "California" },
  { id: "West Islip", state_id: "New York" },
  { id: "West Jordan", state_id: "Utah" },
  { id: "West Lafayette", state_id: "Indiana" },
  { id: "West Lake Stevens", state_id: "Washington" },
  { id: "Washington", state_id: "District of Columbia" },
  { id: "West Linn", state_id: "Oregon" },
  { id: "West Little River", state_id: "Florida" },
  { id: "West Livingston", state_id: "Texas" },
  { id: "West Long Branch", state_id: "New Jersey" },
  { id: "West Melbourne", state_id: "Florida" },
  { id: "West Memphis", state_id: "Arkansas" },
  { id: "West Mifflin", state_id: "Pennsylvania" },
  { id: "West Milford", state_id: "New Jersey" },
  { id: "West Modesto", state_id: "California" },
  { id: "West Monroe", state_id: "Louisiana" },
  { id: "West New York", state_id: "New Jersey" },
  { id: "West Norriton", state_id: "Pennsylvania" },
  { id: "West Odessa", state_id: "Texas" },
  { id: "West Orange", state_id: "New Jersey" },
  { id: "West Palm Beach", state_id: "Florida" },
  { id: "West Paterson", state_id: "New Jersey" },
  { id: "West Pensacola", state_id: "Florida" },
  { id: "West Perrine", state_id: "Florida" },
  { id: "West Plains", state_id: "Missouri" },
  { id: "West Point", state_id: "New York" },
  { id: "West Point", state_id: "Mississippi" },
  { id: "West Point", state_id: "Utah" },
  { id: "West Puente Valley", state_id: "California" },
  { id: "West Richland", state_id: "Washington" },
  { id: "West Sacramento", state_id: "California" },
  { id: "West Seneca", state_id: "New York" },
  { id: "West Seneca", state_id: "New York" },
  { id: "West Slope", state_id: "Oregon" },
  { id: "West Springfield", state_id: "Virginia" },
  { id: "West Springfield", state_id: "Massachusetts" },
  { id: "West Springfield", state_id: "Massachusetts" },
  { id: "West St. Paul", state_id: "Minnesota" },
  { id: "West University Place", state_id: "Texas" },
  { id: "West Valley", state_id: "Washington" },
  { id: "West Valley City", state_id: "Utah" },
  { id: "West Vero Corridor", state_id: "Florida" },
  { id: "West View", state_id: "Pennsylvania" },
  { id: "West Warwick", state_id: "Rhode Island" },
  { id: "West Warwick", state_id: "Rhode Island" },
  { id: "West Whittier-Los Nietos", state_id: "California" },
  { id: "West Yarmouth", state_id: "Massachusetts" },
  { id: "Westborough", state_id: "Massachusetts" },
  { id: "Westbrook", state_id: "Connecticut" },
  { id: "Westbrook", state_id: "Maine" },
  { id: "Westbury", state_id: "New York" },
  { id: "Westchase", state_id: "Florida" },
  { id: "Westchester", state_id: "Florida" },
  { id: "Westchester", state_id: "Illinois" },
  { id: "Westerly", state_id: "Rhode Island" },
  { id: "Westerly", state_id: "Rhode Island" },
  { id: "Western Springs", state_id: "Illinois" },
  { id: "Westerville", state_id: "Ohio" },
  { id: "Westfield", state_id: "Massachusetts" },
  { id: "Westfield", state_id: "New Jersey" },
  { id: "Westfield", state_id: "Indiana" },
  { id: "Westford", state_id: "Massachusetts" },
  { id: "Westgate-Belvedere Homes", state_id: "Florida" },
  { id: "Westlake", state_id: "Ohio" },
  { id: "Westlake Village", state_id: "California" },
  { id: "Westland", state_id: "Michigan" },
  { id: "Westmere", state_id: "New York" },
  { id: "Westminster", state_id: "Massachusetts" },
  { id: "Westminster", state_id: "California" },
  { id: "Westminster", state_id: "Colorado" },
  { id: "Westminster", state_id: "Maryland" },
  { id: "Westmont", state_id: "Illinois" },
  { id: "Westmont", state_id: "California" },
  { id: "Westmoreland", state_id: "New York" },
  { id: "Weston", state_id: "Connecticut" },
  { id: "Weston", state_id: "Florida" },
  { id: "Weston", state_id: "Massachusetts" },
  { id: "Weston", state_id: "Wisconsin" },
  { id: "Westport", state_id: "Massachusetts" },
  { id: "Westport", state_id: "Connecticut" },
  { id: "Westport", state_id: "Connecticut" },
  { id: "Westview", state_id: "Florida" },
  { id: "Westwego", state_id: "Louisiana" },
  { id: "Westwood", state_id: "Massachusetts" },
  { id: "Westwood", state_id: "Michigan" },
  { id: "Westwood", state_id: "New Jersey" },
  { id: "Westwood Lakes", state_id: "Florida" },
  { id: "Wethersfield", state_id: "Connecticut" },
  { id: "Wethersfield", state_id: "Connecticut" },
  { id: "Weymouth", state_id: "Massachusetts" },
  { id: "Weymouth", state_id: "Massachusetts" },
  { id: "Wharton", state_id: "New Jersey" },
  { id: "Wharton", state_id: "Texas" },
  { id: "Wheat Ridge", state_id: "Colorado" },
  { id: "Wheatfield", state_id: "New York" },
  { id: "Wheaton", state_id: "Illinois" },
  { id: "Wheaton-Glenmont", state_id: "Maryland" },
  { id: "Wheelersburg", state_id: "Ohio" },
  { id: "Wheeling", state_id: "Illinois" },
  { id: "Wheeling", state_id: "West Virginia" },
  { id: "White Bear Lake", state_id: "Minnesota" },
  { id: "White Center", state_id: "Washington" },
  { id: "White Horse", state_id: "New Jersey" },
  { id: "White House", state_id: "Tennessee" },
  { id: "White Marsh", state_id: "Maryland" },
  { id: "White Meadow Lake", state_id: "New Jersey" },
  { id: "White Oak", state_id: "Ohio" },
  { id: "White Oak", state_id: "Maryland" },
  { id: "White Oak", state_id: "Pennsylvania" },
  { id: "White Plains", state_id: "New York" },
  { id: "White Rock", state_id: "New Mexico" },
  { id: "White Settlement", state_id: "Texas" },
  { id: "Whitefish Bay", state_id: "Wisconsin" },
  { id: "Whitehall", state_id: "Pennsylvania" },
  { id: "Whitehall", state_id: "Ohio" },
  { id: "Whitestown", state_id: "New York" },
  { id: "Whitewater", state_id: "Wisconsin" },
  { id: "Whitinsville", state_id: "Massachusetts" },
  { id: "Whitman", state_id: "Massachusetts" },
  { id: "Whitmore Lake", state_id: "Michigan" },
  { id: "Whitney", state_id: "Nevada" },
  { id: "Whittier", state_id: "California" },
  { id: "Wichita", state_id: "Kansas" },
  { id: "Wichita Falls", state_id: "Texas" },
  { id: "Wickliffe", state_id: "Ohio" },
  { id: "Wilbraham", state_id: "Massachusetts" },
  { id: "Wildomar", state_id: "California" },
  { id: "Wildwood", state_id: "Missouri" },
  { id: "Wilkes-Barre", state_id: "Pennsylvania" },
  { id: "Wilkins Township", state_id: "Pennsylvania" },
  { id: "Wilkinsburg", state_id: "Pennsylvania" },
  { id: "Willard", state_id: "Ohio" },
  { id: "Williamsburg", state_id: "Florida" },
  { id: "Williamsburg", state_id: "Virginia" },
  { id: "Williamson", state_id: "New York" },
  { id: "Williamsport", state_id: "Pennsylvania" },
  { id: "Williamstown", state_id: "New Jersey" },
  { id: "Williamstown", state_id: "Massachusetts" },
  { id: "Willimantic", state_id: "Connecticut" },
  { id: "Williston", state_id: "North Dakota" },
  { id: "Williston", state_id: "Vermont" },
  { id: "Williston Park", state_id: "New York" },
  { id: "Willmar", state_id: "Minnesota" },
  { id: "Willoughby", state_id: "Ohio" },
  { id: "Willoughby Hills", state_id: "Ohio" },
  { id: "Willow Grove", state_id: "Pennsylvania" },
  { id: "Willow Street", state_id: "Pennsylvania" },
  { id: "Willowbrook", state_id: "California" },
  { id: "Willowbrook", state_id: "Illinois" },
  { id: "Willowick", state_id: "Ohio" },
  { id: "Willows", state_id: "California" },
  { id: "Wilmette", state_id: "Illinois" },
  { id: "Wilmington", state_id: "Massachusetts" },
  { id: "Wilmington", state_id: "Delaware" },
  { id: "Wilmington", state_id: "Ohio" },
  { id: "Wilmington", state_id: "North Carolina" },
  { id: "Wilmington", state_id: "Massachusetts" },
  { id: "Wilmington Island", state_id: "Georgia" },
  { id: "Wilmington Manor", state_id: "Delaware" },
  { id: "Wilna", state_id: "New York" },
  { id: "Wilson", state_id: "North Carolina" },
  { id: "Wilson", state_id: "Pennsylvania" },
  { id: "Wilsonville", state_id: "Oregon" },
  { id: "Wilton", state_id: "New York" },
  { id: "Wilton", state_id: "Connecticut" },
  { id: "Wilton Manors", state_id: "Florida" },
  { id: "Winchendon", state_id: "Massachusetts" },
  { id: "Winchester", state_id: "Massachusetts" },
  { id: "Winchester", state_id: "Nevada" },
  { id: "Winchester", state_id: "Connecticut" },
  { id: "Winchester", state_id: "Massachusetts" },
  { id: "Winchester", state_id: "Kentucky" },
  { id: "Winchester", state_id: "Tennessee" },
  { id: "Winchester", state_id: "Virginia" },
  { id: "Windemere", state_id: "Texas" },
  { id: "Crane", state_id: "Texas" },
  { id: "Cookville", state_id: "Texas" },
  { id: "Winder", state_id: "Georgia" },
  { id: "Windham", state_id: "Maine" },
  { id: "Windham", state_id: "Connecticut" },
  { id: "Windham", state_id: "New Hampshire" },
  { id: "Windsor", state_id: "New York" },
  { id: "Windsor", state_id: "Connecticut" },
  { id: "Windsor", state_id: "Colorado" },
  { id: "Windsor", state_id: "California" },
  { id: "Windsor Locks", state_id: "Connecticut" },
  { id: "Windsor Locks", state_id: "Connecticut" },
  { id: "Winfield", state_id: "Kansas" },
  { id: "Winfield", state_id: "Illinois" },
  { id: "Winnemucca", state_id: "Nevada" },
  { id: "Winnetka", state_id: "Illinois" },
  { id: "Winona", state_id: "Minnesota" },
  { id: "Winooski", state_id: "Vermont" },
  { id: "Winslow", state_id: "Maine" },
  { id: "Winslow", state_id: "Maine" },
  { id: "Winslow", state_id: "Arizona" },
  { id: "Winsted", state_id: "Connecticut" },
  { id: "Winston", state_id: "Florida" },
  { id: "Winston-Salem", state_id: "North Carolina" },
  { id: "Winter Garden", state_id: "Florida" },
  { id: "Winter Gardens", state_id: "California" },
  { id: "Winter Haven", state_id: "Florida" },
  { id: "Winter Park", state_id: "Florida" },
  { id: "Winter Springs", state_id: "Florida" },
  { id: "Winters", state_id: "California" },
  { id: "Winthrop", state_id: "Maine" },
  { id: "Winthrop", state_id: "Massachusetts" },
  { id: "Winthrop", state_id: "Massachusetts" },
  { id: "Winthrop Harbor", state_id: "Illinois" },
  { id: "Winton", state_id: "California" },
  { id: "Wisconsin Rapids", state_id: "Wisconsin" },
  { id: "Wixom", state_id: "Michigan" },
  { id: "Woburn", state_id: "Massachusetts" },
  { id: "Wolcott", state_id: "Connecticut" },
  { id: "Wolf Trap", state_id: "Virginia" },
  { id: "Wolfeboro", state_id: "New Hampshire" },
  { id: "Wonder Lake", state_id: "Illinois" },
  { id: "Wood Dale", state_id: "Illinois" },
  { id: "Wood River", state_id: "Illinois" },
  { id: "Wood-Ridge", state_id: "New Jersey" },
  { id: "Woodbourne-Hyde Park", state_id: "Ohio" },
  { id: "Woodbridge", state_id: "New Jersey" },
  { id: "Woodbridge", state_id: "Connecticut" },
  { id: "Woodbridge", state_id: "Virginia" },
  { id: "Woodburn", state_id: "Oregon" },
  { id: "Woodbury", state_id: "Connecticut" },
  { id: "Woodbury", state_id: "New Jersey" },
  { id: "Woodbury", state_id: "Minnesota" },
  { id: "Woodbury", state_id: "New York" },
  { id: "Woodbury", state_id: "New York" },
  { id: "Woodcrest", state_id: "California" },
  { id: "Woodfield", state_id: "South Carolina" },
  { id: "Woodhaven", state_id: "Michigan" },
  { id: "Woodinville", state_id: "Washington" },
  { id: "Woodlake", state_id: "California" },
  { id: "Woodland", state_id: "California" },
  { id: "Woodland Park", state_id: "Colorado" },
  { id: "Woodlawn", state_id: "Maryland" },
  { id: "Woodlawn", state_id: "Maryland" },
  { id: "Woodlyn", state_id: "Pennsylvania" },
  { id: "Woodmere", state_id: "Louisiana" },
  { id: "Woodmere", state_id: "New York" },
  { id: "Woodmoor", state_id: "Colorado" },
  { id: "Woodmore", state_id: "Maryland" },
  { id: "Woodridge", state_id: "Illinois" },
  { id: "Woods Cross", state_id: "Utah" },
  { id: "Woodstock", state_id: "Georgia" },
  { id: "Woodstock", state_id: "Illinois" },
  { id: "Woodstock", state_id: "Connecticut" },
  { id: "Woodstock", state_id: "New York" },
  { id: "Woodward", state_id: "Oklahoma" },
  { id: "Woodway", state_id: "Texas" },
  { id: "Woonsocket", state_id: "Rhode Island" },
  { id: "Wooster", state_id: "Ohio" },
  { id: "Worcester", state_id: "Massachusetts" },
  { id: "Worth", state_id: "Illinois" },
  { id: "Worthington", state_id: "Minnesota" },
  { id: "Worthington", state_id: "Ohio" },
  { id: "Wrentham", state_id: "Massachusetts" },
  { id: "Wright", state_id: "Florida" },
  { id: "Wright-Patterson AFB", state_id: "Ohio" },
  { id: "Wyandanch", state_id: "New York" },
  { id: "Wyandotte", state_id: "Michigan" },
  { id: "Wyckoff", state_id: "New Jersey" },
  { id: "Wylie", state_id: "Texas" },
  { id: "Wyndham", state_id: "Virginia" },
  { id: "Wynne", state_id: "Arkansas" },
  { id: "Wyoming", state_id: "Ohio" },
  { id: "Wyoming", state_id: "Michigan" },
  { id: "Wyomissing", state_id: "Pennsylvania" },
  { id: "Wytheville", state_id: "Virginia" },
  { id: "Xenia", state_id: "Ohio" },
  { id: "Yakima", state_id: "Washington" },
  { id: "Yankton", state_id: "South Dakota" },
  { id: "Yardville-Groveville", state_id: "New Jersey" },
  { id: "Yarmouth", state_id: "Maine" },
  { id: "Yarmouth", state_id: "Massachusetts" },
  { id: "Yazoo City", state_id: "Mississippi" },
  { id: "Yeadon", state_id: "Pennsylvania" },
  { id: "Yeehaw Junction", state_id: "Florida" },
  { id: "Yonkers", state_id: "New York" },
  { id: "Yorba Linda", state_id: "California" },
  { id: "York", state_id: "Nebraska" },
  { id: "York", state_id: "Maine" },
  { id: "York", state_id: "Pennsylvania" },
  { id: "York", state_id: "South Carolina" },
  { id: "Yorketown", state_id: "New Jersey" },
  { id: "Yorkshire", state_id: "Virginia" },
  { id: "Yorktown", state_id: "New York" },
  { id: "Yorktown Heights", state_id: "New York" },
  { id: "Yorkville", state_id: "Illinois" },
  { id: "Youngstown", state_id: "Ohio" },
  { id: "Ypsilanti", state_id: "Michigan" },
  { id: "Yreka", state_id: "California" },
  { id: "Yuba City", state_id: "California" },
  { id: "Yucaipa", state_id: "California" },
  { id: "Yucca Valley", state_id: "California" },
  { id: "Yukon", state_id: "Oklahoma" },
  { id: "Yulee", state_id: "Florida" },
  { id: "Yuma", state_id: "Arizona" },
  { id: "Zachary", state_id: "Louisiana" },
  { id: "Zanesville", state_id: "Ohio" },
  { id: "Zephyrhills", state_id: "Florida" },
  { id: "Zion", state_id: "Illinois" },
  { id: "Zionsville", state_id: "Indiana" },
  { id: "Zuni Pueblo", state_id: "New Mexico" },
];
